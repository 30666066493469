import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getMyStories } from 'api/stories/getMyStories';
import { IMyStory } from 'types/myStories';

interface IUseMyStoriesQueryParams {
    enabled?: boolean;
    reFetchOnFocus?: boolean;
}

export const MY_STORIES_LIST_QUERY_KEY = ['myStories'];

export const useMyStoriesQuery = ({ enabled, reFetchOnFocus }: IUseMyStoriesQueryParams = {}) =>
    useQuery<IMyStory[], AxiosError>(MY_STORIES_LIST_QUERY_KEY, {
        enabled,
        queryFn: getMyStories,
        refetchOnWindowFocus: reFetchOnFocus,
        staleTime: 200, // to prevent double requests when redirecting
    });
