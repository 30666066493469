import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError } from 'axios';
import { FC, ReactNode } from 'react';

import { REACT_QUERY_DEFAULT_RETRY } from '../../../config';
import { HttpStatusCode } from 'constants/httpStatusCode';

export interface IProviderProps {
    children: ReactNode;
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (retryCount: number, error: unknown) => {
                const status = (error as AxiosError)?.response?.status;

                if (status === HttpStatusCode.NotFound || status === HttpStatusCode.Unauthorized) {
                    return false;
                }

                return retryCount < REACT_QUERY_DEFAULT_RETRY; // 4 attempts: 0, 1, 2, 4 sec
            },
            refetchOnMount: true,
            refetchOnWindowFocus: true,
        },
    },
});

export const ReactQueryProvider: FC<IProviderProps> = ({ children }) => (
    <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
);
