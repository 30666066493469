import { useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from 'hooks/queries/user/useUserQuery';
import { TUser } from 'types/user';

export const useUserCacheData = () => {
    const queryClient = useQueryClient();

    return queryClient.getQueryData<TUser>(USER_QUERY_KEY);
};
