import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './NextButtonBlock.module.css';
import { Button } from 'components/atoms/Button/Button';
import { ButtonBlock } from 'components/organisms/ImageModal/ButtonBlock/ButtonBlock';

interface INextButtonBlockProps {
    onClick: () => void;
}

export const NextButtonBlock: FC<INextButtonBlockProps> = ({ onClick }) => (
    <div className={styles.clickable} onClick={onClick}>
        <ButtonBlock>
            <Button className={styles.button} stretched>
                <FormattedMessage id="onboarding_modal.button_finish" />
            </Button>
        </ButtonBlock>
    </div>
);
