import { IGetStoryByIdResponse } from '../stories/getReadableStoryById';
import { getContentIdBySummaryInput } from 'api/refferalSystem/types';
import { httpApi } from 'core/httpApi';

export const getStoryBySummary = async (
    summary: getContentIdBySummaryInput['summary'],
    referrer?: getContentIdBySummaryInput['referrer']
): Promise<IGetStoryByIdResponse> =>
    httpApi.v2.get<IGetStoryByIdResponse, IGetStoryByIdResponse>(`stories/hash`, {
        params: {
            summary,
            referrer,
        },
    });
