import { useThemeContext } from 'contexts/Theme/useThemeContext';
import { IConfig } from 'themes/types/config';

type UseThemeConfig = {
    (): IConfig;
    <Selected = any>(selector?: (themeConfig: IConfig) => Selected): Selected;
};

export const useThemeConfig: UseThemeConfig = <Selected = any>(
    selector?: (themeConfig: IConfig) => Selected
) => {
    const { config } = useThemeContext();
    if (!selector) {
        return config;
    }
    return selector(config);
};
