import cn from 'classnames';
import type { FC } from 'react';

import styles from './Spinner.module.css';

export interface ISpinnerProps {
    className?: string;
    size?: number;
}

export const Spinner: FC<ISpinnerProps> = ({ className, size = 24 }) => (
    <span
        className={cn(className, styles['spinner'], !className && styles['default'])}
        style={{ fontSize: `${size}px` }}
    />
);
