import { accessTokenCService } from 'api/accessToken/accessTokenCService';
import { setToken } from 'api/accessToken/setToken';

export const initAccessToken = () => {
    const accessToken = accessTokenCService.get();

    if (accessToken) {
        setToken(accessToken);
    }
};
