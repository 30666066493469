import { forbesMockReadHistory } from 'api/forbesMock/forbesMockReadHistorySSService';
import {
    story1,
    story2,
    twist11,
    twist12,
    twist13,
    twist21,
    twist22,
    twist23,
} from 'api/forbesMock/forbesMockStoriesData';
import { Status } from 'components/organisms/StoryEditorForm/StatusService';
import { IOpenedTwist } from 'types/myStories';

export const forbesMockGetOpenedTwists = async (): Promise<IOpenedTwist[]> => {
    const twistType = twist11();
    const storyType = story1();
    const purchasedTwists = forbesMockReadHistory.get();

    const openedTwist: { twist1: typeof twistType; twist2: typeof twistType } = {
        // @ts-ignore
        twist1: undefined,
        // @ts-ignore
        twist2: undefined,
    };
    const openedStory: { story1: typeof storyType; story2: typeof storyType } = {
        // @ts-ignore
        story1: undefined,
        // @ts-ignore
        story2: undefined,
    };
    let lastOpened: 'story1' | 'story2' | '' = '';
    purchasedTwists.forEach((hash) => {
        if (hash === twist11().hashId) {
            openedTwist.twist1 = twist11();
            openedStory.story1 = story1();
            lastOpened = 'story1';
        } else if (hash === twist12().hashId) {
            openedTwist.twist1 = twist12();
            openedStory.story1 = story1();
            lastOpened = 'story1';
        } else if (hash === twist13().hashId) {
            openedTwist.twist1 = twist13();
            openedStory.story1 = story1();
            lastOpened = 'story1';
        } else if (hash === twist21().hashId) {
            openedTwist.twist2 = twist21();
            openedStory.story2 = story2();
            lastOpened = 'story2';
        } else if (hash === twist22().hashId) {
            openedTwist.twist2 = twist22();
            openedStory.story2 = story2();
            lastOpened = 'story2';
        } else if (hash === twist23().hashId) {
            openedTwist.twist2 = twist23();
            openedStory.story2 = story2();
            lastOpened = 'story2';
        }
    });
    if (lastOpened && lastOpened === 'story1') {
        return Promise.resolve([
            {
                storyHashId: openedStory.story1.hashId,
                storyTitle: openedStory.story1.title,
                storyAuthorNickname: openedStory.story1.nickname,
                twistHashId: openedTwist.twist1.hashId,
                twistTitle: openedTwist.twist1.title,
                twistAuthorNickname: openedTwist.twist1.nickname,
                twistStatus: Status.PUBLISHED,
                lastEditDate: new Date('31 dec 2023'),
                readAtDate: new Date(),
            },
            ...(openedStory.story2
                ? [
                      {
                          storyHashId: openedStory.story2.hashId,
                          storyTitle: openedStory.story2.title,
                          storyAuthorNickname: openedStory.story2.nickname,
                          twistHashId: openedTwist.twist2.hashId,
                          twistTitle: openedTwist.twist2.title,
                          twistAuthorNickname: openedTwist.twist2.nickname,
                          twistStatus: Status.PUBLISHED,
                          lastEditDate: new Date('31 dec 2023'),
                          readAtDate: new Date(),
                      },
                  ]
                : []),
        ]);
    } else if (lastOpened && lastOpened === 'story2') {
        return Promise.resolve([
            {
                storyHashId: openedStory.story2.hashId,
                storyTitle: openedStory.story2.title,
                storyAuthorNickname: openedStory.story2.nickname,
                twistHashId: openedTwist.twist2.hashId,
                twistTitle: openedTwist.twist2.title,
                twistAuthorNickname: openedTwist.twist2.nickname,
                twistStatus: Status.PUBLISHED,
                lastEditDate: new Date('31 dec 2023'),
                readAtDate: new Date(),
            },
            ...(openedStory.story1
                ? [
                      {
                          storyHashId: openedStory.story1.hashId,
                          storyTitle: openedStory.story1.title,
                          storyAuthorNickname: openedStory.story1.nickname,
                          twistHashId: openedTwist.twist1.hashId,
                          twistTitle: openedTwist.twist1.title,
                          twistAuthorNickname: openedTwist.twist1.nickname,
                          twistStatus: Status.PUBLISHED,
                          lastEditDate: new Date('31 dec 2023'),
                          readAtDate: new Date(),
                      },
                  ]
                : []),
        ]);
    }
    return Promise.resolve([]);
};
