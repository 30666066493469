import axios from 'axios';

import { XSOLLA_PROJECT_ID } from '../../config';

const client = axios.create({
    baseURL: `https://store.xsolla.com/api/v2/project/${XSOLLA_PROJECT_ID}`,
});

client.interceptors.response.use((response) => response.data);

const setToken = (token: string | undefined) => {
    client.defaults.headers.common.Authorization = token ? `Bearer ${token}` : token;
};

export const xsollaStore = { client, setToken };
