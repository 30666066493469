import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { storyLanguagesLSService } from 'api/languages/storyLanguagesLSService';
import { TGetStoriesDto } from 'entities/story/api/getStories';
import { useThemeConfig } from 'hooks/theme/useThemeConfig';

interface IUseStoriesQueryArgs {
    enabled: boolean;
    authorHashId?: string | null;
    limit?: number;
    onSuccess?: (stories: TGetStoriesDto) => void;
    onError?: (error: AxiosError) => void;
    offset?: number;
}

export const useStoriesQuery = ({
    enabled,
    authorHashId,
    limit,
    onSuccess,
    onError,
    offset,
}: IUseStoriesQueryArgs) => {
    const { getStories } = useThemeConfig((themeConfig) => themeConfig.api);

    return useQuery({
        enabled,
        queryKey: ['stories', limit, authorHashId],
        queryFn: () =>
            getStories({
                author_id: authorHashId || undefined,
                languages: storyLanguagesLSService.getQueryString(),
                limit,
                offset,
            }),
        refetchOnWindowFocus: false,
        onSuccess,
        onError,
    });
};
