import { XsollaMetrika } from '../xsolla-metrika';
import { xmEventNames } from '../xsolla-metrika/xmEventNames';

export const contentTabSwitch = (tabName: string) => {
    XsollaMetrika.elementClick(xmEventNames.content_tab_switch, tabName);
};

export const readingStoryOpen = (status: string, storyId: string, twistId?: string) => {
    XsollaMetrika.elementClick(xmEventNames.reading_story_open, status, {
        story_id: storyId,
        twist_id: twistId,
    });
};

export const writingStoryOpen = (status: string, storyId: string, twistId?: string) => {
    XsollaMetrika.elementClick(xmEventNames.writing_story_open, status, {
        story_id: storyId,
        twist_id: twistId,
    });
};

export const exploreStoriesBannerClick = () => {
    XsollaMetrika.elementClick(xmEventNames.reading_explore_click);
};
