import { ThemeVariant } from 'themes/types';

export const convertFigmaColors = (figmaColors: any): ThemeVariant => ({
    colors: {
        core: {
            background: {
                primary: figmaColors.bg.primary.$value,
                secondary: figmaColors.bg.secondary.$value,
                ghost: figmaColors.bg.ghost.$value,
                tone: figmaColors.bg.tone.$value,
                selection: figmaColors.bg.selection.$value,
                hover: figmaColors.bg.hover.$value,
                brand: figmaColors.bg.brand.$value,
                tertiary: figmaColors.bg.tertiary.$value,
                yellow: figmaColors.bg.yellow.$value,
                pink: figmaColors.bg.pink.$value,
                success: figmaColors.bg.status.success.$value,
                warning: figmaColors.bg.status.warning.$value,
                alert: figmaColors.bg.status.alert.$value,
                neutral: figmaColors.bg.status.neutral.$value,
            },
            divider: {
                divider: figmaColors.divider.$value,
            },
            text: {
                primary: figmaColors.text.primary.$value,
                secondary: figmaColors.text.secondary.$value,
                tertiary: figmaColors.text.tertiary.$value,
                brand: figmaColors.text.brand.$value,
                success: figmaColors.text.status.success.$value,
                warning: figmaColors.text.status.warning.$value,
                alert: figmaColors.text.status.alert.$value,
                neutral: figmaColors.text.status.neutral.$value,
            },
            link: {
                link: figmaColors.link.link.$value,
                linkHover: figmaColors.link.linkHover.$value,
                linkMinor: figmaColors.link.linkMinor.$value,
                linkMinorHover: figmaColors.link.linkMinorHover.$value,
            },
        },
        control: {
            primary: {
                bg: figmaColors.control.primary.bg.$value,
                bgHover: figmaColors.control.primary.bgHover.$value,
                bgPress: figmaColors.control.primary.bgPress.$value,
                border: figmaColors.control.primary.border.$value,
                borderHover: figmaColors.control.primary.borderHover.$value,
                borderPress: figmaColors.control.primary.borderPress.$value,
                bgDisabled: figmaColors.control.primary.bgDisable.$value,
            },
            secondary: {
                bg: figmaColors.control.secondary.bg.$value,
                bgHover: figmaColors.control.secondary.bgHover.$value,
                bgPress: figmaColors.control.secondary.bgPress.$value,
                border: figmaColors.control.secondary.border.$value,
                borderHover: figmaColors.control.secondary.borderPress?.$value || 'transparent',
                borderPress: figmaColors.control.secondary.borderPress?.$value || 'transparent',
                bgDisabled: figmaColors.control.secondary.bgDisable.$value,
            },
            tertiary: {
                bg: figmaColors.control.tertiary.bg.$value,
                bgHover: figmaColors.control.tertiary.bgHover.$value,
                bgPress: figmaColors.control.tertiary.bgPress.$value,
                border: figmaColors.control.tertiary.border.$value,
                borderHover: figmaColors.control.tertiary.borderHover?.$value || 'transparent',
                borderPress: figmaColors.control.tertiary.borderPress?.$value || 'transparent',
                bgDisabled: figmaColors.control.tertiary.bgDisable.$value,
            },
            default: {
                bg: figmaColors.control.default.bg.$value,
                bgHover: figmaColors.control.default.bgHover.$value,
                bgPress: figmaColors.control.default.bgPress.$value,
                border: figmaColors.control.default.border.$value,
                borderHover: figmaColors.control.default.borderHover.$value,
                borderPress: figmaColors.control.default.borderPress.$value,
                bgDisabled: figmaColors.control.default.bgDisable.$value,
            },
            input: {
                bg: figmaColors.control.input.bg.$value,
                bgHover: figmaColors.control.input.bgHover.$value,
                bgDisabled: figmaColors.control.input.bgDisable.$value,
                border: figmaColors.control.input.border.$value,
                borderHover: figmaColors.control.input.borderHover.$value,
                borderDisabled: figmaColors.control.input.borderDisable.$value,
                text: figmaColors.control.input.text.$value,
                textDisabled: figmaColors.control.input.textDisable.$value,
                placeholder: figmaColors.control.input.placeholder.$value,
            },
            focus: {
                bg: figmaColors.control.focus.bg.$value,
                border: figmaColors.control.focus.border.$value,
            },
            alert: {
                bg: figmaColors.control.alert.bg.$value,
                bgHover: figmaColors.control.alert.bgHover.$value,
                bgPress: figmaColors.control.alert.bgPress.$value,
                border: figmaColors.control.alert.border.$value,
                borderHover: figmaColors.control.alert.borderHover.$value,
                borderPress: figmaColors.control.alert.borderPress.$value,
            },
            check: {
                bg: figmaColors.control.check.bg.$value,
                bgHover: figmaColors.control.check.bgHover.$value,
                bgDisabled: figmaColors.control.check.bgDisable.$value,
            },
            faint: {
                bg: figmaColors.control.faint.bg.$value,
                bgHover: figmaColors.control.faint.bgHover.$value,
                border: figmaColors.control.faint.border.$value,
                borderHover: figmaColors.control.faint.borderHover.$value,
            },
            toggle: {
                bg: figmaColors.control.toggle.bg.$value,
                bgHover: figmaColors.control.toggle.bgHover.$value,
            },
        },
    },
});
