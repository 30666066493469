import { useMutation } from '@tanstack/react-query';

import { useUpdateUserQueryData } from './updateUserQueryData';
import { updateUser } from 'api/user';

export const useUpdateUserMutation = () => {
    const updateUserQueryData = useUpdateUserQueryData();

    return useMutation({
        mutationFn: updateUser,
        onSuccess: updateUserQueryData,
    });
};
