import {
    ICurrencyPackageItem,
    ICurrencyPackageListResponse,
    IPostTopUpBalanceResponseSchema,
} from './types';
import { xsollaStore } from './xsollaStore';
import { XSOLLA_PAYSTATION_SANDBOX_MODE } from 'config';
import { ITopUpBalanceData } from 'types/balance';

export const xsollaStoreService = {
    async getCurrencyPackageList(): Promise<ICurrencyPackageItem[]> {
        const query = new URLSearchParams({
            limit: '10',
            country: 'US',
        }).toString();

        const { items }: ICurrencyPackageListResponse = await xsollaStore.client.get(
            `/items/virtual_currency/package?${query}`
        );
        return items.map((item, index) => ({
            number: index + 1,
            itemId: item.item_id,
            sku: item.sku,
            name: item.name,
            price: item.price.amount,
            currency: item.price.currency,
            imageUrl: item.image_url || '',
            quantity: item.content[0].quantity,
        }));
    },

    async createOrder(item: ITopUpBalanceData) {
        const { order_id: orderId, token }: IPostTopUpBalanceResponseSchema =
            await xsollaStore.client.post(`/payment/item/${item.sku}`, {
                sandbox: XSOLLA_PAYSTATION_SANDBOX_MODE,
                settings: {
                    ui: {
                        theme: '64e366549dcc5f8e8cc2d113',
                        desktop: {
                            header: {
                                visible_logo: true,
                                visible_name: true,
                                visible_purchase: true,
                                type: 'normal',
                            },
                        },
                        mobile: {
                            footer: {
                                is_visible: true,
                            },
                            header: {
                                close_button: false,
                            },
                        },
                    },
                },
            });

        return {
            orderId,
            token,
        };
    },
};
