import { httpApi } from '../../core/httpApi';
import { ReadTwistResponse } from './readTwist';

export const readFreeTwist = async (
    hash: string,
    readTwistHashes: string[],
    signal?: AbortSignal
) =>
    httpApi.v2.post<ReadTwistResponse, ReadTwistResponse>(
        `twists/${hash}/read-free`,
        readTwistHashes,
        { signal }
    );
