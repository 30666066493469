import { createContext } from 'react';

interface IModalWindowContext {
    isDisplayed: boolean;
    display: () => void;
    hide: () => void;
}

export const ModalWindowContext = createContext<IModalWindowContext>({
    isDisplayed: false,
    display: () => {},
    hide: () => {},
});
