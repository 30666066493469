// TODO problems with basic fontWeight (add +100???)
import { ITypography } from 'themes/types/typography';

export const convertFigmaTypo = (
    figmaTypo: any,
    headingFontFamily: string,
    mainFontFamily: string
): ITypography => ({
    fontFamily: mainFontFamily,
    core: {
        h1: {
            fontFamily: headingFontFamily,
            fontSize: figmaTypo.heading.h1.$value.fontSize,
            lineHeight: figmaTypo.heading.h1.$value.lineHeight,
            letterSpacing: figmaTypo.heading.h1.$value.letterSpacing,
            fontWeight: figmaTypo.heading.h1.$value.fontWeight,
        },
        h2: {
            fontFamily: headingFontFamily,
            fontSize: figmaTypo.heading.h2.$value.fontSize,
            lineHeight: figmaTypo.heading.h2.$value.lineHeight,
            letterSpacing: figmaTypo.heading.h2.$value.letterSpacing,
            fontWeight: figmaTypo.heading.h2.$value.fontWeight,
        },
        h3: {
            fontFamily: headingFontFamily,
            fontSize: figmaTypo.heading.h3.$value.fontSize,
            lineHeight: figmaTypo.heading.h3.$value.lineHeight,
            letterSpacing: figmaTypo.heading.h3.$value.letterSpacing,
            fontWeight: figmaTypo.heading.h3.$value.fontWeight,
        },
        h4: {
            fontFamily: headingFontFamily,
            fontSize: figmaTypo.heading.h4.$value.fontSize,
            lineHeight: figmaTypo.heading.h4.$value.lineHeight,
            letterSpacing: figmaTypo.heading.h4.$value.letterSpacing,
            fontWeight: figmaTypo.heading.h4.$value.fontWeight,
        },
        strong: {
            fontFamily: mainFontFamily,
            fontSize: figmaTypo.basic.strong.$value.fontSize,
            lineHeight: figmaTypo.basic.strong.$value.lineHeight,
            letterSpacing: figmaTypo.basic.strong.$value.letterSpacing,
            fontWeight: figmaTypo.basic.strong.$value.fontWeight,
        },
        bodyLgAccent: {
            fontFamily: mainFontFamily,
            fontSize: figmaTypo.basic.BodyLAccent.$value.fontSize,
            lineHeight: figmaTypo.basic.BodyLAccent.$value.lineHeight,
            letterSpacing: figmaTypo.basic.BodyLAccent.$value.letterSpacing,
            fontWeight: figmaTypo.basic.BodyLAccent.$value.fontWeight,
        },
        bodyLg: {
            fontFamily: mainFontFamily,
            fontSize: figmaTypo.basic.BodyL.$value.fontSize,
            lineHeight: figmaTypo.basic.BodyL.$value.lineHeight,
            letterSpacing: figmaTypo.basic.BodyL.$value.letterSpacing,
            fontWeight: figmaTypo.basic.BodyL.$value.fontWeight,
        },
        bodyMdAccent: {
            fontFamily: mainFontFamily,
            fontSize: figmaTypo.basic.BodyMAccent.$value.fontSize,
            lineHeight: figmaTypo.basic.BodyMAccent.$value.lineHeight,
            letterSpacing: figmaTypo.basic.BodyMAccent.$value.letterSpacing,
            fontWeight: figmaTypo.basic.BodyMAccent.$value.fontWeight,
        },
        bodyMd: {
            fontFamily: mainFontFamily,
            fontSize: figmaTypo.basic.BodyM.$value.fontSize,
            lineHeight: figmaTypo.basic.BodyM.$value.lineHeight,
            letterSpacing: figmaTypo.basic.BodyM.$value.letterSpacing,
            fontWeight: figmaTypo.basic.BodyM.$value.fontWeight,
        },
        paragraphL: {
            fontFamily: mainFontFamily,
            fontSize: figmaTypo.basic.ParagraphL.$value.fontSize,
            lineHeight: figmaTypo.basic.ParagraphL.$value.lineHeight,
            letterSpacing: figmaTypo.basic.ParagraphL.$value.letterSpacing,
            fontWeight: figmaTypo.basic.ParagraphL.$value.fontWeight,
        },
        paragraphM: {
            fontFamily: mainFontFamily,
            fontSize: figmaTypo.basic.ParagraphM.$value.fontSize,
            lineHeight: figmaTypo.basic.ParagraphM.$value.lineHeight,
            letterSpacing: figmaTypo.basic.ParagraphM.$value.letterSpacing,
            fontWeight: figmaTypo.basic.ParagraphM.$value.fontWeight,
        },
        label: {
            fontFamily: mainFontFamily,
            fontSize: figmaTypo.basic.Label.$value.fontSize,
            lineHeight: figmaTypo.basic.Label.$value.lineHeight,
            letterSpacing: figmaTypo.basic.Label.$value.letterSpacing,
            fontWeight: figmaTypo.basic.Label.$value.fontWeight,
        },
        minor: {
            fontFamily: mainFontFamily,
            fontSize: figmaTypo.basic.Minor.$value.fontSize,
            lineHeight: figmaTypo.basic.Minor.$value.lineHeight,
            letterSpacing: figmaTypo.basic.Minor.$value.letterSpacing,
            fontWeight: figmaTypo.basic.Minor.$value.fontWeight,
        },
        micro: {
            fontFamily: mainFontFamily,
            fontSize: figmaTypo.basic.Micro.$value.fontSize,
            lineHeight: figmaTypo.basic.Micro.$value.lineHeight,
            letterSpacing: figmaTypo.basic.Micro.$value.letterSpacing,
            fontWeight: figmaTypo.basic.Micro.$value.fontWeight,
        },
    },
});
