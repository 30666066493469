import { TMuiSxProps } from '../types';
import { useThemeContext } from 'contexts/Theme/useThemeContext';

export const usePaperSxProps = () => {
    const { cssTheme } = useThemeContext();

    const paperSxProps: TMuiSxProps = {
        '&.MuiPaper-rounded': {
            backgroundColor: cssTheme.colors.core.background.secondary,
            borderRadius: '8px',
            boxShadow: cssTheme.shadows.popover,
        },
    };

    return { paperSxProps };
};
