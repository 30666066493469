import { ELanguage } from 'types/languages';

export const uiLanguages = [
    ELanguage.English,
    ELanguage.Chinese,
    ELanguage.German,
    ELanguage.Korean,
    ELanguage.Japanese,
    ELanguage.French,
    ELanguage.Russian,
    ELanguage.Spanish,
    ELanguage.Portuguese,
];

//TODO Сюда добавлять языки которые попадут в прод. Выпилить когда зарелизятся все языки.
export const enabledUiLanguages = [ELanguage.English, ELanguage.Portuguese];
