export enum TransactionType {
    AgentRevenue = 'AgentRevenue',
    OrbsPurchase = 'OrbsPurchase',
    ReferralRevenue = 'ReferralRevenue',
    StoryRevenue = 'StoryRevenue',
    TwistPurchase = 'TwistPurchase',
}

export interface ITransaction {
    date: string;
    type: TransactionType;
    amount: number;
    hashId: string;
    story?: {
        hashId: string;
        title: string;
    };
}
