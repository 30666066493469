import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { USER_QUERY_KEY } from './useUserQuery';
import { IAuthenticatedUser, TUser } from 'types/user';

export const useUpdateUserQueryData = () => {
    const queryClient = useQueryClient();

    return useCallback(
        (userUpdate: Partial<IAuthenticatedUser>) => {
            queryClient.setQueryData<TUser>(USER_QUERY_KEY, (prevUser) => ({
                ...(prevUser as IAuthenticatedUser), //Assume we mutate existing user (saved in cache at this moment)
                ...userUpdate,
            }));
        },
        [queryClient]
    );
};
