import { colors } from 'themes/partners/nytimes/colors';
import { config } from 'themes/partners/nytimes/config';
import { shadows } from 'themes/partners/nytimes/shadows';
import { typo } from 'themes/partners/nytimes/typo';
import { IMisc } from 'themes/types';
import { IThemeConfigurable, THEME_ID } from 'themes/types/theme';

export const newYorkTimesTheme: IThemeConfigurable = {
    ...colors,
    shadows,
    typo,
    misc: {} as IMisc,
    themeId: THEME_ID.NEW_YORK_TIMES,
    config,
};
