import cn from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '../../../atoms/Button/Button';
import styles from './FloatingLayout.module.css';
import { INewStoryButtonLayoutProps } from './types';
import { ReactComponent as FeatherIcon } from 'assets/feather.svg';
import { OnboardingTooltip } from 'entities/Onboarding/Tooltip';
import { isCreateStoryTip } from 'features/FlowTips';

export const FloatingLayout: FC<INewStoryButtonLayoutProps> = ({
    className,
    tooltipClassName,
    isDisabled,
    onClick,
}) => {
    const isTipShowing = useSelector(isCreateStoryTip);

    return isDisabled ? null : (
        <div className={className}>
            <OnboardingTooltip
                titleId="onboarding_tip_create_story"
                statusTextId="onboarding_tip_create_story_status"
                placement="left"
                className={cn(tooltipClassName, styles.tooltip)}
                bgColor="var(--colors-core-background-brand)"
                offset={[0, 56]}
                open={isTipShowing}
            >
                <Button
                    className={className}
                    onClick={onClick}
                    icon={<FeatherIcon className={styles['icon']} height={24} />}
                    size="lg"
                    variant="primary"
                />
            </OnboardingTooltip>
        </div>
    );
};
