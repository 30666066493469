import { TMuiInputSize, TMuiSxProps } from '../types';
import { useThemeContext } from 'contexts/Theme/useThemeContext';

export const useInputSxProps = (size: TMuiInputSize | undefined) => {
    const { cssTheme } = useThemeContext();

    const inputSxProps: TMuiSxProps = {
        typography: size === 'small' ? cssTheme.typo.core.minor : cssTheme.typo.core.bodyMd,
        color: cssTheme.colors.core.text.primary,
        borderRadius: size === 'small' ? '12px' : '16px',
        backgroundColor: cssTheme.colors.control.input.bg,

        '&.MuiInputBase-adornedStart': {
            paddingLeft: '16px',
            '.MuiOutlinedInput-input, .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall': {
                paddingLeft: '8px',
            },
        },

        '&.MuiInputBase-adornedEnd': {
            paddingRight: '16px',
            '.MuiOutlinedInput-input, .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall': {
                paddingRight: '24px',
            },
        },

        '&:hover:not(.Mui-disabled)': {
            backgroundColor: cssTheme.colors.control.input.bgHover,
            '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                borderColor: cssTheme.colors.control.input.borderHover,
            },
        },

        '&.Mui-disabled': {
            backgroundColor: cssTheme.colors.control.input.bgDisabled,
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: cssTheme.colors.control.input.border,
            },
        },

        '&.Mui-disabled .MuiOutlinedInput-input': {
            color: cssTheme.colors.core.text.primary,
            WebkitTextFillColor: cssTheme.colors.core.text.primary,
            '&::placeholder,&::-webkit-input-placeholder': {
                color: cssTheme.colors.control.input.textDisabled,
                WebkitTextFillColor: cssTheme.colors.control.input.textDisabled,
            },
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: cssTheme.colors.control.input.borderHover,
            borderWidth: '1px',
        },

        '&.Mui-error, &.Mui-error.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: cssTheme.colors.control.alert.border,
            },
        },

        '.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: cssTheme.colors.control.input.border,
        },

        '.MuiOutlinedInput-input': {
            padding: '16px',
            height: '24px',

            '&.MuiInputBase-inputSizeSmall': {
                padding: '11px 16px',
                height: '18px',
            },

            '&::placeholder,&::-webkit-input-placeholder': {
                opacity: 1,
                color: cssTheme.colors.core.text.tertiary,
            },
        },
    };

    return { inputSxProps };
};
