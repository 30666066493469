import { configureStore } from '@reduxjs/toolkit';

import { flowTipsListenerMiddleware, flowTipsReducer } from '../features/FlowTips';

export const store = configureStore({
    reducer: {
        flowTips: flowTipsReducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware(),
        flowTipsListenerMiddleware.middleware,
    ],
});

/*if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../slices/index.ts', () => store.replaceReducer(rootReducer));
}*/

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
