import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import {
    PartnerRouterContext,
    PARTNERS_URL,
    PROJECTS,
} from 'contexts/PartnerRouter/PartnerRouterContext';
import { httpApi } from 'core/httpApi';

export const PartnerRouterContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [prefix, setPrefix] = useState<PARTNERS_URL>(PARTNERS_URL.DEFAULT);
    const [project, _setProject] = useState<PROJECTS>(PROJECTS.DEFAULT);

    const getRoute = useCallback((route: string) => prefix + route, [prefix]);

    const setProject = useCallback((project: PROJECTS) => {
        _setProject(project);
        httpApi.setProjectHeader(project);
    }, []);

    const value = useMemo(
        () => ({
            prefix,
            project,
            getRoute,
            setPrefix,
            setProject,
        }),
        [prefix, project, getRoute, setProject]
    );

    return <PartnerRouterContext.Provider value={value}>{children}</PartnerRouterContext.Provider>;
};
