import { useQuery } from '@tanstack/react-query';

import { getUserBalance } from '../../../api/billing/getUserBalance';
import { queryClient } from 'app/providers/ReactQueryProvider';
import { isBalanceMocked } from 'contexts/PartnerRouter/PartnerRouterContext';
import { useThemeContext } from 'contexts/Theme/useThemeContext';
import { useUserCacheData } from 'hooks/queries/user';
import { useThemeConfig } from 'hooks/theme/useThemeConfig';

export const BALANCE_KEY = 'balance';

// Used during application initialization
export const loadBalanceToCache = async () => {
    if (!isBalanceMocked) {
        try {
            const balance = await getUserBalance();
            queryClient.setQueryData([BALANCE_KEY], balance);
        } catch {}
    }
};

export const useUserBalanceQuery = () => {
    const { getUserBalance } = useThemeConfig((themeConfig) => themeConfig.api);
    const { themeId } = useThemeContext();

    const user = useUserCacheData();

    return useQuery({
        initialData: 0,
        queryKey: [BALANCE_KEY],
        queryFn: getUserBalance,
        enabled: !!themeId && Boolean(user?.isAuthenticated),
        cacheTime: 0,
    });
};
