import { gtmPushEvent } from '../gtm/gtm';
import { gtmEventNames } from '../gtm/gtmEventNames';
import { XsollaMetrika } from '../xsolla-metrika';
import { xmEventNames } from '../xsolla-metrika/xmEventNames';

export const storyRootView = (storyId: string) => {
    XsollaMetrika.customEvent(xmEventNames.story_reading_root_view, { story_id: storyId });
};

export const storySwitchNext = () => {
    XsollaMetrika.elementClick(xmEventNames.story_switch_right);
    gtmPushEvent(gtmEventNames.switch_story_right);
};

export const storySwitchPrev = () => {
    XsollaMetrika.elementClick(xmEventNames.story_switch_left);
    gtmPushEvent(gtmEventNames.switch_story_left);
};

export const storyCreateClick = () => {
    XsollaMetrika.elementClick(xmEventNames.story_menu_create);
};
export const finishStoryCreationClick = (storyId: string) => {
    XsollaMetrika.elementClick(xmEventNames.story_own_add_plot, undefined, { story_id: storyId });
    gtmPushEvent(gtmEventNames.add_own_story, { story_id: storyId });
};

export const deleteStoryClick = (storyId: string) => {
    XsollaMetrika.elementClick(xmEventNames.stories_list_delete, 'permanent', {
        story_id: storyId,
    });
};

export const reviseStoryTwistsClick = (storyId: string, isOwnStory: boolean) => {
    const event = isOwnStory
        ? xmEventNames.story_own_revise_twists
        : xmEventNames.story_foreign_revise_twists;
    XsollaMetrika.elementClick(event, undefined, { story_id: storyId });
};

export const shareStoryClick = (
    storyId: string,
    authorship: 'own' | 'foreign' | null,
    source: 'writing' | 'story'
) => {
    XsollaMetrika.elementClick(xmEventNames.story_share_button_click, storyId, {
        authorship,
        source,
    });
};
