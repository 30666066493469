import { useCallback, useEffect, useState } from 'react';

import styles from './ForbesEntryPoint.module.css';
import { useThemeConfig } from 'hooks/theme/useThemeConfig';

export const ForbesEntryPoint = () => {
    const { Component: DisclaimerModal } = useThemeConfig(
        (themeConfig) => themeConfig.disclaimerModal
    );
    const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);

    const onDisclaimerClose = () => {
        setShowDisclaimer(false);
    };

    const openDisclaimer = useCallback((event: MessageEvent<string>) => {
        if (event.data === 'openDisclaimer') {
            setShowDisclaimer(true);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('message', openDisclaimer);

        return () => window.removeEventListener('message', openDisclaimer);
    }, []);

    return (
        <>
            <iframe
                className={styles['iframe']}
                title="forbes entry point"
                src="/forbesEntryPoint.html"
            ></iframe>
            {showDisclaimer && DisclaimerModal && <DisclaimerModal onClose={onDisclaimerClose} />}
        </>
    );
};
