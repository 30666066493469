import { styled } from '@mui/material';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { forwardRef } from 'react';

const TooltipWithRef = forwardRef<HTMLDivElement, TooltipProps>(({ className, ...props }, ref) => (
    <Tooltip {...props} classes={{ popper: className }}>
        <div ref={ref}>{props.children}</div>
    </Tooltip>
));

TooltipWithRef.displayName = 'Tooltip';

export const StyledTooltip = styled(TooltipWithRef)(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        boxSizing: 'content-box',
        fontSize: 26,
    },

    [`& .${tooltipClasses.tooltip}`]: {
        borderRadius: 24,
        padding: 20,
        maxWidth: 320,
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '21px',
        boxShadow: '0 6px 34px 0 rgba(0, 0, 0, 0.12)',
    },

    '&[data-popper-placement*="top"]': {
        [`& .${tooltipClasses.arrow}`]: {
            padding: '0 18px',

            '&::before': {
                borderBottomRightRadius: 3,
                transform: 'rotate(45deg) skew(10deg, 10deg)',
            },
        },
    },

    '&[data-popper-placement*="right"]': {
        [`& .${tooltipClasses.arrow}`]: {
            marginLeft: '-0.68em',
            paddingTop: 15,
            paddingBottom: 15,

            '&::before': {
                borderBottomLeftRadius: 3,
                transform: 'rotate(55deg) skew(0deg, -20deg)',
            },
        },
    },

    '&[data-popper-placement*="bottom"]': {
        [`& .${tooltipClasses.arrow}`]: {
            padding: '0 18px',

            '&::before': {
                borderTopLeftRadius: 3,
                transform: 'rotate(45deg) skew(10deg, 10deg)',
            },
        },
    },

    '&[data-popper-placement*="left"]': {
        [`& .${tooltipClasses.arrow}`]: {
            marginRight: '-0.68em',
            paddingTop: 15,
            paddingBottom: 15,

            '&::before': {
                borderTopRightRadius: 3,
                transform: 'rotate(55deg) skew(0deg, -20deg)',
            },
        },
    },
}));
