import { prepareUserResponse } from './prepareUserResponse';
import { TResponseSchema } from './types';
import { accessTokenCService } from 'api/accessToken/accessTokenCService';
import { ANONYM_USER } from 'constants/anonymousUser';
import { httpApi } from 'core/httpApi';
import { TUser } from 'types/user';

export const getUser = async (): Promise<TUser> => {
    const accessToken = accessTokenCService.get();

    if (!accessToken) {
        return ANONYM_USER;
    }

    const response: TResponseSchema = await httpApi.v2.get('users/me');

    return prepareUserResponse(response);
};
