import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { ILocalizationContext, LocalizationContext } from './LocalizationContext';
import { getLocale } from './getLocale';
import { ELocale } from 'types/locale';
import { TTranslations } from 'types/translations';
import { uiLanguageClientStorage } from 'utils/languages/uiLanguageClientStorage';
import { getBundledTranslations } from 'utils/translations/getBundledTranslations';

const initialLanguage = uiLanguageClientStorage.get();
const initialTranslations = getBundledTranslations(initialLanguage);

export const LocalizationContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [language, setLanguage] = useState(initialLanguage);
    const [translations, setTranslations] = useState<TTranslations>(initialTranslations);

    const value = useMemo<ILocalizationContext>(
        () => ({ language, setLanguage, setTranslations }),
        [language, setLanguage, setTranslations]
    );

    return (
        <LocalizationContext.Provider value={value}>
            <IntlProvider
                messages={translations}
                locale={getLocale(language)}
                defaultLocale={getLocale(language)}
            >
                {children}
            </IntlProvider>
        </LocalizationContext.Provider>
    );
};
