import React from 'react';

import styles from './SocialLinks.module.css';
import { AnalyticsEvents } from 'analytics/events';
import { PlainButton } from 'components/atoms/PlainButton';
import { FacebookIcon } from 'components/atoms/SocialLinkIcons/FacebookIcon';
import { InstagramIcon } from 'components/atoms/SocialLinkIcons/InstagramIcon';
import { LinkedinIcon } from 'components/atoms/SocialLinkIcons/LinkedinIcon';
import { TwitterIcon } from 'components/atoms/SocialLinkIcons/TwitterIcon';

const linkedInUrl = 'https://www.linkedin.com/company/story-3';
const facebookUrl = 'https://www.facebook.com/people/Story3/61554308553352';
const instagramUrl = 'https://www.instagram.com/_story3._';
const twitterInUrl = 'https://twitter.com/_story3_';

const links = [
    {
        name: 'facebook',
        icon: FacebookIcon,
        url: facebookUrl,
    },
    {
        name: 'linkedin',
        icon: LinkedinIcon,
        url: linkedInUrl,
    },
    {
        name: 'twitter',
        icon: TwitterIcon,
        url: twitterInUrl,
    },
    {
        name: 'instagram',
        icon: InstagramIcon,
        url: instagramUrl,
    },
];

export const SocialLinks = () => {
    const openLink = (url: string, socialNetworkName: string) => {
        AnalyticsEvents.socialLinkClick(socialNetworkName);
        window.open(url, '_blank');
    };

    return (
        <div className={styles['container']}>
            {links.map(({ icon: Icon, url, name }) => (
                <PlainButton key={name} onClick={() => openLink(url, name)}>
                    <Icon />
                </PlainButton>
            ))}
        </div>
    );
};
