import { createSlice } from '@reduxjs/toolkit';

import { localStorageService } from 'utils/localStorageService';

export enum FlowTipsTypes {
    noTip,
    twistTip,
    createTwistTip,
    createStoryTip,
    finished,
}

interface IInitialState {
    tipType: FlowTipsTypes;
    readStories: number;
}

const storageService = localStorageService('flowTipsConfig');

const initialState: () => IInitialState = () =>
    (storageService.get() as IInitialState) || {
        tipType: 0,
        readStories: 0,
    };

const flowTipsSlice = createSlice({
    name: 'flowTips',
    initialState,
    reducers: {
        readStoryIncrement: (state) => {
            state.readStories++;
        },
        toggleTip: (state, action) => {
            state.tipType = action.payload;
        },
    },
});

export const { readStoryIncrement, toggleTip } = flowTipsSlice.actions;
export default flowTipsSlice.reducer;
