import { httpApi } from '../../core/httpApi';

export type IGetUserBalanceResponse = {
    amount: number;
};

export const getUserBalance = () =>
    httpApi.v2
        .get<IGetUserBalanceResponse, IGetUserBalanceResponse>('billing/balance')
        .then(({ amount }) => amount);
