import { httpApi } from 'core/httpApi';
import { ELanguage } from 'types/languages';
import { IAuthenticatedUser } from 'types/user';

interface IUpdateUserParams {
    nickname?: string;
    preferred_languages?: string[];
    ui_language?: ELanguage;
}

export const updateUser = async ({
    nickname,
    preferred_languages,
    ui_language,
}: IUpdateUserParams): Promise<Partial<IAuthenticatedUser>> =>
    httpApi.v2.patch('users/me', { nickname, preferred_languages, ui_language });
