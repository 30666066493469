import { forbesMockReadHistory } from 'api/forbesMock/forbesMockReadHistorySSService';
import {
    story1,
    story2,
    twist11,
    twist12,
    twist13,
    twist21,
    twist22,
    twist23,
} from 'api/forbesMock/forbesMockStoriesData';
import { ITransaction, TransactionType } from 'types/transactions';

export interface IGetTransactionsParams {
    limit?: number;
    offset?: number;
}

export type GetTransactionsResponse = ITransaction[];

export const forbesMockGetTransactions = async (
    params: IGetTransactionsParams
): Promise<GetTransactionsResponse> => {
    const purchasedTwists = forbesMockReadHistory.get();
    const transactions: GetTransactionsResponse = [];
    purchasedTwists.forEach((hash) => {
        let twist;
        let story;
        if (hash === twist11().hashId) {
            twist = twist11();
            story = story1();
        } else if (hash === twist12().hashId) {
            twist = twist12();
            story = story1();
        } else if (hash === twist13().hashId) {
            twist = twist13();
            story = story1();
        } else if (hash === twist21().hashId) {
            twist = twist21();
            story = story2();
        } else if (hash === twist22().hashId) {
            twist = twist22();
            story = story2();
        } else if (hash === twist23().hashId) {
            twist = twist23();
            story = story2();
        }
        if (twist && story && twist.price !== 0) {
            transactions.push({
                date: new Date().toString(),
                type: TransactionType.TwistPurchase,
                amount: -1 * twist.price,
                hashId: twist.hashId,
                story: {
                    hashId: story.hashId,
                    title: story.title,
                },
            });
        }
    });
    return Promise.resolve(transactions);
};
