import de from 'translations/bundled/de-DE.json';
import en from 'translations/bundled/en-US.json';
import es from 'translations/bundled/es-ES.json';
import fr from 'translations/bundled/fr-FR.json';
import ja from 'translations/bundled/ja-JP.json';
import ko from 'translations/bundled/ko-KR.json';
import pt from 'translations/bundled/pt-BR.json';
import ru from 'translations/bundled/ru-RU.json';
import untranslated from 'translations/bundled/untranslated.json';
import zn from 'translations/bundled/zh-CN.json';
import { ELanguage } from 'types/languages';
import { TTranslations } from 'types/translations';

const bundledMessages = {
    untranslated,
    [ELanguage.German]: de,
    [ELanguage.English]: en,
    [ELanguage.Spanish]: es,
    [ELanguage.French]: fr,
    [ELanguage.Japanese]: ja,
    [ELanguage.Korean]: ko,
    [ELanguage.Portuguese]: pt,
    [ELanguage.Russian]: ru,
    [ELanguage.Chinese]: zn,
};

export const getBundledTranslations = (language: ELanguage): TTranslations => ({
    ...bundledMessages.untranslated,
    ...bundledMessages[language],
});
