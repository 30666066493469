import { AbService } from './AbService';
import { localStorageService } from 'utils/localStorageService';

const getGroup = (group: string) => AbService.isCurrentGroup(group);

export const AbTest = {
    get isGroupB813() {
        return getGroup('STORY3-813_B') || false;
    },
    get isGroupB956() {
        return getGroup('STORY3-956_B') || false;
    },
    get isGroupB945() {
        return getGroup('STORY3-945_B') || false;
    },
    get isGroupB1150() {
        return getGroup('STORY3-1150_B') || false;
    },
    get isGroupB1152() {
        return getGroup('STORY3-1152_B') || false;
    },
    get isGroupB1194() {
        return getGroup('STORY3-1194_B') || false;
    },
};
