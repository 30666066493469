import cn from 'classnames';
import type { FC, PropsWithChildren, ReactHTML } from 'react';

import styles from './Text.module.css';
import { useIsMobile, useIsTablet } from 'hooks/layout';
import { useIsSmallMobile } from 'hooks/layout/useIsSmallMobile';

export type TextTypography =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'strong'
    | 'body-lg-accent'
    | 'body-lg'
    | 'body-md-accent'
    | 'body-md'
    | 'paragraphL'
    | 'paragraphM'
    | 'label'
    | 'minor'
    | 'micro';

export type TextColor =
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'brand'
    | 'success'
    | 'warning'
    | 'alert'
    | 'neutral';

export interface ITextProps extends PropsWithChildren {
    typo?: TextTypography;
    smallMobileTypo?: TextTypography;
    mobileTypo?: TextTypography;
    desktopTypo?: TextTypography;
    italic?: boolean;
    selectNone?: boolean;
    color?: TextColor;
    className?: string;
    component?: keyof ReactHTML;
    nowrap?: boolean;
}

const componentTypeMap: Partial<Record<TextTypography, keyof ReactHTML>> = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
};

export const Text: FC<ITextProps> = ({
    typo = 'body-md',
    smallMobileTypo,
    mobileTypo,
    desktopTypo,
    italic,
    selectNone,
    color = 'primary',
    component,
    nowrap,
    className,
    children,
}) => {
    const smallMobileTypography = useIsSmallMobile() && smallMobileTypo;
    const mobileTypography = useIsMobile() && mobileTypo;
    const desktopTypography = !useIsTablet() && desktopTypo;
    const typography = smallMobileTypography || mobileTypography || desktopTypography || typo;
    const CustomDOMComponent = component || componentTypeMap[typo] || 'p';

    return (
        <CustomDOMComponent
            className={cn(
                className,
                styles['text'],
                styles[`typo_${typography.replaceAll('-', '_')}`],
                styles[`color_${color}`],
                italic && styles['italic'],
                nowrap && styles['nowrap'],
                selectNone && styles['select_none']
            )}
        >
            {children}
        </CustomDOMComponent>
    );
};
