import * as Sentry from '@sentry/react';
import { ConfigCatProvider } from 'configcat-react';
import 'normalize.css';
import { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
    createRoutesFromChildren,
    matchRoutes,
    RouterProvider,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

import './styles/fonts.css';
import './styles/global.css';
import './styles/variables.css';
import { initAccessToken } from 'api/accessToken/initAccessToken';
import { ReactQueryProvider } from 'app/providers/ReactQueryProvider';
import { createRouter } from 'app/providers/routing';
import { FullscreenLoader } from 'components/molecules/FullscreenLoader';
import { CONFIGCAT_SDK_KEY } from 'config';
import { ROOT_DOM_ID } from 'constants/domIds';
import { LocalizationContextProvider } from 'contexts/LocalizationContext/LocalizationContextProvider';
import { ModalWindowContextProvider } from 'contexts/ModalWindowContext';
import { PartnerRouterContextProvider } from 'contexts/PartnerRouter/PartnerRouterContextProvider';
import { ThemeContextProvider } from 'contexts/Theme/ThemeContextProvider';
import { store } from 'store';
import { mockRandomUuidFuncInDevMode } from 'utils/mockRandomUuidFuncInDevMode';
import { initViewportHeightUpdate } from 'utils/viewportHeight';

const rootElement = document.getElementById(ROOT_DOM_ID);
const root = createRoot(rootElement!);
const router = createRouter();

initAccessToken();
initViewportHeightUpdate();
mockRandomUuidFuncInDevMode();

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_URL) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        environment: 'production',
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    });
}

root.render(
    <ReactQueryProvider>
        <Provider store={store}>
            <ConfigCatProvider sdkKey={CONFIGCAT_SDK_KEY}>
                <LocalizationContextProvider>
                    <ThemeContextProvider>
                        <PartnerRouterContextProvider>
                            <ModalWindowContextProvider>
                                <Suspense fallback={<FullscreenLoader />}>
                                    <RouterProvider router={router} />
                                </Suspense>
                            </ModalWindowContextProvider>
                        </PartnerRouterContextProvider>
                    </ThemeContextProvider>
                </LocalizationContextProvider>
            </ConfigCatProvider>
        </Provider>
        <div className="story3_preload_images" />
    </ReactQueryProvider>
);
