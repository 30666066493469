import cn from 'classnames';
import { FC, forwardRef, PropsWithChildren } from 'react';

import styles from './PageTemplate.module.css';
import { NotFoundMessage } from 'components/molecules/NotFoundMessage';
import { PageLoader } from 'components/molecules/PageLoader';
import { SomethingWrongMessage } from 'components/molecules/SomethingWrongMessage';

export enum PageState {
    DISPLAY = 'display',
    LOADING = 'loading',
    NOT_FOUND = 'not_found',
    ERROR = 'error',
}

export type GetPageStateParams = {
    isLoading?: boolean;
    isNotFound?: boolean;
    isError?: boolean;
};

export const getPageState = ({ isLoading, isNotFound, isError }: GetPageStateParams) => {
    if (isError) {
        return PageState.ERROR;
    }

    if (isNotFound) {
        return PageState.NOT_FOUND;
    }

    if (isLoading) {
        return PageState.LOADING;
    }

    return PageState.DISPLAY;
};

export interface IPageTemplateProps extends PropsWithChildren {
    state?: PageState;
    className?: string;
}

const PageTemplate = forwardRef<HTMLDivElement, IPageTemplateProps>(
    ({ state = PageState.DISPLAY, className, children }, ref) => (
        <div ref={ref} className={cn(styles['page'], styles[`page_${state}`], className)}>
            {/* TODO: Может не стоит размонтировать из дерева children? Просто скрывать и все */}
            {state === PageState.DISPLAY && children}
            {state === PageState.LOADING && <PageLoader />}
            {state === PageState.NOT_FOUND && <NotFoundMessage />}
            {state === PageState.ERROR && <SomethingWrongMessage />}
        </div>
    )
);

PageTemplate.displayName = 'PageTemplate';

export { PageTemplate };
