import { createContext } from 'react';

interface IBalanceNotificationContext {
    amount?: number;
    message?: string;
    display: (amount: number, message: string) => void;
    hide: () => void;
}

export const BalanceNotificationContext = createContext<IBalanceNotificationContext>({
    display: () => {},
    hide: () => {},
});
