import type { IBaseColors, ThemeVariant } from '../../types';
import { colord } from 'colord';

export const base: IBaseColors = {
    content: colord({ h: 30, s: 4, l: 9 }),
    surface: colord({ h: 180, s: 3, l: 94 }),
    project: colord({ h: 0, s: 100, l: 65 }),
    phantom: colord({ h: 30, s: 4, l: 9 }),
    path: colord({ h: 210, s: 60, l: 48 }),
    yellow: colord({ h: 240, s: 7, l: 97 }),
    pink: colord({ h: 51, s: 49, l: 85 }),
    success: colord({ h: 252, s: 10, l: 10 }),
    warning: colord({ h: 19, s: 100, l: 40 }),
    alert: colord({ h: 337, s: 100, l: 40 }),
    neutral: colord({ h: 210, s: 53, l: 17 }),
};

const white = 'rgb(255,255,255)';
export const defaultScheme = (base: IBaseColors): ThemeVariant => ({
    colors: {
        core: {
            background: {
                primary: base.surface.toRgbString(),
                secondary: white,
                tertiary: base.path.alpha(0.2).toRgbString(),
                ghost: base.phantom.alpha(0.05).toRgbString(),
                tone: base.phantom.desaturate(0.08).alpha(0.5).toRgbString(),
                selection: base.phantom.alpha(0.1).toRgbString(),
                hover: base.phantom.alpha(0.08).toRgbString(),
                brand: base.project.toRgbString(),
                yellow: base.yellow.toRgbString(),
                pink: base.pink.toRgbString(),
                success: 'rgb(232,240,219)',
                warning: base.warning.rotate(18).lighten(0.55).toRgbString(),
                alert: base.alert.rotate(18).lighten(0.55).toRgbString(),
                neutral: base.neutral.rotate(-21).lighten(0.65).toRgbString(),
            },
            divider: {
                divider: base.phantom.alpha(0.15).toRgbString(),
            },
            text: {
                primary: base.content.toRgbString(),
                secondary: base.content.alpha(0.7).toRgbString(),
                tertiary: base.content.alpha(0.5).toRgbString(),
                brand: base.project.toRgbString(),
                success: base.success.toRgbString(),
                warning: base.warning.toRgbString(),
                alert: base.alert.toRgbString(),
                neutral: base.neutral.toRgbString(),
            },
            link: {
                link: base.project.toRgbString(),
                linkHover: base.project.alpha(0.7).toRgbString(),
                linkMinor: 'rgb(255,238,238)',
                linkMinorHover: 'rgba(253,127,127,0.7)',
            },
        },
        control: {
            primary: {
                bg: base.project.toRgbString(),
                bgHover: base.project.rotate(3).darken(0.1).toRgbString(),
                bgPress: base.project.rotate(-3).lighten(0.1).toRgbString(),
                border: 'transparent',
                borderHover: 'transparent',
                borderPress: 'transparent',
                bgDisabled: base.surface.rotate(9).toRgbString(),
            },
            secondary: {
                bg: base.path.toRgbString(),
                bgHover: base.path.rotate(3).darken(0.1).toRgbString(),
                bgPress: base.path.rotate(-3).lighten(0.1).toRgbString(),
                border: base.path.toRgbString(),
                borderHover: 'transparent',
                borderPress: 'transparent',
                bgDisabled: base.surface.rotate(9).toRgbString(),
            },
            tertiary: {
                bg: base.path.alpha(0.2).toRgbString(),
                bgHover: base.path.alpha(0.3).toRgbString(),
                bgPress: base.path.alpha(0.2).toRgbString(),
                border: base.path.toRgbString(),
                borderHover: base.path.toRgbString(),
                borderPress: base.path.toRgbString(),
                bgDisabled: base.surface.rotate(9).toRgbString(),
            },
            default: {
                bg: 'hsla(30, 4%, 9%, 1)',
                bgHover: 'hsla(30, 51%, 12%, 1)',
                bgPress: 'hsla(30, 52%, 28%, 1)',
                border: 'transparent',
                borderHover: 'transparent',
                borderPress: 'transparent',
                bgDisabled: base.surface.rotate(9).toRgbString(),
            },
            input: {
                bg: base.phantom.alpha(0.07).toRgbString(),
                bgHover: base.phantom.alpha(0.1).toRgbString(),
                bgDisabled: base.phantom.alpha(0.2).toRgbString(),
                border: 'transparent',
                borderHover: base.phantom.alpha(0.15).toRgbString(),
                borderDisabled: 'transparent',
                text: base.content.toRgbString(),
                placeholder: base.content.alpha(0.55).toRgbString(),
                textDisabled: base.content.alpha(0.3).toRgbString(),
            },
            focus: {
                bg: white,
                border: base.path.toRgbString(),
            },
            alert: {
                bg: 'transparent',
                bgHover: base.alert.rotate(9).lighten(0.3).alpha(0.2).toRgbString(),
                bgPress: base.alert.rotate(9).lighten(0.3).alpha(0.5).toRgbString(),
                border: base.alert.toRgbString(),
                borderHover: base.alert.rotate(-3).darken(0.1).toRgbString(),
                borderPress: base.alert.rotate(-3).darken(0.1).toRgbString(),
            },
            check: {
                bg: base.project.toRgbString(),
                bgHover: base.project.rotate(3).darken(0.1).toRgbString(),
                bgDisabled: base.phantom.alpha(0.2).toRgbString(),
            },
            faint: {
                bg: base.phantom.alpha(0.15).toRgbString(),
                bgHover: base.phantom.alpha(0.2).toRgbString(),
                border: 'transparent',
                borderHover: base.phantom.alpha(0.15).toRgbString(),
            },
            toggle: {
                bg: base.phantom.alpha(0.15).toRgbString(),
                bgHover: base.phantom.alpha(0.2).toRgbString(),
            },
        },
    },
});

export const forbes = defaultScheme(base);
