import cn from 'classnames';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Text } from '../../atoms/Text';
import styles from './StatusPill.module.css';

export enum PillStatus {
    ALERT = 'alert',
    WARNING = 'warning',
    NEUTRAL = 'neutral',
    NEUTRAL_CHANGED = 'neutral_changed',
    SUCCESS = 'success',
}

interface IStatusPillProps {
    type: PillStatus;
    text: string;
    transparent?: boolean;
}

export const StatusPill: FC<IStatusPillProps> = ({ text, type, transparent }) => (
    <div
        className={cn(
            styles['container'],
            styles[`container_${type}`],
            transparent && styles['transparent']
        )}
    >
        <Text typo="micro" color="primary" className={styles['text']}>
            <FormattedMessage id={text} />
        </Text>
    </div>
);
