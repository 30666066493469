import { SelectChangeEvent, SelectProps } from '@mui/material';
import cn from 'classnames';
import { FC, lazy, ReactNode, Suspense, SuspenseProps, SyntheticEvent, useState } from 'react';

import { MuiDefaultInputFallback } from '../MuiDefaultInputFallback/MuiDefaultInputFallback';
import { useInputSxProps } from '../MuiInput/useInputSxProps';
import { usePaperSxProps } from '../MuiPaper/usePaperSxProps';
import { useSelectSxProps } from './useSelectSxProps';
import { ReactComponent as DropdownIcon } from 'assets/chevron-right.svg';

type TToggleOpenEvent = SyntheticEvent<Element, Event>;

interface IMuiSelectModuleProps extends SelectProps {
    fallback?: SuspenseProps['fallback'];
    onChangeValue?: (value: unknown) => void;
}

const MuiSelectLazy = lazy(() => import('@mui/material/Select'));
const MuiInputLazy = lazy(() => import('@mui/material/OutlinedInput'));

export const MuiSelect: FC<IMuiSelectModuleProps> = ({
    fallback,
    value,
    open,
    size,
    onOpen,
    onClose,
    onChange,
    onChangeValue,
    MenuProps,
    IconComponent = DropdownIcon,
    children,
    ...restProps
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(!!open);

    const { inputSxProps } = useInputSxProps(size);
    const { selectSxProps, selectSxPropsOpen } = useSelectSxProps();
    const { paperSxProps } = usePaperSxProps();

    const handleOpen = (ev: TToggleOpenEvent) => {
        setIsOpen(true);
        onOpen?.(ev);
    };

    const handleClose = (ev: TToggleOpenEvent) => {
        setIsOpen(false);
        onClose?.(ev);
    };

    const handleChange = (event: SelectChangeEvent<unknown>, child?: ReactNode) => {
        onChange?.(event, child);
        onChangeValue?.(event.target.value);
    };

    return (
        <Suspense fallback={fallback ?? <MuiDefaultInputFallback size={size} />}>
            <MuiSelectLazy
                {...restProps}
                value={value}
                open={open}
                size={size}
                onOpen={handleOpen}
                onClose={handleClose}
                onChange={handleChange}
                IconComponent={IconComponent}
                className={cn(
                    'story3_mui_input',
                    'story3_mui_select',
                    isOpen && 'story3_mui_select_open'
                )}
                input={
                    <MuiInputLazy
                        sx={{
                            '&.story3_mui_input': inputSxProps,
                            '&.story3_mui_input.story3_mui_select': selectSxProps,
                            '&.story3_mui_input.story3_mui_select_open': selectSxPropsOpen,
                        }}
                    />
                }
                MenuProps={{
                    ...MenuProps,
                    PaperProps: {
                        ...MenuProps?.PaperProps,
                        sx: { ...paperSxProps, ...MenuProps?.PaperProps?.sx },
                    },
                }}
            >
                {children}
            </MuiSelectLazy>
        </Suspense>
    );
};
