const filterUndefined = (query?: Record<string, string | undefined>): Record<string, string> => {
    const copy = { ...query };

    Object.keys(copy).forEach((key) => !copy[key] && delete copy[key]);

    return copy as Record<string, string>;
};

export const buildQueryString = (query?: Record<string, string | undefined>) => {
    const qs = new URLSearchParams(filterUndefined(query)).toString();
    return qs && `?${qs}`;
};
