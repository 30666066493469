import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import styles from './FooterControls.module.css';
import { AnalyticsEvents } from 'analytics/events';
import { ERoutes } from 'app/providers/routing';
import { Button } from 'components/atoms/Button/Button';
import { ExternalLink } from 'components/atoms/ExternalLink/ExternalLink';
import { Text } from 'components/atoms/Text';
import { SocialLinks } from 'components/molecules/SocialLinks/SocialLinks';
import { externalLinks } from 'constants/externalLinks';
import { useGDPRContext } from 'contexts/GDPRContext';
import { usePartnerRouterContext } from 'contexts/PartnerRouter/usePartnerRouterContext';
import { useThemeConfig } from 'hooks/theme/useThemeConfig';
import { useInAppNavigate } from 'hooks/useInAppNavigate';

export const FooterControls = () => {
    const { hidden: hideSocialLinksBlock } = useThemeConfig(
        (themeConfig) => themeConfig.socialLinksBlock
    );
    const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
    const { Component: DisclaimerModal } = useThemeConfig(
        (themeConfig) => themeConfig.disclaimerModal
    );
    const { show: showBanner } = useGDPRContext();
    const navigate = useInAppNavigate();
    const { getRoute } = usePartnerRouterContext();

    const onDisclaimerClose = () => {
        setShowDisclaimer(false);
    };

    const handleAboutUsClick = () => {
        AnalyticsEvents.sidebarSectionClick('about-us');
    };

    const handleLanguagesClick = () => {
        AnalyticsEvents.sidebarSectionClick('language');
        navigate(ERoutes.LanguageSettings);
    };

    const handleContactUsClick = () => {
        AnalyticsEvents.sidebarSectionClick('contact');
        window.LiveChatWidgetAPI?.openChat();
    };

    const handleFaqClick = () => {
        AnalyticsEvents.sidebarSectionClick('faq');
    };

    const handleLegalClick = () => {
        AnalyticsEvents.sidebarSectionClick('legal');
        navigate(ERoutes.LegalDocuments);
    };

    const handlePrivacyClick = () => {
        AnalyticsEvents.sidebarSectionClick('privacy');
        showBanner();
    };

    const handleApiClick = () => {
        AnalyticsEvents.sidebarSectionClick('api');
    };

    return (
        <>
            <NavLink
                to={getRoute(ERoutes.AboutUs)}
                className={styles['link']}
                onClick={handleAboutUsClick}
                target="_blank"
            >
                <Text typo="minor" color="secondary">
                    <FormattedMessage id="left_sidebar.links.about_us" />
                </Text>
            </NavLink>
            {!!DisclaimerModal && (
                <Button
                    className={styles['button']}
                    variant="text"
                    textTypo="minor"
                    textColor="secondary"
                    onClick={() => setShowDisclaimer(true)}
                    noPadding
                    stretched
                >
                    <FormattedMessage id="left_sidebar.links.disclaimer" />
                </Button>
            )}
            <Button
                className={styles['button']}
                variant="text"
                textTypo="minor"
                textColor="secondary"
                onClick={handleLanguagesClick}
                noPadding
                stretched
            >
                <FormattedMessage id="left_sidebar.links.language_settings" />
            </Button>
            <NavLink to={getRoute(ERoutes.FAQ)} className={styles['link']} onClick={handleFaqClick}>
                <Text typo="minor" color="secondary">
                    <FormattedMessage id="left_sidebar.links.faq" />
                </Text>
            </NavLink>
            <Button
                className={styles['button']}
                variant="text"
                textTypo="minor"
                textColor="secondary"
                onClick={handleContactUsClick}
                noPadding
                stretched
            >
                <FormattedMessage id="left_sidebar.links.contact_us" />
            </Button>
            <Button
                className={styles['button']}
                variant="text"
                textTypo="minor"
                textColor="secondary"
                onClick={handleLegalClick}
                noPadding
                stretched
            >
                <FormattedMessage id="left_sidebar.links.legal_agreements" />
            </Button>
            <Button
                className={styles['button']}
                variant="text"
                textTypo="minor"
                textColor="secondary"
                onClick={handlePrivacyClick}
                noPadding
                stretched
            >
                <FormattedMessage id="left_sidebar.links.privacy_settings" />
            </Button>
            <ExternalLink href={externalLinks.apiDocs} color="inherit" onClick={handleApiClick}>
                <Text typo="minor" color="secondary">
                    <FormattedMessage id="left_sidebar.links.api" />
                </Text>
            </ExternalLink>
            {!hideSocialLinksBlock && <SocialLinks />}
            {showDisclaimer && DisclaimerModal && <DisclaimerModal onClose={onDisclaimerClose} />}
        </>
    );
};
