export const gtmEventNames = {
    /*
    To use an event as a conversion event in Google Analytics, the event name must meet the following criteria:
        - max 40 characters
        - should only start with a letter
        - only letters, numbers and underscores are allowed
    * */
    add_own_story: 'story3_story_own_add-plot',
    add_twist_to_own_story: 'story3_story_own_add-twist',
    add_twist_to_someones_story: 'story3_story_foreign_add-twist',
    currency_package_purchased: 'story3_shop_package_purchase',
    registration_completed: 'story3_onboarding_second-screen_submit',
    switch_story_right: 'story3_story_switch_right',
    switch_story_left: 'story3_story_switch_left',
    twist_opened: 'twist_opened',
};
