import * as Sentry from '@sentry/react';
import jwt_decode from 'jwt-decode';

export type JwtTokenParsed = {
    username: string;
    phone_number: string;
    sub: string;
};

export const parseJWT = (token: string): JwtTokenParsed => {
    try {
        const { username = '', phone_number = '', sub = '' } = jwt_decode<JwtTokenParsed>(token);

        return {
            username,
            phone_number,
            sub,
        };
    } catch (e) {
        Sentry.captureException(e);
        return {
            username: '',
            phone_number: '',
            sub: '',
        };
    }
};
