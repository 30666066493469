import { IUserSchema } from './types';
import { prepareUserResponse } from 'api/user/prepareUserResponse';
import { httpApi } from 'core/httpApi';
import { TUser } from 'types/user';

export const createUser = async (accessToken: string): Promise<TUser> => {
    const agent = localStorage.getItem('agent');

    const data = agent ? { agent } : null;

    const response: IUserSchema = await httpApi.v2.post('users', data, {
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
    });

    localStorage.removeItem('agent');

    return prepareUserResponse(response);
};
