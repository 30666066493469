import { useSearchParams } from 'react-router-dom';

import { ERoutes } from 'app/providers/routing';

const SEARCH_PARAM = 'redirectToFAQ';
export const useRedirectToWritersFaqParam = () => {
    const [searchParams] = useSearchParams();

    return {
        hasParam: searchParams.has(SEARCH_PARAM),
        withParam: (route: ERoutes) => `${route}?${SEARCH_PARAM}`,
    };
};
