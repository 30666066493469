import { XsollaMetrika } from '../xsolla-metrika';
import { xmEventNames } from '../xsolla-metrika/xmEventNames';

export const openAgentAgreementFormClick = (agreementVisited: boolean) => {
    XsollaMetrika.elementClick(
        agreementVisited
            ? xmEventNames.agent_banner_continue_agreement
            : xmEventNames.agent_banner_start_agreement,
        'open'
    );
};

export const becomeAgentCardCloseClick = (agreementVisited: boolean) => {
    XsollaMetrika.elementClick(
        agreementVisited
            ? xmEventNames.agent_banner_continue_agreement
            : xmEventNames.agent_banner_start_agreement,
        'close'
    );
};

export const openSignedAgentAgreementClick = () => {
    XsollaMetrika.elementClick(xmEventNames.agent_banner_check_agreement);
};

export const copyAgentLinkClick = () => {
    XsollaMetrika.elementClick(xmEventNames.agent_banner_copy_link);
};

export const acceptAgentAgreementClick = () => {
    XsollaMetrika.elementClick(xmEventNames.agent_agreement_accept);
};

export const agentAgreementFormBannerClick = (fill: boolean) => {
    XsollaMetrika.elementClick(xmEventNames.agent_agreement_click_banner, fill ? 'next' : 'close');
};
