import { TMuiInputSize, TMuiSxProps } from '../types';
import { useThemeContext } from 'contexts/Theme/useThemeContext';

export const useAutocompleteSxProps = (size?: TMuiInputSize) => {
    const { cssTheme } = useThemeContext();

    const autocompleteSxProps: TMuiSxProps = {
        padding: 0,
        '&.MuiInputBase-adornedStart': {
            paddingLeft: '16px',
        },
        '&.MuiInputBase-adornedEnd': {
            paddingRight: '16px',
        },
        '.MuiAutocomplete-endAdornment': {
            position: 'relative',
            top: 'initial',
            right: 'initial',
        },
        '.MuiAutocomplete-clearIndicator': {
            marginRight: size === 'small' ? '-4px' : '0',
            padding: size === 'small' ? '4px' : '0',
            svg: {
                width: size === 'small' ? '16px' : '24px',
                height: size === 'small' ? '16px' : '24px',
                color: cssTheme.colors.core.text.secondary,
            },
        },
    };

    const autocompleteSxPropsPaper: TMuiSxProps = {
        '.MuiAutocomplete-listbox': {
            '.MuiAutocomplete-option[aria-disabled="true"]': {
                opacity: 'initial',
            },
        },
    };

    return { autocompleteSxProps, autocompleteSxPropsPaper };
};
