import cn from 'classnames';
import { FC } from 'react';

import styles from './SidePattern.module.css';

export type SidePatternVariant = 'primary' | 'secondary';

interface ISidePatternProps {
    variant?: SidePatternVariant;
    className?: string;
}

export const SidePattern: FC<ISidePatternProps> = ({ variant = 'primary', className }) => (
    <div className={cn(className, styles[variant])} />
);
