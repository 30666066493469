import { FC, PropsWithChildren } from 'react';

import styles from './ModalHeaderBasic.module.css';
import { ReactComponent as CrossSVG } from 'assets/cross.svg';
import { PlainButton } from 'components/atoms/PlainButton';
import { Text } from 'components/atoms/Text';

interface IModalHeaderProps extends PropsWithChildren {
    className?: string;
    onClose?: () => void;
}
export const ModalHeaderBasic: FC<IModalHeaderProps> = ({ className, children, onClose }) => (
    <div className={styles['container']}>
        <Text typo="body-md-accent">{children}</Text>
        {onClose && (
            <PlainButton className={styles['close']} onClick={onClose}>
                <CrossSVG width={24} height={24} />
            </PlainButton>
        )}
    </div>
);
