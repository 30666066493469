import { httpApi } from 'core/httpApi';
import { ITransaction } from 'types/transactions';

export interface IGetTransactionsParams {
    limit?: number;
    offset?: number;
}

export type GetTransactionsResponse = ITransaction[];

export const getTransactions = async (
    params: IGetTransactionsParams
): Promise<GetTransactionsResponse> =>
    httpApi.v2.get<GetTransactionsResponse, GetTransactionsResponse>('billing/transactions', {
        params,
    });
