import cn from 'classnames';
import { FC, ReactNode } from 'react';

import styles from './Button.module.css';

interface IButtonIconProps {
    isIconWithoutHeight: boolean;
    icon: ReactNode;
}

export const ButtonIcon: FC<IButtonIconProps> = ({ icon, isIconWithoutHeight }) => {
    if (!icon) {
        return null;
    }

    return (
        <span className={cn(styles['icon'], isIconWithoutHeight && styles['icon_without_height'])}>
            {icon}
        </span>
    );
};
