import { FC, PropsWithChildren } from 'react';

import { FullscreenLoader } from 'components/molecules/FullscreenLoader';
import { useSetThemeByRoute } from 'contexts/PartnerRouter/useSetThemeByRoute';

export const ConfigurableApp: FC<PropsWithChildren> = ({ children }) => {
    const { themeId } = useSetThemeByRoute();

    if (!themeId) {
        return <FullscreenLoader />;
    }

    return <>{children}</>;
};
