import * as Sentry from '@sentry/react';
import { AbExperimentsApi } from '@xsolla/ab-experiments-sdk';

import { queryClient } from 'app/providers/ReactQueryProvider';
import { XSOLLA_AB_GROUP_URL, XSOLLA_PROJECT_ID } from 'config';

class AbServiceClass {
    private productName = 'story3';
    private groupUrl = XSOLLA_AB_GROUP_URL;
    private projectId = `${XSOLLA_PROJECT_ID}`;
    private conditions: Record<string, string> = {};
    private currentGroup: string | null = null;

    public async fetchGroup(country: string = '') {
        if (!this.groupUrl) {
            return;
        }

        const api = new AbExperimentsApi(
            {
                product: this.productName,
                projectId: this.projectId,
            },
            { endPoint: this.groupUrl }
        );

        this.conditions = { ...this.conditions, country };

        api.addConditions(...this.getConditionsArray());

        try {
            this.currentGroup = await queryClient.fetchQuery({
                queryKey: [],
                queryFn: () => api.fetchGroup(),
            });
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    public getAbParams() {
        if (this.currentGroup) {
            return {
                ...this.conditions,
                project_id: this.projectId,
                experiment_group: this.currentGroup,
            };
        }
    }

    public isCurrentGroup(group: string) {
        return !!group && group === this.currentGroup;
    }

    private getConditionsArray() {
        return Object.entries(this.conditions).map(([name, value]) => ({
            name,
            value,
        }));
    }
}

export const AbService = new AbServiceClass();
