import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { USER_QUERY_KEY } from './useUserQuery';
import { TUser } from 'types/user';

export const useSetUserQueryData = () => {
    const queryClient = useQueryClient();

    return useCallback(
        (user: TUser) => {
            queryClient.setQueryData(USER_QUERY_KEY, user);
        },
        [queryClient]
    );
};
