import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { AbService } from 'analytics/ab-service';
import { XsollaMetrika } from 'analytics/xsolla-metrika';
import { fetchCountry } from 'app/fetchCountry';
import { ConfigurableApp } from 'components/templates/ConfigurableApp/ConfigurableApp';
import { BalanceNotificationContextProvider } from 'contexts/BalanceNotificationContext';
import { GDPRContextProvider } from 'contexts/GDPRContext';
import { NotificationsContextProvider } from 'contexts/NotificationsContext';
import { ReplenishBalanceModalContextProvider } from 'contexts/ReplenishBalanceModalContext';
import { UiLanguageContextProvider } from 'contexts/UiLanguageContext/UiLanguageContextProvider';
import { loadBalanceToCache } from 'hooks/queries/balance/useUserBalanceQuery';
import { loadStoryLanguagesToCache } from 'hooks/queries/languages/useStoryLanguages';
import { loadUserToCache } from 'hooks/queries/user/useUserQuery';
import { ELanguage } from 'types/languages';
import { TTranslations } from 'types/translations';
import { getIsSearchBotAgent } from 'utils/getIsSearchBotAgent';
import { setStoriesLanguages } from 'utils/languages/setStoriesLanguages';
import { uiLanguageClientStorage } from 'utils/languages/uiLanguageClientStorage';
import { getCoreTranslations } from 'utils/translations/getCoreTranslations';

interface IInitAppReturnArgs {
    isEu: boolean;
    language: ELanguage;
    translations: TTranslations;
}

const initApp = async (): Promise<IInitAppReturnArgs> => {
    const clientLanguage = uiLanguageClientStorage.get();

    if (getIsSearchBotAgent()) {
        const translations = await getCoreTranslations(clientLanguage);
        return { isEu: false, language: clientLanguage, translations };
    }

    const [user, countryData] = await Promise.all([
        loadUserToCache(),
        fetchCountry(),
        loadStoryLanguagesToCache(),
    ]);

    const isAuthenticated = !!user?.isAuthenticated;
    const userId = isAuthenticated ? user.id : null;
    const language = (isAuthenticated && user.uiLanguage) || clientLanguage;
    const country = countryData?.country;
    const isEu = !!countryData?.isEu;

    const [translations] = await Promise.all([
        getCoreTranslations(language),
        AbService.fetchGroup(country),
        isAuthenticated && loadBalanceToCache(),
        setStoriesLanguages(user),
    ]);

    XsollaMetrika.init(userId, country);

    return { isEu, language, translations };
};

// TODO: Поддержка Suspense в React Query пока что экспериментальная, в будущем можно переделать на него, если зарелизят стабильное API
export const App = lazy(async () => {
    const initialUrl = window.location.href;
    const { isEu, language, translations } = await initApp();

    return {
        default: () => (
            <UiLanguageContextProvider language={language} translations={translations}>
                <GDPRContextProvider isEu={isEu} initialUrl={initialUrl}>
                    <NotificationsContextProvider>
                        <ReplenishBalanceModalContextProvider>
                            <BalanceNotificationContextProvider>
                                <ConfigurableApp>
                                    <Outlet />
                                </ConfigurableApp>
                            </BalanceNotificationContextProvider>
                        </ReplenishBalanceModalContextProvider>
                    </NotificationsContextProvider>
                </GDPRContextProvider>
            </UiLanguageContextProvider>
        ),
    };
});
