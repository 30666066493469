import { IGetLanguagesResponse } from 'api/languages/getStoryLanguages';
import { storyLanguagesLSService } from 'api/languages/storyLanguagesLSService';
import { queryClient } from 'app/providers/ReactQueryProvider';
import { STORY_LANGUAGES_KEY } from 'hooks/queries/languages/useStoryLanguages';
import { TUser } from 'types/user';
import { DEFAULT_LANGUAGE, getMatchedDefaultLanguages } from 'utils/languages/getDefaultLanguages';

export const setStoriesLanguages = async (user: TUser | undefined) => {
    const allStoriesLanguages = queryClient
        .getQueryData<IGetLanguagesResponse>([STORY_LANGUAGES_KEY])
        ?.filter((lang) => lang.count > 0)
        .map((lang) => lang.code) || [DEFAULT_LANGUAGE];

    const localPreferences = storyLanguagesLSService.get();
    const clientPreferences = localPreferences.length
        ? localPreferences
        : getMatchedDefaultLanguages(allStoriesLanguages);

    if (!user?.isAuthenticated) {
        await storyLanguagesLSService.set(clientPreferences, user);
        return;
    }

    const { preferredLanguages } = user;
    if (preferredLanguages.length) {
        await storyLanguagesLSService.set(preferredLanguages, user);
        return;
    }

    await storyLanguagesLSService.set(clientPreferences, user);

    return;
};
