import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getStoryLanguages, IGetLanguagesResponse } from 'api/languages/getStoryLanguages';
import { queryClient } from 'app/providers/ReactQueryProvider';

export const STORY_LANGUAGES_KEY = 'story_languages';

export const loadStoryLanguagesToCache = async () => {
    try {
        const storyLanguages = await getStoryLanguages();
        queryClient.setQueryData([STORY_LANGUAGES_KEY], storyLanguages);
    } catch {}
};

export const useStoryLanguages = () =>
    useQuery<IGetLanguagesResponse, AxiosError>({
        queryFn: getStoryLanguages,
        queryKey: [STORY_LANGUAGES_KEY],
        refetchOnWindowFocus: false,
    });
