import { IGetStoryByIdResponse } from '../stories/getReadableStoryById';
import { forbesMockReadHistory } from 'api/forbesMock/forbesMockReadHistorySSService';
import { story1, story2 } from 'api/forbesMock/forbesMockStoriesData';
import { getContentIdBySummaryInput } from 'api/refferalSystem/types';

export const forbesMockGetStoryBySummary = async (
    summary: getContentIdBySummaryInput['summary'],
    referrer?: getContentIdBySummaryInput['referrer']
): Promise<IGetStoryByIdResponse> => {
    const purchasedTwists = forbesMockReadHistory.get();
    if (
        summary ===
        'smc_chairman_mark_liu_retiring_in_2024_after_leading_the_company_through_a_chip_shortage_and_geopolitical_conflict'
    ) {
        return Promise.resolve({
            path: [story1(purchasedTwists)],
        });
    }

    return Promise.resolve({
        path: [story2(purchasedTwists)],
    });
};
