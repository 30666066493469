import { ELanguage } from 'types/languages';

export const DEFAULT_LANGUAGE = ELanguage.English;

export const getDefaultLanguage = () => {
    const browserLanguages = Array.from(
        new Set(navigator.languages.map((lang) => lang.slice(0, 2)))
    );
    return browserLanguages.length ? browserLanguages : [DEFAULT_LANGUAGE];
};

/**
 * compares the list of languages with provided fixedArray
 * and returns only allowed languages from default array or an emty array
 * @param matchingArray - list of languages to match
 * @param fixedArray - list of allowed languages
 */
export const getMatchedLanguages = (matchingArray: string[], fixedArray: string[]) => {
    const matchedLanguages = matchingArray.filter((lang) => fixedArray.includes(lang));
    return matchedLanguages.length ? matchedLanguages : [DEFAULT_LANGUAGE];
};

export const getMatchedDefaultLanguages = (fixedArray: string[]) =>
    getMatchedLanguages(getDefaultLanguage(), fixedArray);
