import { generatePath } from 'react-router-dom';

import { EMyStoriesTabs } from 'components/organisms/myStories/MyStoriesTabs';
import { EFAQTabs } from 'components/pages/FAQPage/types';
import { buildQueryString } from 'helpers/buildQueryString';

export enum ERoutes {
    Main = '/',
    AboutUs = '/about-us?utm_source=main_website',
    ReferralStory = '/:referralLink',
    Story = '/story/:id',
    Profile = '/profile',
    Transactions = '/profile/transactions',
    MyStories = '/my-stories',
    MyStoriesReading = '/my-stories/reading',
    MyStoriesWriting = '/my-stories/writing',
    CreateStory = '/my-stories/create',
    EditStory = '/my-stories/:storyId',
    CreateTwist = '/my-stories/twists/:parentId/create',
    EditTwist = '/my-stories/twists/:twistId',
    FAQ = '/faq',
    AgentFAQ = '/faq/agent',
    WritersFAQ = '/faq/writer',
    LegalDocuments = '/legal',
    ContentPolicy = '/legal/content-policy',
    DMCAPolicy = '/legal/dmca-policy',
    PrivacyPolicy = '/legal/privacy-policy',
    RefundPolicy = '/legal/refund-policy',
    TermsOfService = '/legal/terms-of-service',
    CookieStatement = '/legal/cookie-statement',
    AgentInvite = '/agent-invite/:nickname',
    EntryPoint = '/entry',
    LanguageSettings = '/languages',
    LanguageSettingsDev = '/languages/dev',
}

export const getStoryRouteById = (id: string, query?: Record<string, string | undefined>) =>
    generatePath(ERoutes.Story, { id }) + buildQueryString(query);

export const getStoryEditorUrl = (storyId: string, query: string = '') =>
    generatePath(ERoutes.EditStory, { storyId }) + query;

export const getTwistEditorUrl = (twistId: string, query: string = '') =>
    generatePath(ERoutes.EditTwist, { twistId }) + query;

export const getCreateTwistUrl = (parentId: string, query: string = '') =>
    generatePath(ERoutes.CreateTwist, { parentId }) + query;

export const getMyStoriesTabUrl = (tabName: EMyStoriesTabs) => `${ERoutes.MyStories}/${tabName}`;

export const getFAQTabUrl = (tabName: EFAQTabs) => `${ERoutes.FAQ}/${tabName}`;

export const getAgentInviteLink = (nickname: string) =>
    generatePath(ERoutes.AgentInvite, { nickname });
