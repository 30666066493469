import type { FC } from 'react';

import { FloatingLayout } from './FloatingLayout';
import { StaticLayout } from './StaticLayout';
import { INewStoryButtonLayoutProps } from './types';
import { useIsEditorPath } from 'hooks/useIsEditorPath';

export interface INewStoryButtonProps extends INewStoryButtonLayoutProps {
    isFloating?: boolean;
}

export const NewStoryButton: FC<INewStoryButtonProps> = ({
    className,
    tooltipClassName,
    isFloating,
    onClick,
}) => {
    const Layout = isFloating ? FloatingLayout : StaticLayout;
    const isEditorPath = useIsEditorPath();

    return (
        <Layout
            className={className}
            tooltipClassName={tooltipClassName}
            isDisabled={isEditorPath}
            onClick={onClick}
        />
    );
};
