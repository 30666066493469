import { TooltipProps } from '@mui/material';
import { FC, lazy, Suspense } from 'react';

const MuiTooltipLazy = lazy(() => import('@mui/material/Tooltip'));

export const MuiTooltip: FC<TooltipProps> = ({ children, ...rest }) => (
    <Suspense fallback={children}>
        <MuiTooltipLazy {...rest}>{children}</MuiTooltipLazy>
    </Suspense>
);
