import { gtmPushEvent } from '../gtm/gtm';
import { gtmEventNames } from '../gtm/gtmEventNames';
import { XsollaMetrika } from '../xsolla-metrika';
import { xmEventNames } from '../xsolla-metrika/xmEventNames';

export const askEmailStart = () => {
    XsollaMetrika.elementClick(xmEventNames.onboarding_email_specification, 'start');
};

export const askEmailComplete = () => {
    XsollaMetrika.elementClick(xmEventNames.onboarding_email_specification, 'complete');
};

export const onboardingModalShow = (step: number) => {
    XsollaMetrika.customEvent(xmEventNames.onboarding_modal_show, { step });
};

export const onboardingModalClose = (step: number) => {
    XsollaMetrika.elementClick(xmEventNames.onboarding_modal_close, String(step));
};

export const onboardingModalNext = (step: number) => {
    XsollaMetrika.elementClick(xmEventNames.onboarding_modal_next, String(step));
};

export const onboardingFirstScreenAgeValidationError = () => {
    XsollaMetrika.customEvent(xmEventNames.onboarding_first_screen_age_error);
};

export const onboardingFirstScreenSubmit = () => {
    XsollaMetrika.elementClick(xmEventNames.onboarding_first_screen_submit);
};

export const onboardingSecondScreenSubmit = () => {
    XsollaMetrika.elementClick(xmEventNames.onboarding_second_screen_submit);
    gtmPushEvent(gtmEventNames.registration_completed);
};
