import type { IConfig } from '../../types/config';

import { routesOptions } from './routes';
import { forbesMockBuyTwistByHash } from 'api/forbesMock/forbesMockBuyTwistByHash';
import { forbesMockGetLastOpenedStory } from 'api/forbesMock/forbesMockGetLastOpenedStory';
import { forbesMockGetOpenedTwists } from 'api/forbesMock/forbesMockGetOpenedTwists';
import { forbesMockGetPublishedStories } from 'api/forbesMock/forbesMockGetPublishedStories';
import { forbesMockGetReadableStoryById } from 'api/forbesMock/forbesMockGetReadableStoryById';
import { forbesMockGetStoryBySummary } from 'api/forbesMock/forbesMockGetStoryBySummary';
import { forbesMockGetTransactions } from 'api/forbesMock/forbesMockGetTransactions';
import { forbesMockGetUserBalance } from 'api/forbesMock/forbesMockGetUserBalance';
import { forbesMockPostAnalyticsView } from 'api/forbesMock/forbesMockPostAnalyticsView';
import { forbesMockPostLastOpenedStory } from 'api/forbesMock/forbesMockPostLastOpenedStory';
import { forbesMockReadFreeTwist } from 'api/forbesMock/forbesMockReadFreeTwist';
import { forbesMockReadTwist } from 'api/forbesMock/forbesMockReadTwist';
import SidePatternMobile from 'assets/80lv/SidePatternMobile.png';
import SidePatternPC from 'assets/80lv/SidePatternPC.png';
import LogoImage from 'assets/80lv/logo.svg';

export const config: IConfig = {
    routesOptions: {
        ...routesOptions,
    },
    api: {
        getReadableStoryById: forbesMockGetReadableStoryById,
        getStories: forbesMockGetPublishedStories,
        getStoryBySummary: forbesMockGetStoryBySummary,
        readFreeTwist: forbesMockReadFreeTwist,
        getLastOpenedStory: forbesMockGetLastOpenedStory,
        buyTwistByHash: forbesMockBuyTwistByHash,
        getUserBalance: forbesMockGetUserBalance,
        readTwist: forbesMockReadTwist,
        getTransactions: forbesMockGetTransactions,
        getOpenedTwists: forbesMockGetOpenedTwists,
        postLastOpenedStory: forbesMockPostLastOpenedStory,
        postAnalyticsView: forbesMockPostAnalyticsView,
    },
    withSideBar: {
        style: {
            sidePattern: {
                size: {
                    mobile: {
                        width: 16,
                    },
                    desktop: {
                        width: 52,
                    },
                },
            },
        },
    },
    sidePattern: {
        style: {
            backGroundImage: {
                desktop: `url('${SidePatternPC}')`,
                mobile: `url('${SidePatternMobile}')`,
            },
            backgroundSize: {
                desktop: '100% auto',
                mobile: '100% auto',
            },
        },
    },
    logoSidebar: {
        onion: {
            hidden: true,
        },
    },
    logo: {
        image: LogoImage,
        style: {
            size: {
                // width of logo image
                mobile: {
                    width: 128,
                },
                desktop: {
                    width: 189,
                },
            },
            skeleton: {
                // height of logo image
                size: {
                    mobile: {
                        height: 50,
                    },
                    desktop: {
                        height: 62,
                    },
                },
            },
        },
    },
    twistCard: {
        style: {
            fillingBackground: 'var(--colors-control-secondary-bg)',
            frontGradient:
                'linear-gradient(180deg,rgb(from var(--colors-control-secondary-bg) r g b / 30%) 0%,' +
                'transparent 50%,transparent 100%)',
            cardBought: {
                titleColor: 'var(--colors-core-background-secondary)',
            },
            cardSelected: {
                background: 'var(--colors-control-secondary-bg)',
                titleColor: 'var(--colors-core-background-secondary)',
                authorColor: 'var(--colors-core-background-primary)',
            },
        },
    },
    addUserPathButton: {
        style: {
            tip: {
                background: 'var(--colors-core-text-success)',
            },
        },
    },
    onboardingModal: {
        hidden: true,
    },
    writersFAQCard: {
        hidden: true,
    },
    callToWriteStoriesCard: {
        hidden: true,
    },
    agentFlow: {
        hidden: true,
    },
    readyToWriteCard: {
        hidden: true,
    },
    skippableModal: {
        hidden: true,
    },
    signUpModal: {
        hidden: true,
    },
    replenishBalanceModalWindow: {
        disableBuy: true,
    },
    disclaimerModal: {
        Component: null,
    },
    socialLinksBlock: {
        hidden: true,
    },
    modalTemplateSecond: {
        defaultSidePattern: 'primary',
    },
};
