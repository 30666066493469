import { forbesMockReadHistory } from 'api/forbesMock/forbesMockReadHistorySSService';
import { story1, story2 } from 'api/forbesMock/forbesMockStoriesData';

interface IForbesMockGetStoryByIdParams {
    storyId: string;
    offset?: number;
    signal?: AbortSignal;
}

export const forbesMockGetReadableStoryById = ({
    storyId,
    offset = 0,
    signal,
}: IForbesMockGetStoryByIdParams) => {
    const purchasedTwists = forbesMockReadHistory.get();
    if (storyId === story1().hashId) {
        if (offset % 2 === 0) {
            return Promise.resolve({
                path: [story1(purchasedTwists)],
            });
        }
        return Promise.resolve({
            path: [story2(purchasedTwists)],
        });
    }

    if (offset % 2 === 0) {
        return Promise.resolve({
            path: [story2(purchasedTwists)],
        });
    }

    return Promise.resolve({
        path: [story1(purchasedTwists)],
    });
};
