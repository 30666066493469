import cn from 'classnames';
import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import styles from './TitledLogo.module.css';
import { Logo } from 'components/atoms/Logo';
import { Text } from 'components/atoms/Text';
import { useIsTablet } from 'hooks/layout';

export interface ITitledLogoProps {
    className?: string;
}

export const TitledLogo: FC<ITitledLogoProps> = ({ className }) => {
    const isTablet = useIsTablet();

    const intl = useIntl();

    const slogansAmount = Object.keys(intl.messages).filter((key) =>
        key.startsWith('left_sidebar.logo.slogan')
    ).length;

    const sloganNumber = 1 + Math.floor(Math.random() * slogansAmount);

    return (
        <div className={cn(className, styles['container'])}>
            <Logo />
            {!isTablet && (
                <Text typo="h4" color="primary" className={styles['title']}>
                    <FormattedMessage
                        id={`left_sidebar.logo.slogan${sloganNumber}`}
                        values={{
                            nobreak: '\u00a0',
                        }}
                    />
                </Text>
            )}
        </div>
    );
};
