import { httpApi } from 'core/httpApi';
import { TLastOpenedStory } from 'entities/story/model';

interface IGetLastOpenedStoryDto {
    hashId: string | null;
}

const mapToLastOpenedStory = (dto: IGetLastOpenedStoryDto): TLastOpenedStory =>
    dto.hashId ? { hashId: dto.hashId } : null;

export const getLastOpenedStory = async () => {
    const res = await httpApi.v2.get<IGetLastOpenedStoryDto, IGetLastOpenedStoryDto>(
        `stories/last-opened-story`
    );
    return mapToLastOpenedStory(res);
};

export type TGetLastOpenedStoryApiFunction = typeof getLastOpenedStory;
