import { createContext } from 'react';

import { ELanguage } from 'types/languages';

export interface IUiLanguageContext {
    uiLanguage: ELanguage;
    setUiLanguage: (language: ELanguage) => Promise<void>;
    isItalicAllowed: boolean;
}

export const UiLanguageContext = createContext<IUiLanguageContext>({
    uiLanguage: ELanguage.English,
    setUiLanguage: () => new Promise(() => {}),
    isItalicAllowed: true,
});
