import { accessTokenCService } from 'api/accessToken/accessTokenCService';
import { sessionStorageService } from 'utils/sessionStorageService/sessionStorageService';

const forbesMockReadHistorySSService = sessionStorageService<unknown>('forbes_mock_read_history');

const pushToSS = (value: string) => {
    const ssValue = forbesMockReadHistorySSService.get();
    const valuesArray = ssValue && Array.isArray(ssValue) ? ssValue : [];

    const hasDuplicateValue = valuesArray.includes(value);

    if (!hasDuplicateValue) {
        valuesArray.push(value);
    }

    forbesMockReadHistorySSService.set(valuesArray);
    // хз как, но это работает
    forbesMockReadHistorySSService.set(valuesArray);
    forbesMockReadHistorySSService.get();
    forbesMockReadHistorySSService.get();
};

export const forbesMockReadHistory = {
    set: (value: string[]) => forbesMockReadHistorySSService.set(value),
    get: () => {
        const accessToken = accessTokenCService.get();
        const savedValue = forbesMockReadHistorySSService.get();
        if (savedValue && Array.isArray(savedValue)) {
            if (!accessToken) {
                return [];
            }

            return savedValue;
        }
        forbesMockReadHistorySSService.set([]);
        return [];
    },
    push: pushToSS,
};
