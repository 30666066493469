import { createContext, ReactNode } from 'react';

export interface INotification {
    id: string;
    element: ReactNode;
    showTime?: number;
    showCloseButton?: boolean;
}

export type PushNotificationArgs = Omit<INotification, 'id'>;

export interface INotificationsContext {
    items: INotification[];
    push: (notification: PushNotificationArgs) => string;
    pop: (id: string) => void;
}

export const NotificationsContext = createContext<INotificationsContext>({
    items: [],
    push: () => '',
    pop: () => {},
});
