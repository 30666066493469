import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useGDPRLocalization = () => {
    const { locale, formatMessage } = useIntl();

    const customTranslation = useMemo(() => {
        const msg = (id: string) => formatMessage({ id });
        return {
            welcome_screen_data_processing: msg('gdpr_banner.welcome_screen_data_processing'),
            opt_out_consent: msg('gdpr_banner.opt_out_consent'),
            essential_cookies_description: msg('gdpr_banner.essential_cookies_description'),
            analytical_performance_cookies_description: msg(
                'gdpr_banner.analytical_performance_cookies_description'
            ),
            functional_cookies_description: msg('gdpr_banner.functional_cookies_description'),
            targeting_cookies_description: msg('gdpr_banner.targeting_cookies_description'),
            privacy_policy_link_name: msg('gdpr_banner.privacy_policy_link_name'),
            data_processing_consent_customer_checkbox_tooltip: ' ',
            settings_footer_description: ' ',
        };
    }, [locale, formatMessage]);

    return { locale, customTranslation };
};
