import { JwtTokenParsed, parseJWT } from 'components/organisms/LiveChatWidget/utils';

declare global {
    interface Window {
        LiveChatWidget: {
            iframe: HTMLIFrameElement;
            customFields: {
                externalUserId?: string | undefined;
                phone?: string | undefined;
            };
            visitor: {
                email?: string | undefined;
            };
            type: string;
        };
        LiveChatWidgetAPI: {
            openChat: () => void;
        };
    }
}

export const mountChatWidget = (url: string, target: string, token: string | null) => {
    const jwtTokenParsed: JwtTokenParsed | null = token ? parseJWT(token) : null;

    const visitor = {
        ...(jwtTokenParsed?.username && { email: jwtTokenParsed.username }),
    };

    const customFields = {
        ...(jwtTokenParsed?.phone_number && {
            phone: jwtTokenParsed.phone_number,
        }),
        ...(jwtTokenParsed?.sub && {
            externalUserId: jwtTokenParsed.sub,
        }),
    };

    const liveChatWidget = {
        type: 'babka',
        ...(visitor && { visitor }),
        ...(customFields && { customFields }),
    };

    window.LiveChatWidget = window.LiveChatWidget || liveChatWidget;
    window.LiveChatWidget.iframe = window.LiveChatWidget.iframe || {
        url,
        target,
        position: 'right',
    };
    const script = document.createElement('script');
    script.async = true;
    script.src = new URL('//cdn.chat.xsolla.com/widget/livechat-iframe.js', url).toString();
    document.body.append(script);
};

export const unmountChatWidget = () => {
    const livechat = document.getElementById('livechat');
    livechat && document.body.removeChild(livechat);
};
