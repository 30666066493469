import { AutocompleteProps, OutlinedInputProps } from '@mui/material';
import cn from 'classnames';
import { forwardRef, lazy, Suspense, SuspenseProps } from 'react';

import { MuiDefaultInputFallback } from '../MuiDefaultInputFallback/MuiDefaultInputFallback';
import { useInputSxProps } from '../MuiInput/useInputSxProps';
import { usePaperSxProps } from '../MuiPaper/usePaperSxProps';
import { useAutocompleteSxProps } from './useAutocompleteSxProps';
import { ReactComponent as ClearIcon } from 'shared/assets/remove.svg';

const MuiAutocompleteLazy = lazy(() => import('@mui/material/Autocomplete'));
const MuiInputLazy = lazy(() => import('@mui/material/OutlinedInput'));

type TLazyAutocompleteProps = AutocompleteProps<
    unknown,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
>;

export interface IMuiAutocompleteProps extends Omit<TLazyAutocompleteProps, 'renderInput' | 'ref'> {
    fallback?: SuspenseProps['fallback'];
    inputComponentProps?: OutlinedInputProps;
}

const MuiAutocomplete = forwardRef<HTMLDivElement, IMuiAutocompleteProps>(
    ({ fallback, componentsProps, inputComponentProps, ...restProps }, ref) => {
        const { inputSxProps } = useInputSxProps(restProps.size);
        const { autocompleteSxProps, autocompleteSxPropsPaper } = useAutocompleteSxProps(
            restProps.size
        );
        const { paperSxProps } = usePaperSxProps();

        return (
            <Suspense fallback={fallback ?? <MuiDefaultInputFallback size={restProps.size} />}>
                <MuiAutocompleteLazy
                    ref={ref}
                    clearIcon={<ClearIcon />}
                    {...restProps}
                    componentsProps={{
                        ...componentsProps,
                        paper: {
                            ...componentsProps?.paper,
                            sx: {
                                ...paperSxProps,
                                ...autocompleteSxPropsPaper,
                                ...componentsProps?.paper?.sx,
                            },
                        },
                    }}
                    renderInput={({ InputLabelProps, InputProps, ...restInputProps }) => (
                        <MuiInputLazy
                            {...restInputProps}
                            {...InputProps}
                            {...inputComponentProps}
                            className={cn(
                                'story3_mui_input',
                                'story3_mui_autocomplete',
                                InputProps.className,
                                inputComponentProps?.className
                            )}
                            sx={{
                                '&.story3_mui_input': inputSxProps,
                                '&.story3_mui_input.story3_mui_autocomplete': autocompleteSxProps,
                                ...inputComponentProps?.sx,
                            }}
                        />
                    )}
                />
            </Suspense>
        );
    }
);

MuiAutocomplete.displayName = 'MuiAutocomplete';

export { MuiAutocomplete };
