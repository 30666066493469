import { colord } from 'colord';
import type { IBaseColors, ThemeVariant } from 'themes/types';

export const base: IBaseColors = {
    content: colord({ h: 240, s: 5, l: 12 }),
    surface: colord({ h: 180, s: 3, l: 94 }),
    project: colord({ h: 2, s: 69, l: 60 }),
    phantom: colord({ h: 210, s: 53, l: 17 }),
    path: colord({ h: 105, s: 30, l: 41 }),
    yellow: colord({ h: 43, s: 73, l: 78 }),
    pink: colord({ h: 0, s: 100, l: 84 }),
    success: colord({ h: 105, s: 30, l: 41 }),
    warning: colord({ h: 19, s: 100, l: 40 }),
    alert: colord({ h: 337, s: 100, l: 40 }),
    neutral: colord({ h: 210, s: 53, l: 17 }),
};

const white = 'rgb(255,255,255)';

export const defaultLightScheme = (base: IBaseColors): ThemeVariant => ({
    colors: {
        core: {
            background: {
                primary: base.surface.toRgbString(),
                secondary: white,
                tertiary: base.path.alpha(0.2).toRgbString(),
                ghost: base.phantom.alpha(0.05).toRgbString(),
                tone: base.phantom.desaturate(0.08).alpha(0.5).toRgbString(),
                selection: base.phantom.alpha(0.1).toRgbString(),
                hover: base.phantom.alpha(0.08).toRgbString(),
                brand: base.project.toRgbString(),
                yellow: base.yellow.toRgbString(),
                pink: base.pink.toRgbString(),
                success: base.success.rotate(-21).saturate(0.1).lighten(0.49).toRgbString(),
                warning: base.warning.rotate(18).lighten(0.55).toRgbString(),
                alert: base.alert.rotate(18).lighten(0.55).toRgbString(),
                neutral: base.neutral.rotate(-21).lighten(0.65).toRgbString(),
            },
            divider: {
                divider: base.phantom.alpha(0.15).toRgbString(),
            },
            text: {
                primary: base.content.toRgbString(),
                secondary: base.content.alpha(0.7).toRgbString(),
                tertiary: base.content.alpha(0.5).toRgbString(),
                brand: base.project.toRgbString(),
                success: base.success.toRgbString(),
                warning: base.warning.toRgbString(),
                alert: base.alert.toRgbString(),
                neutral: base.neutral.toRgbString(),
            },
            link: {
                link: base.project.toRgbString(),
                linkHover: base.project.alpha(0.7).toRgbString(),
                linkMinor: base.project.desaturate(0.01).lighten(0.35).toRgbString(),
                linkMinorHover: base.project
                    .rotate(-1)
                    .desaturate(0.04)
                    .lighten(0.21)
                    .alpha(0.7)
                    .toRgbString(),
            },
        },
        control: {
            primary: {
                bg: base.project.toRgbString(),
                bgHover: base.project.rotate(3).darken(0.3).toRgbString(),
                bgPress: base.project.rotate(-3).lighten(0.1).toRgbString(),
                border: 'transparent',
                borderHover: 'transparent',
                borderPress: 'transparent',
                bgDisabled: base.surface.rotate(9).toRgbString(),
            },
            secondary: {
                bg: base.path.toRgbString(),
                bgHover: base.path.rotate(3).darken(0.1).toRgbString(),
                bgPress: base.path.rotate(-3).lighten(0.1).toRgbString(),
                border: base.path.toRgbString(),
                borderHover: 'transparent',
                borderPress: 'transparent',
                bgDisabled: base.surface.rotate(9).toRgbString(),
            },
            tertiary: {
                bg: base.path.alpha(0.2).toRgbString(),
                bgHover: base.path.alpha(0.3).toRgbString(),
                bgPress: base.path.alpha(0.2).toRgbString(),
                border: base.path.toRgbString(),
                borderHover: base.path.toRgbString(),
                borderPress: base.path.toRgbString(),
                bgDisabled: base.surface.rotate(9).toRgbString(),
            },
            default: {
                bg: base.content.toRgbString(),
                bgHover: base.phantom.toRgbString(),
                bgPress: base.content.toRgbString(),
                border: 'transparent',
                borderHover: 'transparent',
                borderPress: 'transparent',
                bgDisabled: base.surface.rotate(9).toRgbString(),
            },
            input: {
                bg: base.phantom.alpha(0.07).toRgbString(),
                bgHover: base.phantom.alpha(0.1).toRgbString(),
                bgDisabled: base.phantom.alpha(0.2).toRgbString(),
                border: 'transparent',
                borderHover: base.phantom.alpha(0.15).toRgbString(),
                borderDisabled: 'transparent',
                text: base.content.toRgbString(),
                placeholder: base.content.alpha(0.55).toRgbString(),
                textDisabled: base.content.alpha(0.3).toRgbString(),
            },
            focus: {
                bg: white,
                border: base.path.toRgbString(),
            },
            alert: {
                bg: 'transparent',
                bgHover: base.alert.rotate(9).lighten(0.3).alpha(0.2).toRgbString(),
                bgPress: base.alert.rotate(9).lighten(0.3).alpha(0.5).toRgbString(),
                border: base.alert.toRgbString(),
                borderHover: base.alert.rotate(-3).darken(0.1).toRgbString(),
                borderPress: base.alert.rotate(-3).darken(0.1).toRgbString(),
            },
            check: {
                bg: base.project.toRgbString(),
                bgHover: base.project.rotate(3).darken(0.1).toRgbString(),
                bgDisabled: base.phantom.alpha(0.2).toRgbString(),
            },
            faint: {
                bg: base.phantom.alpha(0.15).toRgbString(),
                bgHover: base.phantom.alpha(0.2).toRgbString(),
                border: 'transparent',
                borderHover: base.phantom.alpha(0.15).toRgbString(),
            },
            toggle: {
                bg: base.phantom.alpha(0.15).toRgbString(),
                bgHover: base.phantom.alpha(0.2).toRgbString(),
            },
        },
    },
});

export const light = defaultLightScheme(base);
