import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

import styles from './ButtonBlock.module.css';

interface IButtonBlockProps {
    className?: string;
}

export const ButtonBlock: FC<PropsWithChildren<IButtonBlockProps>> = ({ children, className }) => (
    <div className={cn(styles['container'], className)}>{children}</div>
);
