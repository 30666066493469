import { IApp, IConsent, IError, initialize, IState, onConsent } from '@xsolla/gdpr/dist/bundle';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { GDPRContext, IGDPRContextContext } from './GDPRContext';
import { customizeGDPRBannerOnMutate, customizeGDPRBannerOnShow } from './customizeGDPRBanner';
import { useGDPRLocalization } from './useGDPRLocalization';
import { gtmInit } from 'analytics/gtm/gtm';
import { ERoutes } from 'app/providers/routing';
import { GDPRBanner } from 'components/molecules/GDPRBanner';
import { usePartnerRouterContext } from 'contexts/PartnerRouter/usePartnerRouterContext';
import { useThemeContext } from 'contexts/Theme/useThemeContext';
import { useUserQuery } from 'hooks/queries/user';
import { getIsSearchBotAgent } from 'utils/getIsSearchBotAgent';

interface IGDPRContextProviderProps extends PropsWithChildren {
    isEu: boolean;
    initialUrl: string;
}

const PRODUCT_NAME = 'story3';

export const GDPRContextProvider: FC<IGDPRContextProviderProps> = ({
    isEu,
    initialUrl,
    children,
}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const bannerRef = useRef<HTMLDivElement>(null);
    const appRef = useRef<IApp>();
    const localization = useGDPRLocalization();
    const { getRoute } = usePartnerRouterContext();
    const { themeId } = useThemeContext();

    const bannerEnabled = !getIsSearchBotAgent();

    const { data: user } = useUserQuery({ enabled: bannerEnabled });
    const userId = user?.isAuthenticated ? user.id : undefined;

    const show = useCallback(() => {
        if (bannerRef.current && appRef.current) {
            appRef.current.show();
            customizeGDPRBannerOnShow(bannerRef.current, getRoute);
        }
    }, [getRoute]);

    const onInitialized = useCallback(
        (error?: IError, svelteApp?: IApp) => {
            if (!error && svelteApp && bannerRef.current) {
                setIsLoaded(true);
                appRef.current = svelteApp;
                customizeGDPRBannerOnShow(bannerRef.current, getRoute);
                customizeGDPRBannerOnMutate(bannerRef.current, getRoute);
            }
        },
        [getRoute]
    );

    const handleConsent = useCallback((consent: IConsent, prev: IConsent) => {
        if (!prev.cookie || !consent.cookie || !consent.gdpr) {
            return;
        }

        const analyticsCookiesDisabled =
            prev.cookie.analytics_consent_state && !consent.cookie.analytics_consent_state;
        const functionalCookiesDisabled =
            prev.cookie.functional_consent_state && !consent.cookie.functional_consent_state;
        const targetingCookiesDisabled =
            prev.cookie.targeting_consent_state && !consent.cookie.targeting_consent_state;

        if (analyticsCookiesDisabled || functionalCookiesDisabled || targetingCookiesDisabled) {
            window.location.reload();
            return;
        }

        if (consent.cookie.analytics_consent_state) {
            gtmInit(initialUrl);
        }
    }, []);

    const init = () => {
        if (!bannerRef.current) {
            return;
        }

        bannerRef.current.innerHTML = '';

        const initializeData: IState = {
            state: {
                productName: PRODUCT_NAME,
                userIdentityType: userId ? 'story3_user_id' : undefined,
                userId: userId ? String(userId) : undefined,
                customCookieUrl: getRoute(ERoutes.CookieStatement),
            },
            ui: { target: bannerRef.current },
            localization,
            settings: { useDataProcessing: isEu },
        };

        onConsent(handleConsent);
        initialize(initializeData, onInitialized);
    };

    const value = useMemo((): IGDPRContextContext => ({ show }), [show]);

    useEffect(() => {
        if (bannerEnabled && themeId) {
            init();
        }
    }, [userId, localization.locale, themeId]);

    return (
        <GDPRContext.Provider value={value}>
            {children}
            {bannerEnabled && <GDPRBanner isLoaded={isLoaded} bannerRef={bannerRef} />}
        </GDPRContext.Provider>
    );
};
