export enum ELocale {
    German = 'de-DE',
    English = 'en-US',
    Spanish = 'es-ES',
    French = 'fr-FR',
    Japanese = 'ja-JP',
    Korean = 'ko-KR',
    Portuguese = 'pt-BR',
    Russian = 'ru-RU',
    Chinese = 'zh-CN',
}
