import cn from 'classnames';
import type { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import { forwardRef } from 'react';

import styles from './Button.module.css';
import { ButtonIcon } from './ButtonIcon';
import { Spinner } from 'components/atoms/Spinner';
import { Text, TextColor, TextTypography } from 'components/atoms/Text/Text';
import { MuiTooltip } from 'shared/ui';

export type ButtonVariant =
    | 'default'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'text'
    | 'textBg'
    | 'flat';

export type ControlSize = 'xs' | 'sm' | 'lg';

export type IButtonProps = PropsWithChildren<{
    className?: string;
    onClick?: MouseEventHandler;
    variant?: ButtonVariant;
    size?: ControlSize;
    stretched?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    icon?: ReactNode;
    iconPosition?: IIconPosition;
    tooltip?: string | null;
    textTypo?: TextTypography;
    textColor?: TextColor;
    nowrap?: boolean;
    noPadding?: boolean;
}>;

type IIconPosition = 'before' | 'after';

const DEFAULT_SIZE: ControlSize = 'lg';

const Button: FC<IButtonProps> = forwardRef<HTMLButtonElement, IButtonProps>(
    (
        {
            children,
            className,
            onClick,
            variant = 'default',
            size = DEFAULT_SIZE,
            stretched = false,
            isLoading,
            isDisabled,
            icon,
            iconPosition = 'after',
            tooltip,
            textColor,
            textTypo,
            nowrap,
            noPadding = false,
            ...props
        },
        ref
    ) => {
        const textTypoBySize: { [key: string]: TextTypography } = {
            xs: 'minor',
            sm: 'label',
            lg: 'label',
        };

        return (
            <MuiTooltip title={tooltip}>
                <button
                    type="button"
                    className={cn(
                        className,
                        styles['button'],
                        styles[`variant_${variant}`],
                        styles[`size_${size}`],
                        stretched && styles['stretched'],
                        isLoading && styles['loading'],
                        isDisabled && styles['disabled'],
                        noPadding && styles['no_padding']
                    )}
                    onClick={isDisabled || isLoading ? () => {} : onClick}
                    ref={ref}
                    {...props}
                >
                    <Text typo={textTypo || textTypoBySize[size]} color={textColor || 'inherit'}>
                        <span
                            className={cn(
                                styles['content'],
                                nowrap && styles['nowrap'],
                                icon && styles['content_with_icon'],
                                isLoading && styles['loading']
                            )}
                        >
                            {iconPosition === 'before' && (
                                <ButtonIcon icon={icon} isIconWithoutHeight={!!children} />
                            )}
                            {children}
                            {iconPosition === 'after' && (
                                <ButtonIcon icon={icon} isIconWithoutHeight={!!children} />
                            )}
                        </span>
                        {isLoading && (
                            <span
                                className={cn(
                                    styles['spinner_container'],
                                    isLoading && styles['loading'],
                                    isDisabled && styles['disabled']
                                )}
                            >
                                <Spinner className={styles['spinner']} size={20} />
                            </span>
                        )}
                    </Text>
                </button>
            </MuiTooltip>
        );
    }
);

Button.displayName = 'Button';

export { Button };
