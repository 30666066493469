import { capitalize } from '../../../utils/capitalize';
import { EditorStatuses } from '../../molecules/EditorStatus';
import { PillStatus } from '../../molecules/StatusPill/StatusPill';

export enum Status {
    DRAFT = 'draft',
    ANALYZING = 'analyzing',
    DECLINED = 'declined',
    PUBLISHED = 'published',
}

enum StatusText {
    DRAFT = 'story_editor.status.draft',
    ANALYZING = 'story_editor.status.analyzing',
    DECLINED = 'story_editor.status.declined',
    PUBLISHED = 'story_editor.status.published',
    SAVING = 'story_editor.status.saving',
    UNSAVED = 'story_editor.status.unsaved',
}

enum SecondaryButtonText {
    SAVE = 'story_editor.buttons.save',
    UNPUBLISH = 'story_editor.buttons.unpublish',
}

export type EditorStatusState = {
    statusPillColor: PillStatus;
    statusPillText: string;
    secondaryButtonText?: string;
    buttonHandlers?: {
        main: (id: string) => Promise<void> | void;
        secondary: (id: string) => Promise<void> | void;
    };
};

type GetEditorStatusStateOptions = {
    status: Status | EditorStatuses | null | undefined;
    onPublish?: (id: string) => Promise<void> | void;
    onUnpublish?: (id: string) => Promise<void> | void;
    onSave?: (id: string) => Promise<void> | void;
    isChangeDraftColor?: boolean;
};

export const StatusService = {
    getEditorStatusState({
        status,
        onPublish = () => {},
        onUnpublish = () => {},
        onSave = () => {},
        isChangeDraftColor = false,
    }: GetEditorStatusStateOptions): EditorStatusState {
        const draftStatus = isChangeDraftColor ? PillStatus.NEUTRAL_CHANGED : PillStatus.NEUTRAL;
        switch (status) {
            case null:
            case undefined: {
                return {
                    statusPillColor: draftStatus,
                    statusPillText: StatusText.DRAFT,
                    secondaryButtonText: SecondaryButtonText.SAVE,
                    buttonHandlers: {
                        main: onPublish,
                        secondary: onSave,
                    },
                };
            }
            case Status.ANALYZING: {
                return {
                    statusPillColor: PillStatus.WARNING,
                    statusPillText: StatusText.ANALYZING,
                    secondaryButtonText: SecondaryButtonText.UNPUBLISH,
                    buttonHandlers: {
                        main: onPublish,
                        secondary: onUnpublish,
                    },
                };
            }
            case Status.DECLINED: {
                return {
                    statusPillColor: PillStatus.ALERT,
                    statusPillText: StatusText.DECLINED,
                    secondaryButtonText: SecondaryButtonText.SAVE,
                    buttonHandlers: {
                        main: onPublish,
                        secondary: onSave,
                    },
                };
            }
            case Status.PUBLISHED: {
                return {
                    statusPillColor: PillStatus.SUCCESS,
                    statusPillText: StatusText.PUBLISHED,
                    secondaryButtonText: SecondaryButtonText.UNPUBLISH,
                    buttonHandlers: {
                        main: onPublish,
                        secondary: onUnpublish,
                    },
                };
            }
            case EditorStatuses.SAVING: {
                return {
                    statusPillColor: draftStatus,
                    statusPillText: StatusText.SAVING,
                };
            }
            case EditorStatuses.UNSAVED: {
                return {
                    statusPillColor: PillStatus.ALERT,
                    statusPillText: StatusText.UNSAVED,
                };
            }
            case Status.DRAFT: {
                return {
                    statusPillColor: draftStatus,
                    statusPillText: StatusText.DRAFT,
                    secondaryButtonText: SecondaryButtonText.SAVE,
                    buttonHandlers: {
                        main: onPublish,
                        secondary: onSave,
                    },
                };
            }
        }
        return {
            statusPillColor: draftStatus,
            statusPillText: capitalize(status),
            secondaryButtonText: SecondaryButtonText.SAVE,
            buttonHandlers: {
                main: onPublish,
                secondary: onSave,
            },
        };
    },
};
