const BOT_REGEX_STRING =
    '^facebookexternalhit/[0-9]|vkShare|linkedinbot|embedly|slackbot|W3C_Validator|WhatsApp|Viber|Discordbot|Tumblr|Mail.RU_Bot|Google-PageRenderer|bingbot/[0-9]|yandex|baiduspider|duckduckbot|slurp|YandexBot|YandexImages|Facebot|GoogleOther|Google-Extended|FeedFetcher-Google|Google-Read-Aloud|Google-InspectionTool/[0-9]|Googlebot|Twitterbot|Pinterest|Google.*snippet$';

const botRegex = new RegExp(BOT_REGEX_STRING);
let isSearchBotAgent: boolean;
export const getIsSearchBotAgent = () => {
    if (isSearchBotAgent === undefined) {
        isSearchBotAgent = !!window && botRegex.test(window.navigator.userAgent);
    }
    return isSearchBotAgent;
};
