import * as Sentry from '@sentry/react';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { IUiLanguageContext, UiLanguageContext } from './UiLanguageContext';
import { getIsItalicAllowed } from './getIsItalicAllowed';
import { useSaveUiLanguage } from './useSaveUiLanguage';
import { FullscreenLoader } from 'components/molecules/FullscreenLoader';
import { useLocalizationContext } from 'contexts/LocalizationContext/useLocalizationContext';
import { useUserQuery } from 'hooks/queries/user';
import { ELanguage } from 'types/languages';
import { TTranslations } from 'types/translations';
import { getCoreTranslations } from 'utils/translations/getCoreTranslations';

interface IUiLanguageContextProviderProps extends PropsWithChildren {
    language: ELanguage;
    translations: TTranslations;
}

export const UiLanguageContextProvider: FC<IUiLanguageContextProviderProps> = ({
    language: initialLanguage,
    translations: initialTranslations,
    children,
}) => {
    const [inited, setInited] = useState(false);
    const { language, setLanguage, setTranslations } = useLocalizationContext();

    const { data: user } = useUserQuery();
    const isAuthenticated = !!user?.isAuthenticated;

    const saveUiLanguage = useSaveUiLanguage();

    const setUiLanguage = useCallback(
        async (languageToSet: ELanguage) => {
            if (languageToSet && languageToSet !== language) {
                try {
                    const translationsToSet = await getCoreTranslations(languageToSet);
                    await saveUiLanguage(languageToSet);
                    setLanguage(languageToSet);
                    setTranslations(translationsToSet);
                } catch (e) {
                    Sentry.captureException(e);
                    throw e;
                }
            }
        },
        [language]
    );

    const value = useMemo<IUiLanguageContext>(
        () => ({
            uiLanguage: language,
            setUiLanguage,
            isItalicAllowed: getIsItalicAllowed(language),
        }),
        [language, setUiLanguage, getIsItalicAllowed]
    );

    useEffect(() => {
        setLanguage(initialLanguage);
        setTranslations(initialTranslations);
        setInited(true);
    }, []);

    useEffect(() => {
        if (inited && isAuthenticated && user.uiLanguage) {
            setUiLanguage(user.uiLanguage).catch();
        }
    }, [isAuthenticated]);

    return (
        <UiLanguageContext.Provider value={value}>
            {inited ? children : <FullscreenLoader />}
        </UiLanguageContext.Provider>
    );
};
