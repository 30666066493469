import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { INotification, NotificationsContext, PushNotificationArgs } from './NotificationsContext';
import {
    Notifications,
    NOTIFICATION_ANIMATION_DURATION,
} from 'components/organisms/Notifications/Notifications';
import { useIsTablet } from 'hooks/layout';

export const PUSH_DELAY = 400;
export const DEFAULT_SHOW_TIME = 2000;

const uid = () =>
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export const NotificationsContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const isTablet = useIsTablet();
    const [items, setItems] = useState<INotification[]>([]);

    const pop = useCallback((id: string) => {
        setItems((items) => items.filter((item) => item.id !== id));
    }, []);

    const push = useCallback(
        ({ element, showTime: showTimeArg, showCloseButton }: PushNotificationArgs) => {
            const id = uid();

            const showTime =
                !showCloseButton && showTimeArg === undefined ? DEFAULT_SHOW_TIME : showTimeArg;

            const item = { id, element, showTime, showCloseButton };

            setTimeout(() => {
                setItems((items) => [...items, item]);
            }, PUSH_DELAY);

            if (showTime) {
                setTimeout(() => pop(id), showTime + PUSH_DELAY + NOTIFICATION_ANIMATION_DURATION);
            }

            return id;
        },
        [pop, isTablet]
    );

    const value = useMemo(
        () => ({
            items,
            push,
            pop,
        }),
        [items, push, pop]
    );

    return (
        <NotificationsContext.Provider value={value}>
            {children}
            <Notifications />
        </NotificationsContext.Provider>
    );
};
