import cn from 'classnames';
import { FC } from 'react';
import { createPortal } from 'react-dom';

import styles from './FullscreenLoader.module.css';
import { Spinner } from 'components/atoms/Spinner';
import { ROOT_DOM_ID } from 'constants/domIds';

export interface IFullscreenLoaderProps {
    className?: string;
}

export const FullscreenLoader: FC<IFullscreenLoaderProps> = ({ className }) => {
    const content = (
        <div className={cn(styles['spinner'], className)}>
            <Spinner size={48} />
        </div>
    );

    return createPortal(content, document.getElementById(ROOT_DOM_ID)!);
};
