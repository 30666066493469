import { useQuery } from '@tanstack/react-query';

import { xsollaStoreService } from 'api/xsollaStore';

export const CURRENCY_PACKAGE_LIST_KEY = 'currencyPackageList';

export const useCurrencyPackageList = () =>
    useQuery({
        queryKey: [CURRENCY_PACKAGE_LIST_KEY],
        queryFn: xsollaStoreService.getCurrencyPackageList,
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
