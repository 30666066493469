const document = global.document || {};

export const cookieService = (key: string) => {
    const get = (): string | null =>
        document?.cookie
            ?.split('; ')
            .find((v) => v.startsWith(`${key}=`))
            ?.split('=')[1] ?? null;

    const _set = <T>(name: string, value: T, expires?: number) => {
        const date = new Date();

        if (expires) {
            date.setDate(date.getDate() + expires);
        }

        const cookieValue = `${name}=${encodeURIComponent(value as string | number | boolean)}${
            expires ? '; expires=' + date.toUTCString() : ''
        }`;

        document.cookie = cookieValue;
        return cookieValue;
    };

    const set = <T>(value: T, expires?: number) => _set(key, value, expires);
    const clear = () => _set(key, '', -1);
    const value = get();

    return {
        get,
        set,
        clear,
        value,
    };
};
