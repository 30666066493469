import { ENV_MODE } from 'config';
import { EnvMode } from 'types/envMode';

type UUIDType = `${string}-${string}-${string}-${string}-${string}`;
const UUID =
    'The random UUID function is needed in dev mode to fix a Metaframe login error when you use the insecure http protocol and a custom domain name instead of "localhost"';

export const mockRandomUuidFuncInDevMode = () => {
    if (
        ENV_MODE === EnvMode.Dev &&
        window.location.origin.startsWith('http://') &&
        !window.crypto.randomUUID
    )
        window.crypto.randomUUID = () => UUID as UUIDType;
};
