import { MenuItemProps } from '@mui/material';
import cn from 'classnames';
import { FC, lazy, Suspense, SuspenseProps } from 'react';

import { useThemeContext } from 'contexts/Theme/useThemeContext';

interface IMuiMenuItemProps extends MenuItemProps {
    fallback?: SuspenseProps['fallback'];
}

const MuiMenuItemLazy = lazy(() => import('@mui/material/MenuItem'));

export const MuiMenuItem: FC<IMuiMenuItemProps> = ({
    fallback,
    style,
    sx,
    className,
    children,
    ...restProps
}) => {
    const { cssTheme } = useThemeContext();

    const containerStyle = {
        padding: '12px 16px',
        minHeight: '45px',
        ...style,
    };

    return (
        <Suspense fallback={fallback ?? <li style={containerStyle}>{children}</li>}>
            <MuiMenuItemLazy
                {...restProps}
                style={containerStyle}
                className={cn('story3_mui_menuItem', className)}
                sx={{
                    '&.story3_mui_menuItem': {
                        ':focus, &.Mui-selected, &.MuiMenuItem-root.Mui-focused, &.Mui-selected.Mui-focusVisible':
                            {
                                backgroundColor: cssTheme.colors.core.background.selection,
                            },
                        ':hover, &.Mui-selected:hover, &.MuiMenuItem-root.Mui-focused:hover': {
                            backgroundColor: cssTheme.colors.core.background.hover,
                        },
                    },
                    ...sx,
                }}
            >
                {children}
            </MuiMenuItemLazy>
        </Suspense>
    );
};
