import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { accessTokenCService } from 'api/accessToken/accessTokenCService';
import { getUser } from 'api/user';
import { queryClient } from 'app/providers/ReactQueryProvider';
import { ANONYM_USER } from 'constants/anonymousUser';
import { HttpStatusCode } from 'constants/httpStatusCode';
import { TUser } from 'types/user';

interface IUseUserQueryParams {
    enabled?: boolean;
}

export const USER_QUERY_KEY = ['user'];

// Used during application initialization
export const loadUserToCache = async (): Promise<TUser | undefined> => {
    try {
        return await queryClient.fetchQuery<TUser, AxiosError>(USER_QUERY_KEY, {
            queryFn: getUser,
        });
    } catch (error) {
        if ((error as AxiosError)?.response?.status === HttpStatusCode.Unauthorized) {
            accessTokenCService.clear();
        }
        queryClient.setQueryData<TUser>(USER_QUERY_KEY, ANONYM_USER);
    }
};

// Used in components
export const useUserQuery = ({ enabled }: IUseUserQueryParams = {}) =>
    useQuery<TUser, AxiosError>(USER_QUERY_KEY, {
        enabled,
        queryFn: getUser,
        staleTime: 10 * 60 * 1000, // 10 min
        onError: (error) => {
            if (error?.response?.status === HttpStatusCode.Unauthorized) {
                accessTokenCService.clear();
            }
            queryClient.setQueryData<TUser>(USER_QUERY_KEY, ANONYM_USER);
        },
    });
