import { useNavigate } from 'react-router-dom';

import { usePartnerRouterContext } from 'contexts/PartnerRouter/usePartnerRouterContext';

type RelativeRoutingType = 'route' | 'path';
interface INavigateOptions {
    replace?: boolean;
    state?: any;
    preventScrollReset?: boolean;
    relative?: RelativeRoutingType;
}

interface INavigateFunction {
    (to: string, options?: INavigateOptions): void;
    (delta: number): void;
}

export const useInAppNavigate = (): INavigateFunction => {
    // eslint-disable-next-line no-restricted-syntax
    const navigate = useNavigate();
    const { prefix } = usePartnerRouterContext();
    return (to: string | number, options?: INavigateOptions) => {
        if (typeof to === 'number') {
            navigate(to);
        } else {
            navigate(prefix + to, options);
        }
    };
};
