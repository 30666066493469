import { getBundledTranslations } from './getBundledTranslations';
import { ELanguage } from 'types/languages';
import { TTranslations } from 'types/translations';

const appMessages = {
    nonTranslatable: () => import('translations/core/non-translatable.json'),
    untranslated: () => import('translations/core/untranslated.json'),
    [ELanguage.German]: () => import('translations/core/de-DE.json'),
    [ELanguage.English]: () => import('translations/core/en-US.json'),
    [ELanguage.Spanish]: () => import('translations/core/es-ES.json'),
    [ELanguage.French]: () => import('translations/core/fr-FR.json'),
    [ELanguage.Japanese]: () => import('translations/core/ja-JP.json'),
    [ELanguage.Korean]: () => import('translations/core/ko-KR.json'),
    [ELanguage.Portuguese]: () => import('translations/core/pt-BR.json'),
    [ELanguage.Russian]: () => import('translations/core/ru-RU.json'),
    [ELanguage.Chinese]: () => import('translations/core/zh-CN.json'),
};

export const getCoreTranslations = async (language: ELanguage): Promise<TTranslations> => {
    const [nonTranslatable, untranslated, localeMessages] = await Promise.all([
        appMessages.nonTranslatable(),
        appMessages.untranslated(),
        appMessages[language](),
    ]);

    return {
        ...getBundledTranslations(language),
        ...nonTranslatable.default,
        ...untranslated.default,
        ...localeMessages.default,
    };
};
