import { Box } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import classes from './TooltipContent.module.css';
import { ReactComponent as CrossSVG } from 'assets/cross-alt.svg';
import { Text } from 'components/atoms/Text';

interface ITooltipContentProps {
    titleId: string;
    statusTextId?: string;
}

export const TooltipContent: FC<ITooltipContentProps> = ({ titleId, statusTextId }) => (
    <Box className={classes.content}>
        <Box className={classes.titleContainer}>
            <Text typo="body-md-accent" className={classes.title}>
                <FormattedMessage id={titleId} />
            </Text>
            <CrossSVG width={20} height={12} />
        </Box>

        {statusTextId && (
            <Text typo="minor" className={classes.statusText}>
                <FormattedMessage id={statusTextId} />
            </Text>
        )}
    </Box>
);
