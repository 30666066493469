export const ENV_MODE = process.env.REACT_APP_ENV_MODE ?? '';
export const API_V1_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL ?? '';
export const API_V2_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL ?? '';
export const CONFIGCAT_SDK_KEY = process.env.REACT_APP_CONFIGCAT_SDK_KEY ?? '';
export const XSOLLA_LOGIN_PROJECT_ID = process.env.REACT_APP_XSOLLA_LOGIN_PROJECT_ID ?? '';
export const XSOLLA_PAYOUTS_BASE_URL = process.env.REACT_APP_XSOLLA_PAYOUTS_BASE_URL ?? '';
export const XSOLLA_PAYSTATION_URL = process.env.REACT_APP_XSOLLA_PAYSTATION_URL ?? '';
export const XSOLLA_PROJECT_ID = process.env.REACT_APP_XSOLLA_PROJECT_ID ?? '';
export const REACT_QUERY_DEFAULT_RETRY = process.env.NODE_ENV === 'development' ? 0 : 3;
export const XSOLLA_PAYSTATION_SANDBOX_MODE =
    process.env.REACT_APP_XSOLLA_PAYSTATION_SANDBOX_MODE === 'true';
export const XSOLLA_METRIKA_URL = process.env.REACT_APP_XSOLLA_METRIKA_URL ?? '';
export const XSOLLA_AB_GROUP_URL = process.env.REACT_APP_XSOLLA_AB_GROUP_URL ?? '';
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? '';
