import { createContext } from 'react';

import { VerifyExtends } from 'utils/verifyExtends';

export enum PARTNERS_URL {
    DEFAULT = '',
    FORBES = '/forbes',
    NEW_YORK_TIMES = '/nytimes',
    EIGHTY_LEVEL = '/80lv',
    EIGHTY_LEVEL_DEV = '/80lv-dev',
}

export type PARTNERS_DATA<T> = {
    [key in keyof typeof PARTNERS_URL]: T;
};

export enum PROJECTS {
    DEFAULT = '',
    FORBES = '',
    NEW_YORK_TIMES = '',
    EIGHTY_LEVEL = '',
    EIGHTY_LEVEL_DEV = 'test',
}

/**
 * TODO refactor and remove
 * NOTE: this is a type for validating PROJECTS enum. It should extend PARTNERS_DATA. Do not remove!
 */
type _VerifyProjects = VerifyExtends<PARTNERS_DATA<string>, typeof PROJECTS>;

export type IPartnerRouterContext = {
    prefix: PARTNERS_URL;
    project: PROJECTS;
    setPrefix: (prefix: PARTNERS_URL) => void;
    setProject: (project: PROJECTS) => void;
    getRoute: (route: string) => string;
};

export const isPartnerUrl = (partnerUrl: PARTNERS_URL): boolean => {
    const template = `${partnerUrl}/|${partnerUrl}\$`;
    return !!window.location.pathname.match(new RegExp(template));
};

export const executeForTheme = <T>(
    config: PARTNERS_DATA<{ match?: boolean; callback: () => T }>
): T => {
    const forExecute = Object.entries(config).find(([, value]) => !!value.match);
    if (forExecute) {
        return forExecute[1].callback();
    }
    return config.DEFAULT.callback();
};

export const isBalanceMocked =
    isPartnerUrl(PARTNERS_URL.FORBES) ||
    isPartnerUrl(PARTNERS_URL.NEW_YORK_TIMES) ||
    isPartnerUrl(PARTNERS_URL.EIGHTY_LEVEL);

export const PartnerRouterContext = createContext<IPartnerRouterContext>({
    prefix: PARTNERS_URL.DEFAULT,
    project: PROJECTS.DEFAULT,
    setPrefix: () => {},
    setProject: () => {},
    getRoute: () => '',
});
