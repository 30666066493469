import { useMutation } from '@tanstack/react-query';

import { createUser } from 'api/user';
import { useSetUserQueryData } from 'hooks/queries/user/setQueryData';

export const useUserCreateMutation = () => {
    const setUserQueryData = useSetUserQueryData();

    return useMutation({
        mutationFn: createUser,
        onSuccess: setUserQueryData,
    });
};
