import { ERoutes } from 'app/providers/routing';
import { IPartnerRouterContext } from 'contexts/PartnerRouter/PartnerRouterContext';

const markAsCustomized = (element: Element) => element.setAttribute('data-customized', 'true');
const getIsCustomized = (element: Element) => element.getAttribute('data-customized') === 'true';

const customizeGiveConsent = (content: Element) => {
    const giveConsentContent = content?.querySelector(
        '[data-action="give-consent"] .gdpr-checkbox__content'
    );
    if (giveConsentContent && !getIsCustomized(giveConsentContent)) {
        const [message, description] = giveConsentContent.innerHTML.split('{desc}');
        giveConsentContent.innerHTML = `${message}<span class="gdpr-give-consent--description">${description}</span>`;
        markAsCustomized(giveConsentContent);
    }
};

const customizeFooterLinks = (content: Element, getRoute: IPartnerRouterContext['getRoute']) => {
    const footerLinksContainer = content?.getElementsByClassName(
        'gdpr-settings-footer__links-container'
    )[0];

    if (!footerLinksContainer || getIsCustomized(footerLinksContainer)) {
        return;
    }

    const privacyPolicyLink = footerLinksContainer.children[0] as HTMLAnchorElement;
    if (privacyPolicyLink) {
        const [cookieText, privacyText] = privacyPolicyLink.innerHTML.split('{divider}');
        const cookiePolicyLink = privacyPolicyLink.cloneNode(true) as HTMLAnchorElement;

        privacyPolicyLink.href = getRoute(ERoutes.PrivacyPolicy);
        privacyPolicyLink.innerHTML = privacyText;
        cookiePolicyLink.href = getRoute(ERoutes.CookieStatement);
        cookiePolicyLink.innerHTML = cookieText;

        const divider = document.createElement('span');
        divider.innerHTML = '|';
        divider.style.margin = '0 8px';

        footerLinksContainer.appendChild(cookiePolicyLink);
        footerLinksContainer.appendChild(divider);
        footerLinksContainer.appendChild(privacyPolicyLink);

        markAsCustomized(footerLinksContainer);
    }
};

const customizeGDPRBanner = (content: Element, getRoute: IPartnerRouterContext['getRoute']) => {
    customizeGiveConsent(content);
    customizeFooterLinks(content, getRoute);
};

export const customizeGDPRBannerOnMutate = (
    formElement: Element | null,
    getRoute: IPartnerRouterContext['getRoute']
) => {
    const content = formElement?.children[0];
    if (!content) {
        return;
    }

    const observer = new MutationObserver(() => {
        if (content) {
            customizeGDPRBanner(content, getRoute);
        }
        observer.disconnect();
    });

    observer.observe(content, { childList: true });
};

export const customizeGDPRBannerOnShow = (
    formElement: Element | null,
    getRoute: IPartnerRouterContext['getRoute']
) => {
    setTimeout(() => {
        const content = formElement?.children[0];
        if (content) {
            customizeGDPRBanner(content, getRoute);
        }
    }, 0);
};
