import { TMuiSxProps } from '../types';
import { useThemeContext } from 'contexts/Theme/useThemeContext';

export const useSelectSxProps = () => {
    const { cssTheme } = useThemeContext();

    const selectSxProps: TMuiSxProps = {
        '.MuiSelect-select.MuiSelect-outlined': {
            paddingRight: '64px',
            minHeight: '24px',
            height: '24px',
        },
        '.MuiSelect-icon': {
            color: cssTheme.colors.core.text.primary,
            width: '24px',
            height: '24px',
            right: '16px',
            top: '50%',
            transform: 'translateY(-50%) rotate(90deg)',
            '&.Mui-disabled': {
                color: cssTheme.colors.core.text.tertiary,
            },
        },
    };

    const selectSxPropsOpen: TMuiSxProps = {
        backgroundColor: cssTheme.colors.control.focus.bg,
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: cssTheme.colors.control.focus.border,
        },
        '.MuiSelect-iconOpen': {
            transform: 'translateY(-50%) rotate(-90deg)',
        },
    };

    return { selectSxProps, selectSxPropsOpen };
};
