// TODO Add unit tests for makeVarsArrayFromTheme and makeVarsArrayFromConfig
import { ICssTheme } from './types';
import { BASE_UNITS } from './types/baseConfigUnits';
import { IConfig } from './types/config';

type ThemePart = {
    [key: string]: ThemePart;
};

type ConfigPart = {
    [key: string]: ConfigPart | string | number | boolean | {};
};

const isPartValue = (value: unknown): value is string | number =>
    !value || typeof value !== 'object';
const isPartObject = <PartType>(value: unknown): value is PartType =>
    !!value && typeof value === 'object';

/**
 *
 * @param themePart
 * @param array
 * @param variablePart
 */
export const makeVarsArrayFromTheme = (
    themePart: ICssTheme | ThemePart,
    array: string[] = [],
    variablePart: string = ''
): void => {
    if (isPartValue(themePart)) {
        array.push(`${variablePart}: ${themePart};`);
    } else if (isPartObject<ThemePart>(themePart) && Object.keys(themePart).length) {
        Object.keys(themePart).map((key) =>
            makeVarsArrayFromTheme(themePart[key], array, `${variablePart}-${key}`)
        );
    }
};

const makeVarsArrayFromStyleConfig = (
    configPart: ConfigPart | string | number | boolean,
    array: string[] = [],
    variablePart: string = '',
    unitOfMeasurement?: BASE_UNITS
): void => {
    if (isPartValue(configPart)) {
        array.push(`${variablePart}: ${configPart}${unitOfMeasurement || ''};`);
    } else if (isPartObject<ConfigPart>(configPart) && Object.keys(configPart).length) {
        Object.keys(configPart).forEach((key) => {
            if (key && key !== 'unitOfMeasurement') {
                if (key === 'size') {
                    makeVarsArrayFromStyleConfig(
                        configPart[key],
                        array,
                        `${variablePart}-${key}`,
                        BASE_UNITS.SIZE
                    );
                } else if (key === 'value') {
                    array.push(
                        `${variablePart}: ${configPart.value as unknown as string | number}${
                            (configPart.unitOfMeasurement as unknown as string | number) ||
                            unitOfMeasurement ||
                            ''
                        };`
                    );
                } else {
                    makeVarsArrayFromStyleConfig(
                        configPart[key],
                        array,
                        `${variablePart}-${key}`,
                        unitOfMeasurement
                    );
                }
            }
        });
    }
};

export const makeVarsArrayFromConfig = (configPart: ConfigPart, array: string[] = []): void => {
    Object.keys(configPart).forEach((key) => {
        const variablePart = `-${key}`;
        const componentConfig = configPart[key];
        if (isPartObject<ConfigPart>(componentConfig) && Object.keys(componentConfig).length) {
            if (componentConfig['style']) {
                makeVarsArrayFromStyleConfig(componentConfig['style'], array, variablePart);
            }
        }
    });
};

const getCss = (theme: ICssTheme, config: IConfig) => {
    const variables: string[] = [];
    makeVarsArrayFromTheme(theme, variables);
    makeVarsArrayFromConfig(config, variables);

    return `:root {${variables.map((key) => `-${key}`).join('')}}`;
};

export const applyCssTheme = (theme: ICssTheme, config: IConfig) => {
    const css = getCss(theme, config);
    const stylesFromHead = document.getElementById('themeVariables');
    if (stylesFromHead) {
        stylesFromHead.textContent = css;
        return;
    }
    const style = document.createElement('style');
    style.setAttribute('id', 'themeVariables');
    style.textContent = css;
    document.head.appendChild(style);
};
