import { httpApi } from 'core/httpApi';

export interface ICreateTwistParams {
    title: string;
    body: string;
    hashParentId: string;
    isExtraTwist: boolean;
}

export interface ICreateTwistResponse {
    hashId: string;
    authorId: string;
    title: string;
    body: string;
    lang: string;
    isMature: boolean;
    hashRootId: string;
    hashParentId: string;
    createdAt: string;
    updatedAt: string;
    genre: string | null;
    tags: string[];
}

export const createTwist = async ({
    title,
    body,
    hashParentId,
    isExtraTwist = false,
}: ICreateTwistParams) =>
    httpApi.v2.post<ICreateTwistResponse, ICreateTwistResponse>('twists', {
        title,
        body,
        hashParentId,
        isExtraTwist,
    });
