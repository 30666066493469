import { FormattedMessage } from 'react-intl';

import styles from './TitleBlock.module.css';
import { TitleBlock as ImageModalTitleBlock } from 'components/organisms/ImageModal/TitleBlock/TitleBlock';

export const TitleBlock = () => (
    <ImageModalTitleBlock className={styles.title}>
        <FormattedMessage id="onboarding_modal.title" />
    </ImageModalTitleBlock>
);
