import { sessionStorageService } from 'utils/sessionStorageService/sessionStorageService';

const forbesMockBalanceSSService = sessionStorageService<unknown>('forbes_mock_balance');

export const forbesMockBalance = {
    set: (value: number) => forbesMockBalanceSSService.set(value),
    get: () => {
        const savedValue = forbesMockBalanceSSService.get();
        if (typeof savedValue === 'number') {
            return savedValue;
        }
        forbesMockBalanceSSService.set(100);
        return 100;
    },
};
