import { useEffect } from 'react';

import { accessTokenCService } from 'api/accessToken/accessTokenCService';
import {
    mountChatWidget,
    unmountChatWidget,
} from 'components/organisms/LiveChatWidget/LifeChatWidget';

type UseInitLiveChatArgs = {
    initEnabled: boolean;
};

export const useInitLiveChat = ({ initEnabled }: UseInitLiveChatArgs) => {
    const token = accessTokenCService.get();

    useEffect(() => {
        unmountChatWidget();
        if (initEnabled) {
            mountChatWidget('https://chat.xsolla.com/widget', 'livechat', token);
        }
        return unmountChatWidget;
    }, [initEnabled, token]);
};
