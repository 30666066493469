import cn from 'classnames';
import type { ComponentProps, FC } from 'react';

import styles from './PlainButton.module.css';

export interface IPlainButtonProps extends ComponentProps<'button'> {
    className?: string;
}

export const PlainButton: FC<IPlainButtonProps> = ({ className, ...rest }) => (
    <button className={cn(className, styles['container'])} {...rest} />
);
