import { TransitionGroup, CSSTransition } from 'react-transition-group';

import styles from './Notifications.module.css';
import { ReactComponent as CrossSVG } from 'assets/cross-2.svg';
import { useNotificationsContext } from 'contexts/NotificationsContext';

export const NOTIFICATION_ANIMATION_DURATION = 400;

export const Notifications = () => {
    const { items, pop } = useNotificationsContext();
    return (
        <div className={styles['container']}>
            <TransitionGroup className={styles['list']}>
                {items.map(({ id, element, showTime, showCloseButton }) => {
                    const animationDuration = `${NOTIFICATION_ANIMATION_DURATION}ms`;
                    const animationClasses = {
                        enter: styles['animation_enter'],
                        enterActive: styles['animation_enter_active'],
                        exit: styles['animation_exit'],
                        exitActive: styles['animation_exit_active'],
                    };

                    const showClose = showCloseButton || (showCloseButton !== false && !showTime);

                    return (
                        <CSSTransition
                            key={id}
                            timeout={NOTIFICATION_ANIMATION_DURATION}
                            classNames={animationClasses}
                        >
                            <div className={styles['notification']} style={{ animationDuration }}>
                                <div className={styles['content']}>{element}</div>
                                {showClose && (
                                    <div className={styles['cross']} onClick={() => pop(id)}>
                                        <CrossSVG width={14} height={14} />
                                    </div>
                                )}
                            </div>
                        </CSSTransition>
                    );
                })}
            </TransitionGroup>
        </div>
    );
};
