import { ELanguage } from 'types/languages';
import { ELocale } from 'types/locale';

const languageLocaleMap = {
    [ELanguage.German]: ELocale.German,
    [ELanguage.English]: ELocale.English,
    [ELanguage.Spanish]: ELocale.Spanish,
    [ELanguage.French]: ELocale.French,
    [ELanguage.Japanese]: ELocale.Japanese,
    [ELanguage.Korean]: ELocale.Korean,
    [ELanguage.Portuguese]: ELocale.Portuguese,
    [ELanguage.Russian]: ELocale.Russian,
    [ELanguage.Chinese]: ELocale.Chinese,
};

export const getLocale = (language: ELanguage) => languageLocaleMap[language];
