import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { IThemeContext, ThemeContext } from 'contexts/Theme/ThemeContext';
import { defaultThemes, applyCssTheme, ICssTheme } from 'themes';
import { IConfig } from 'themes/types/config';
import { IThemeConfigurable } from 'themes/types/theme';

const initialTheme = defaultThemes.light;

export const ThemeContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [themeId, setThemeId] = useState<string>();
    const [config, setConfig] = useState<IConfig>(initialTheme.config);
    const [cssTheme, setCssTheme] = useState<ICssTheme>(initialTheme);

    const setTheme = useCallback((theme: IThemeConfigurable) => {
        const { config, themeId, ...rest } = theme;
        applyCssTheme(rest, config);
        setConfig(config);
        setThemeId(themeId);
        setCssTheme(rest);
    }, []);

    const value = useMemo(
        (): IThemeContext => ({
            themeId,
            config,
            cssTheme,
            setTheme,
        }),
        [themeId, config, cssTheme, setTheme]
    );

    return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};
