import { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ERoutes } from 'app/providers/routing';
import { PageState, PageTemplate } from 'components/templates/PageTemplate';
import { usePartnerRouterContext } from 'contexts/PartnerRouter/usePartnerRouterContext';
import { useMyStoriesQuery } from 'hooks/queries/myStories';
import { useRedirectToWritersFaqParam } from 'hooks/useRedirectToWritersFaqParam';

export const WithRedirectToWritersFAQ: FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();
    const { getRoute } = usePartnerRouterContext();
    const { hasParam } = useRedirectToWritersFaqParam();
    const { data, isLoading, error } = useMyStoriesQuery({
        reFetchOnFocus: false,
    });

    if (isLoading) {
        return <PageTemplate state={error ? PageState.ERROR : PageState.LOADING} />;
    }

    if (hasParam) {
        const url = data?.length ? location.pathname : getRoute(ERoutes.WritersFAQ);
        return <Navigate to={url} replace />;
    }

    return <>{children}</>;
};
