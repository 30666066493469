import { forbesMockReadHistory } from 'api/forbesMock/forbesMockReadHistorySSService';
import {
    story1,
    story2,
    twist11,
    twist12,
    twist13,
    twist21,
    twist22,
    twist23,
} from 'api/forbesMock/forbesMockStoriesData';

export const forbesMockReadTwist = async (twistId: string, signal?: AbortSignal) => {
    forbesMockReadHistory.push(twistId);
    const purchasedTwists = forbesMockReadHistory.get();
    if (twistId === twist11().hashId) {
        return Promise.resolve([story1(purchasedTwists), twist11(true)]);
    }
    if (twistId === twist12().hashId) {
        return Promise.resolve([story1(purchasedTwists), twist12(true)]);
    }
    if (twistId === twist13().hashId) {
        return Promise.resolve([story1(purchasedTwists), twist13(true)]);
    }
    if (twistId === twist21().hashId) {
        return Promise.resolve([story2(purchasedTwists), twist21(true)]);
    }
    if (twistId === twist22().hashId) {
        return Promise.resolve([story2(purchasedTwists), twist22(true)]);
    }
    if (twistId === twist23().hashId) {
        return Promise.resolve([story2(purchasedTwists), twist23(true)]);
    }
    return Promise.resolve([]);
};
