import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import { readStoryIncrement, toggleTip } from './slice';
import { RootState } from 'store/store';
import { localStorageService } from 'utils/localStorageService';

const storageService = localStorageService('flowTipsConfig');

export const flowTipsListenerMiddleware = createListenerMiddleware();
flowTipsListenerMiddleware.startListening({
    matcher: isAnyOf(readStoryIncrement, toggleTip),
    effect: (_action, listenerApi) => {
        storageService.set((listenerApi.getState() as RootState).flowTips);
    },
});
