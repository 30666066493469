import axios from 'axios';

import { API_V1_BASE_URL, API_V2_BASE_URL } from '../config';
import { PROJECTS } from 'contexts/PartnerRouter/PartnerRouterContext';

const v1 = axios.create({ baseURL: API_V1_BASE_URL, timeout: 15000 });
const v2 = axios.create({ baseURL: API_V2_BASE_URL, timeout: 15000 });

v1.interceptors.response.use((response) => response.data);
v2.interceptors.response.use((response) => response.data);

const setToken = (token: string | undefined) => {
    v1.defaults.headers.common.Authorization = token ? `Bearer ${token}` : token;
    v2.defaults.headers.common.Authorization = token ? `Bearer ${token}` : token;
};

const setProjectHeader = (projectHeader: PROJECTS) => {
    if (projectHeader) {
        v2.defaults.headers.common['x-story3-channel'] = projectHeader;
    } else {
        delete v2.defaults.headers.common['x-story3-channel'];
    }
};

export const httpApi = { v1, v2, setToken, setProjectHeader };
