import { useCallback } from 'react';

import { useUpdateUserMutation, useUserQuery } from 'hooks/queries/user';
import { ELanguage } from 'types/languages';
import { uiLanguageClientStorage } from 'utils/languages/uiLanguageClientStorage';

export const useSaveUiLanguage = () => {
    const { data: user } = useUserQuery();
    const { mutateAsync } = useUpdateUserMutation();
    const isAuthenticated = user?.isAuthenticated;

    return useCallback(
        async (language: ELanguage) => {
            if (isAuthenticated && user.uiLanguage !== language) {
                await mutateAsync({ ui_language: language });
            }

            uiLanguageClientStorage.set(language);
        },
        [mutateAsync, isAuthenticated]
    );
};
