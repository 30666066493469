import { storyLanguagesLSService } from 'api/languages/storyLanguagesLSService';
import { httpApi } from 'core/httpApi';
import { ELanguage } from 'types/languages';
import { Gender } from 'types/user';

export interface ICompleteSignUpSurveyParams {
    gender: Gender | null;
    birthday: Date;
    tags: string[];
    tosAccepted: boolean;
    mailingAccepted: boolean;
    ui_language: ELanguage;
}

export const completeSignUpSurvey = async (params: ICompleteSignUpSurveyParams) =>
    httpApi.v2.post<void, void>('users/survey', params, {
        headers: {
            'accept-language': storyLanguagesLSService.getQueryString(),
        },
    });
