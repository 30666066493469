import { createContext } from 'react';

import { ELanguage } from 'types/languages';
import { TTranslations } from 'types/translations';

export interface ILocalizationContext {
    language: ELanguage;
    setLanguage: (language: ELanguage) => void;
    setTranslations: (translations: TTranslations) => void;
}

export const LocalizationContext = createContext<ILocalizationContext>({
    language: ELanguage.English,
    setLanguage: () => {},
    setTranslations: () => {},
});
