import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

import styles from './ImageBlockPreloader.module.css';

interface IImageBlockPreloaderProps extends PropsWithChildren {
    loading: boolean;
    className: string;
}

export const ImageBlockPreloader: FC<IImageBlockPreloaderProps> = ({
    loading,
    className,
    children,
}) => (
    <>
        <div
            className={cn(
                styles['preloader'],
                `preloader_${loading ? 'current' : 'next'}`,
                className
            )}
        />
        {!loading && children}
    </>
);
