import cn from 'classnames';
import { FC, PropsWithChildren, ReactElement } from 'react';

import styles from './ModalTemplateSecond.module.css';
import { SidePattern } from 'components/atoms/SidePattern';
import { SidePatternVariant } from 'components/atoms/SidePattern/SidePattern';
import { useThemeConfig } from 'hooks/theme/useThemeConfig';

interface IModalTemplate extends PropsWithChildren {
    header?: ReactElement;
    sidePatternVariant?: SidePatternVariant;
}

export const ModalTemplateSecond: FC<IModalTemplate> = ({
    header,
    children,
    sidePatternVariant,
}) => {
    const { defaultSidePattern } = useThemeConfig((themeConfig) => themeConfig.modalTemplateSecond);
    const sidePattern = sidePatternVariant || defaultSidePattern || 'secondary';

    return (
        <div className={styles['modal']}>
            <SidePattern variant={sidePattern} className={styles['pattern']} />
            <div className={styles['container']}>
                {header}
                <div className={cn('story3_scrollable', styles['main'])}>
                    <div>{children}</div>
                </div>
            </div>
        </div>
    );
};
