import { FC } from 'react';

import { ImageBlock } from './ImageBlock/ImageBlock';
import { MainBlock } from './MainBlock/MainBlock';
import { NextButtonBlock } from './NextButtonBlock/NextButtonBlock';
import styles from './OnboardingModal.module.css';
import { TitleBlock } from './TitleBlock/TitleBlock';
import { ImageModal } from 'components/organisms/ImageModal/ImageModal';

interface IOnboardingModalProps {
    loading: boolean;
    onFinish: () => void;
    onClose: () => void;
}

export const OnboardingModal: FC<IOnboardingModalProps> = ({ loading, onFinish, onClose }) => (
    <ImageModal
        loading={loading}
        onClose={onClose}
        header={<div className={styles.headerBlock} />}
        title={<TitleBlock />}
        main={<MainBlock />}
        image={<ImageBlock />}
        className={styles.modal}
        preloaderClassName={styles.preloader}
        buttonBlock={<NextButtonBlock onClick={onFinish} />}
    />
);
