import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

import { Text } from '../../../atoms/Text';
import styles from './MainBlock.module.css';

interface IMainBlockProps {
    className?: string;
    withSmallScreen?: boolean;
}

export const MainBlock: FC<PropsWithChildren<IMainBlockProps>> = ({
    className,
    children,
    withSmallScreen = true,
}) => (
    <div className={cn(styles['container'], className)}>
        <Text
            typo="paragraphL"
            className={styles['text']}
            smallMobileTypo={withSmallScreen ? 'body-md' : undefined}
        >
            {children}
        </Text>
    </div>
);
