import { ICssTheme } from 'themes/types';

export const shadows: ICssTheme['shadows'] = {
    active: '0px 1px 2px rgba(7, 7, 8, 0.2), 0px 1px 3px 1px rgba(7, 7, 8, 0.1);',
    surface: '0px 1px 2px rgba(7, 7, 8, 0.2), 0px 2px 6px 2px rgba(7, 7, 8, 0.1);',
    surfaceHover: '0px 4px 8px 3px rgba(7, 7, 8, 0.1), 0px 1px 3px rgba(7, 7, 8, 0.2);',
    popover: '0px 6px 10px 4px rgba(7, 7, 8, 0.1), 0px 2px 3px rgba(7, 7, 8, 0.2);',
    modal: '0px 8px 12px 6px rgba(7, 7, 8, 0.1), 0px 4px 4px rgba(7, 7, 8, 0.2);',
    uber: '0px 6px 34px 0px rgba(0, 0, 0, 0.12);',
};
