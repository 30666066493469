import { localStorageService } from 'utils/localStorageService';

const onboardingLSService = localStorageService<unknown>('story3_onboarding_finished');

export const onboardingLocalStorage = {
    set: (value: boolean) => onboardingLSService.set(value),
    get: () => {
        const savedValue = onboardingLSService.get();
        return !!savedValue;
    },
};
