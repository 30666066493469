import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

import { Text } from '../../../atoms/Text';
import styles from './TitleBlock.module.css';

interface ITitleBlockProps {
    className?: string;
    withSmallScreen?: boolean;
}

export const TitleBlock: FC<PropsWithChildren<ITitleBlockProps>> = ({
    children,
    className,
    withSmallScreen = true,
}) => (
    <div className={cn(styles['container'], className)}>
        <Text
            typo="h1"
            mobileTypo="h2"
            smallMobileTypo={withSmallScreen ? 'h3' : undefined}
            color="inherit"
            className={cn(styles['text'])}
        >
            {children}
        </Text>
    </div>
);
