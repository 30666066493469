import { IDisableRoutesOptions } from 'themes/types/routes';

export const defaultRoutesOptions: IDisableRoutesOptions = {
    disableTransactions: false,
    disableProfile: false,
    disableMain: false,
    disableStory: false,
    disableWritersFAQ: false,
    disableLegalDocuments: false,
    disableMyStories: false,
    disableMyStoriesReading: false,
    disableMyStoriesWriting: false,
    disableCreateStory: false,
    disableEditStory: false,
    disableReferralStory: false,
};
