import * as Sentry from '@sentry/react';

import { IExtraArgsBase, IExtraArgsWithValue, IXsollaAnalytics } from './types';
import { AbService } from 'analytics/ab-service';
import { XSOLLA_METRIKA_URL } from 'config';
import {
    executeForTheme,
    isPartnerUrl,
    PARTNERS_DATA,
    PARTNERS_URL,
} from 'contexts/PartnerRouter/PartnerRouterContext';

const XsollaAnalytics = require('@xsolla/metrika/watch').default;

class XM {
    private productId = 300_000;
    private url = XSOLLA_METRIKA_URL;
    private userId: number | null = null;
    private userCountry: string | null = null;
    private static instance: IXsollaAnalytics | null = null;

    private static readonly PROJECT_IDS: PARTNERS_DATA<string> = {
        FORBES: 'forbes',
        DEFAULT: 'story3',
        NEW_YORK_TIMES: 'nytimes',
        EIGHTY_LEVEL: '80lv',
        EIGHTY_LEVEL_DEV: '80lv-dev',
    };

    private logError(message: string) {
        const fullMessage = `Xsolla Metrika: ${message}`;
        Sentry.captureException(new Error(fullMessage));
        console.warn(fullMessage);
    }

    public init(id: number | null, country: string | null = null) {
        this.userId = id;
        this.userCountry = country;

        if (XM.instance) {
            this.logError('XsollaAnalytics instance was reinitialized (unexpected behavior)');
        }

        if (!this.url || XM.instance) {
            return;
        }

        try {
            XM.instance = new XsollaAnalytics({
                id: this.productId,
                server: this.url,
                externalId: this.userId,
                abParams: AbService.getAbParams(),
            }) as IXsollaAnalytics;
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    public reInit(userId: number) {
        XM.instance = null;
        this.init(userId, this.userCountry);
    }

    private static getProjectId(): string {
        return executeForTheme<string>({
            DEFAULT: {
                callback: () => XM.PROJECT_IDS.DEFAULT,
            },
            FORBES: {
                match: isPartnerUrl(PARTNERS_URL.FORBES),
                callback: () => XM.PROJECT_IDS.FORBES,
            },
            NEW_YORK_TIMES: {
                match: isPartnerUrl(PARTNERS_URL.NEW_YORK_TIMES),
                callback: () => XM.PROJECT_IDS.NEW_YORK_TIMES,
            },
            EIGHTY_LEVEL: {
                match: isPartnerUrl(PARTNERS_URL.EIGHTY_LEVEL),
                callback: () => XM.PROJECT_IDS.EIGHTY_LEVEL,
            },
            EIGHTY_LEVEL_DEV: {
                match: isPartnerUrl(PARTNERS_URL.EIGHTY_LEVEL_DEV),
                callback: () => XM.PROJECT_IDS.EIGHTY_LEVEL_DEV,
            },
        });
    }

    private getExtraArgsBase(extraValues?: IExtraArgsBase['exv']): IExtraArgsBase {
        return {
            eid: this.userId,
            abParams: AbService.getAbParams(),
            exv: {
                country: this.userCountry,
                projectId: XM.getProjectId(),
                ...extraValues,
            },
        };
    }

    private getExtraArgsWithValue(
        elementValue?: IExtraArgsWithValue['ev'],
        extraValues?: IExtraArgsWithValue['exv']
    ): IExtraArgsWithValue {
        return {
            ...this.getExtraArgsBase(extraValues),
            ev: elementValue,
        };
    }

    public internalLinkClick(url: string, extraValues: Record<string, unknown> = {}) {
        XM.instance?.hit(url, this.getExtraArgsBase(extraValues));
    }
    public externalLinkClick(url: string, extraValues: Record<string, unknown> = {}) {
        XM.instance?.externalLink(url, this.getExtraArgsBase(extraValues));
    }
    public elementClick(
        elementName: string,
        elementValue?: string,
        extraValues: Record<string, unknown> = {}
    ) {
        XM.instance?.elementClick(
            elementName,
            this.getExtraArgsWithValue(elementValue, extraValues)
        );
    }

    public sendFormData(
        elementName: string,
        formDataObj: Record<string, unknown>,
        elementValue?: string,
        extraValues: Record<string, unknown> = {}
    ) {
        XM.instance?.formData(
            elementName,
            formDataObj,
            this.getExtraArgsWithValue(elementValue, extraValues)
        );
    }
    public customEvent(eventName: string, extraValues: Record<string, unknown> = {}) {
        XM.instance?.customEvent(eventName, this.getExtraArgsBase(extraValues));
    }

    public getDFP(): string | null {
        return XM.instance?.dfpGet() || null;
    }
}

export const XsollaMetrika = new XM();
