import * as Sentry from '@sentry/react';

import { getCountry } from 'api/geo';
import { queryClient } from 'app/providers/ReactQueryProvider';

export const fetchCountry = () => {
    try {
        return queryClient.fetchQuery({ queryKey: [], queryFn: getCountry });
    } catch (error) {
        Sentry.captureException(error);
    }
};
