import { httpApi } from 'core/httpApi';
import { MonetizationOption } from 'types/monetization';

export interface IReadTwistItem {
    title: string;
    hashId: string;
    publishedAt: string;
    purchased: boolean;
    hash: string;
    price: number;
    nickname: string;
}

interface IReadTwistPathItem {
    hashId: string;
    title: string;
    body: string;
    publishedAt: string;
    twists: IReadTwistItem[];
    purchasedTwists: IReadTwistItem[];
    genre: string | null;
    tags: string[];
    nickname: string;
    monetization_option: MonetizationOption;
}

export type ReadTwistResponse = IReadTwistPathItem[];

export const readTwist = async (twistId: string, signal?: AbortSignal) =>
    httpApi.v2.post<ReadTwistResponse, ReadTwistResponse>(`twists/${twistId}/read`, {}, { signal });
