import cn from 'classnames';
import type { ComponentProps, FC } from 'react';

import styles from './Anchor.module.css';

type LinkColor = 'inherit' | 'link';

export interface IAnchorProps extends ComponentProps<'a'> {
    color?: LinkColor;
}

export const Anchor: FC<IAnchorProps> = ({
    color = 'inherit',
    className,
    children,
    ...restProps
}) => (
    <a
        {...restProps}
        rel="noreferrer"
        className={cn(className, styles['anchor'], styles[`color_${color}`])}
    >
        {children}
    </a>
);
