import { lazy, Suspense } from 'react';

import { Spinner } from '../../atoms/Spinner';
import { ModalWindowBase } from 'components/organisms/ModalWindowBase';
import { ModalTemplateBasic } from 'components/templates/ModalTemplateBasic';

const SingUpForm = lazy(() => import('components/organisms/SingUpForm/SignUpForm'));

const SignUpFormLazy = () => (
    <ModalWindowBase>
        <ModalTemplateBasic>
            <Suspense fallback={<Spinner size={20} />}>
                <SingUpForm />
            </Suspense>
        </ModalTemplateBasic>
    </ModalWindowBase>
);

export default SignUpFormLazy;
