import { FC, PropsWithChildren, ReactElement } from 'react';

import styles from './ModalTemplateBasic.module.css';

interface IModalTemplate extends PropsWithChildren {
    header?: ReactElement;
}

export const ModalTemplateBasic: FC<IModalTemplate> = ({ header, children }) => (
    <div className={styles['modal']}>
        <div className={styles['container']}>
            {header && <div>{header}</div>}
            <div className={'story3_scrollable'}>
                <div>{children}</div>
            </div>
        </div>
    </div>
);
