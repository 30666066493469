import { light } from '../default//variants/light/light';
import { shadows } from '../default//variants/light/shadows';
import { typo } from '../default/typo';
import { config } from '../default/variants/light/config';
import { IMisc } from '../types';
import { IThemeConfigurable, THEME_ID } from '../types/theme';

const defaultLight: IThemeConfigurable = {
    ...light,
    shadows,
    typo,
    misc: {} as IMisc,
    themeId: THEME_ID.DEFAULT,
    config,
};

export const defaultThemes = {
    light: defaultLight,
};
