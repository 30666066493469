import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MY_STORIES_LIST_QUERY_KEY } from './useMyStoriesQuery';
import { deleteMyArchiveStory } from 'api/myStories/deleteMyArchiveStory';

export const useDeleteMyArchivedStoryMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteMyArchiveStory,
        onSuccess: () => queryClient.invalidateQueries(MY_STORIES_LIST_QUERY_KEY),
    });
};
