import { FC, ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Button } from '../../atoms/Button/Button';
import styles from './ErrorMessage.module.css';
import { ERoutes } from 'app/providers/routing';
import { ReactComponent as OoopsPicture } from 'assets/ooops.svg';
import { Text } from 'components/atoms/Text';
import { useInAppNavigate } from 'hooks/useInAppNavigate';

export interface IErrorMessage {
    text?: ReactNode;
    hideGoBack?: boolean;
    logo?: ReactNode;
}

export const ErrorMessage: FC<IErrorMessage> = ({ logo, text, hideGoBack }) => {
    const { pathname } = useLocation();
    const navigate = useInAppNavigate();

    const onGoToMainPageClick = useCallback(() => {
        pathname === ERoutes.Main ? navigate(0) : navigate(ERoutes.Main);
    }, [navigate, pathname]);

    const onBackToPrevious = useCallback(() => {
        // If page before current one is in current domain
        if (document.referrer.indexOf(window.location.host) >= 0) {
            navigate(-1);
        } else {
            navigate(ERoutes.Main);
        }
    }, [navigate]);

    return (
        <div className={styles['container']}>
            <div className={styles['content']}>
                <Text color="warning" className={styles['picture']}>
                    {logo ?? <OoopsPicture />}
                </Text>

                <Text typo="body-lg" color="primary" className={styles['text']}>
                    {text ?? (
                        <FormattedMessage
                            id="bundled.error_message.title"
                            values={{ br: <br /> }}
                        />
                    )}
                </Text>

                <div className={styles['buttons']}>
                    {!hideGoBack && (
                        <Button size="lg" stretched onClick={onBackToPrevious}>
                            <FormattedMessage id="bundled.error_message.button.go_back" />
                        </Button>
                    )}
                    <Button
                        size="lg"
                        textColor="primary"
                        variant="text"
                        stretched
                        onClick={onGoToMainPageClick}
                    >
                        <FormattedMessage id="bundled.error_message.button.go_to_main" />
                    </Button>
                </div>
            </div>
        </div>
    );
};
