import { useState } from 'react';

import { onboardingLocalStorage } from '../model/onboardingLocalStorage';
import { OnboardingModal } from '../ui/OnboardingModal';

interface IUseOnboardingModalArgs {
    initEnabled: boolean;
}

export const useOnboardingModal = ({ initEnabled }: IUseOnboardingModalArgs) => {
    const [finished, setFinished] = useState(onboardingLocalStorage.get());

    const handleFinish = () => {
        setFinished(true);
        onboardingLocalStorage.set(true);
    };

    const modalVisible = initEnabled && !finished;

    return modalVisible ? (
        <OnboardingModal loading={false} onFinish={handleFinish} onClose={handleFinish} />
    ) : null;
};
