import cn from 'classnames';
import { FC, RefObject } from 'react';

import './GDPRBanner.css';

type GDPRBannerProps = {
    bannerRef: RefObject<HTMLDivElement>;
    isLoaded: boolean;
};

export const GDPRBanner: FC<GDPRBannerProps> = ({ isLoaded, bannerRef }) => (
    <div
        id="gdpr-consent-form"
        ref={bannerRef}
        className={cn([!isLoaded && 'story3_gdpr_banner_hidden'])}
    />
);
