import { TResponseSchema } from 'api/user/types';
import { IAuthenticatedUser } from 'types/user';

export const prepareUserResponse = (response: TResponseSchema): IAuthenticatedUser => ({
    isAuthenticated: true,
    id: response.id,
    externalId: response.external_id,
    hashId: response.hash_id,
    pseudonym: response.pseudonym,
    email: response.email,
    isAuthor: Boolean(response.author),
    isUser: Boolean(response.user),
    agentState: response.agent_state,
    preferredLanguages: response.preferred_languages,
    uiLanguage: response.ui_language,
});
