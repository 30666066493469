import cn from 'classnames';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './EditorStatus.module.css';
import { ReactComponent as CheckMarkSVG } from 'assets/check-mark.svg';
import { ReactComponent as DangerSVG } from 'assets/danger.svg';
import { Spinner } from 'components/atoms/Spinner';
import { Text } from 'components/atoms/Text';

export enum EditorStatuses {
    UNSAVED = 'unsaved',
    SAVING = 'saving',
    SAVED = 'saved',
}

export interface IEditorStatusProps {
    status: EditorStatuses;
}

const IconsMap = {
    [EditorStatuses.UNSAVED]: <DangerSVG />,
    [EditorStatuses.SAVING]: <Spinner size={12} className={styles['spinner']} />,
    [EditorStatuses.SAVED]: <CheckMarkSVG />,
};

export const EditorStatus: FC<IEditorStatusProps> = ({ status }) => {
    const text = <FormattedMessage id={`story_editor.status.${status}`} />;
    const icon = IconsMap[status];

    return (
        <Text
            className={styles['container']}
            typo="minor"
            color={status === EditorStatuses.UNSAVED ? 'alert' : 'tertiary'}
        >
            <div className={cn(styles['icon'], styles[`icon_${status}`])}>{icon}</div>
            {text}
        </Text>
    );
};
