import { FC, PropsWithChildren, SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import styles from './ModalWindowBase.module.css';
import { ROOT_DOM_ID } from 'constants/domIds';
import { useModalWindowContext } from 'contexts/ModalWindowContext';

interface IModalWindowBaseProps extends PropsWithChildren {
    onShadowClick?: () => void;
}

export const ModalWindowBase: FC<IModalWindowBaseProps> = ({ onShadowClick, children }) => {
    const shadowRef = useRef<HTMLDivElement>(null);
    const modalWindow = useModalWindowContext();

    const handleClick = useCallback(
        (event: SyntheticEvent<HTMLDivElement>) => {
            if (event.target && event.target === shadowRef.current) {
                onShadowClick?.();
            }
        },
        [shadowRef, onShadowClick]
    );

    useEffect(() => {
        modalWindow.display();
        return () => modalWindow.hide();
    }, []);

    return createPortal(
        <div ref={shadowRef} className={styles['shadow']} onClick={handleClick}>
            {children}
        </div>,
        document.getElementById(ROOT_DOM_ID)!
    );
};
