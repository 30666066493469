import { IGetLanguagesResponse } from 'api/languages/getStoryLanguages';
import { updateUser } from 'api/user';
import { queryClient } from 'app/providers/ReactQueryProvider';
import { STORY_LANGUAGES_KEY } from 'hooks/queries/languages/useStoryLanguages';
import { USER_QUERY_KEY } from 'hooks/queries/user';
import { TUser } from 'types/user';
import { DEFAULT_LANGUAGE, getMatchedLanguages } from 'utils/languages/getDefaultLanguages';
import { localStorageService } from 'utils/localStorageService';
const defaultLSService = localStorageService<string[]>('content_languages');

export const storyLanguagesLSService = {
    get: () => {
        const allStoriesLanguages = queryClient
            .getQueryData<IGetLanguagesResponse>([STORY_LANGUAGES_KEY])
            ?.filter((lang) => lang.count > 0)
            .map((lang) => lang.code) || [DEFAULT_LANGUAGE];

        const localLanguages = defaultLSService.get() || [];
        return getMatchedLanguages(localLanguages, allStoriesLanguages);
    },
    set: async (value: string[], user: TUser | undefined) => {
        try {
            if (user?.isAuthenticated && user.isUser) {
                //TODO переделать на useMutate
                await updateUser({ preferred_languages: value });
                await queryClient.invalidateQueries(USER_QUERY_KEY);
            }
            defaultLSService.set(value);
        } catch (e) {}
    },
    getQueryString: () => (defaultLSService.get() || []).join(','),
};
