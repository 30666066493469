import { httpApi } from 'core/httpApi';

interface IPostAnalyticsViewRequestData {
    hashId: string;
    isRoot: boolean;
    clientId: string;
    fingerprint: string | null;
}

export const postAnalyticsView = (data: IPostAnalyticsViewRequestData) =>
    httpApi.v2.post('/analytics/view', data);
