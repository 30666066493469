import { config } from 'themes/partners/80lv-dev/config';
import { colors } from 'themes/partners/80lv/colors';
import { shadows } from 'themes/partners/80lv/shadows';
import { typo } from 'themes/partners/80lv/typo';
import { IMisc } from 'themes/types';
import { IThemeConfigurable, THEME_ID } from 'themes/types/theme';

export const eightyLevelDevTheme: IThemeConfigurable = {
    ...colors,
    shadows,
    typo,
    misc: {} as IMisc,
    themeId: THEME_ID.EIGHTY_LEVEL_DEV,
    config,
};
