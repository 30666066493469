import { Status } from '../../components/organisms/StoryEditorForm/StatusService';
import { PROJECTS } from 'contexts/PartnerRouter/PartnerRouterContext';
import { httpApi } from 'core/httpApi';

export interface ICreateStoryParams {
    title: string;
    body: string;
    isMature?: boolean;
    project?: PROJECTS;
}

export interface ICreateStoryResponse {
    hashId: string;
    authorId: string;
    title: string;
    body: string;
    lang: string;
    isMature: boolean;
    createdAt: string;
    updatedAt: string;
    genre: string | null;
    tags: string[];
    status: Status;
}

export const createStory = async ({ title, body, isMature, project }: ICreateStoryParams) =>
    httpApi.v2.post<ICreateStoryResponse, ICreateStoryResponse>('stories', {
        title,
        body,
        isMature,
        channel: project || undefined,
    });
