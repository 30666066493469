import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { ModalWindowContext } from './ModalWindowContext';

export const ModalWindowContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [count, setCount] = useState<number>(0); // we use a counter for cases when one modal window is displayed in another modal window

    const hide = useCallback(() => setCount((count) => Math.max(count - 1, 0)), []);
    const display = useCallback(() => setCount((count) => count + 1), []);

    const value = useMemo(
        () => ({
            isDisplayed: count > 0,
            hide,
            display,
        }),
        [count, hide, display]
    );

    return <ModalWindowContext.Provider value={value}>{children}</ModalWindowContext.Provider>;
};
