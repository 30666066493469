import { ICssTheme } from 'themes/types';

export const headingFontFamily = `'Family', georgia, serif`;
export const mainFontFamily = `'LabilGrotesk', arial, serif`;

export const typo: ICssTheme['typo'] = {
    fontFamily: mainFontFamily,
    core: {
        h1: {
            fontFamily: headingFontFamily,
            fontSize: '60px',
            lineHeight: '100%',
            letterSpacing: '-2.4px',
            fontWeight: 400,
        },
        h2: {
            fontFamily: headingFontFamily,
            fontSize: '48px',
            lineHeight: '100%',
            letterSpacing: '-0.96px',
            fontWeight: 500,
        },
        h3: {
            fontFamily: headingFontFamily,
            fontSize: '36px',
            lineHeight: '100%',
            letterSpacing: '-0.72px',
            fontWeight: 500,
        },
        h4: {
            fontFamily: headingFontFamily,
            fontSize: '24px',
            lineHeight: '130%',
            letterSpacing: '-0.48px',
            fontWeight: 700,
        },
        strong: {
            fontFamily: mainFontFamily,
            fontSize: '40px',
            lineHeight: '90%',
            letterSpacing: '-0.8%',
            fontWeight: 600,
        },
        bodyLgAccent: {
            fontFamily: mainFontFamily,
            fontSize: '32px',
            lineHeight: '90%',
            letterSpacing: '-0.64%',
            fontWeight: 600,
        },
        bodyLg: {
            fontFamily: mainFontFamily,
            fontSize: '24px',
            lineHeight: '90%',
            letterSpacing: '-0.48%',
            fontWeight: 600,
        },
        bodyMdAccent: {
            fontFamily: mainFontFamily,
            fontSize: '18px',
            lineHeight: '130%',
            letterSpacing: '0',
            fontWeight: 600,
        },
        bodyMd: {
            fontFamily: mainFontFamily,
            fontSize: '16px',
            lineHeight: '130%',
            letterSpacing: '0',
            fontWeight: 500,
        },
        paragraphL: {
            fontFamily: mainFontFamily,
            fontSize: '18px',
            lineHeight: '160%',
            letterSpacing: '0',
            fontWeight: 500,
        },
        paragraphM: {
            fontFamily: mainFontFamily,
            fontSize: '16px',
            lineHeight: '160%',
            letterSpacing: '0',
            fontWeight: 500,
        },
        label: {
            fontFamily: mainFontFamily,
            fontSize: '16px',
            lineHeight: '130%',
            letterSpacing: '0',
            fontWeight: 600,
        },
        minor: {
            fontFamily: mainFontFamily,
            fontSize: '14px',
            lineHeight: '130%',
            letterSpacing: '0',
            fontWeight: 500,
        },
        micro: {
            fontFamily: mainFontFamily,
            fontSize: '12px',
            lineHeight: '130%',
            letterSpacing: '0',
            fontWeight: 600,
        },
    },
};
