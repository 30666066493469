import type { IConfig } from 'themes/types/config';

import { defaultRoutesOptions } from './routes';
import { postAnalyticsView } from 'api/analytics/postAnalyticsView';
import { getUserBalance } from 'api/billing/getUserBalance';
import { getStoryBySummary } from 'api/refferalSystem/getStoryBySummary';
import { postLastOpenedStory } from 'api/stories';
import { getReadableStoryById } from 'api/stories/getReadableStoryById';
import { getTransactions } from 'api/transactions';
import { buyTwistByHash } from 'api/twist';
import { readTwist } from 'api/twists';
import { getOpenedTwists } from 'api/twists/getOpenedTwists';
import { readFreeTwist } from 'api/twists/readFreeTwist';
import SidePattern1 from 'assets/side-pattern-1.svg';
import SidePattern2 from 'assets/side-pattern-2.png';
import LogoImage from 'components/atoms/images/logo.svg';
import { getLastOpenedStory, getStories } from 'entities/story';

export const config: IConfig = {
    routesOptions: {
        ...defaultRoutesOptions,
    },
    api: {
        getReadableStoryById,
        getStories,
        getStoryBySummary,
        readFreeTwist,
        getLastOpenedStory,
        buyTwistByHash,
        getUserBalance,
        readTwist,
        getTransactions,
        getOpenedTwists,
        postLastOpenedStory,
        postAnalyticsView,
    },
    withSideBar: {
        style: {
            sidePattern: {
                size: {
                    mobile: {
                        width: 16,
                    },
                    desktop: {
                        width: 52,
                    },
                },
            },
        },
    },
    sidePattern: {
        style: {
            backGroundImage: {
                desktop: `url('${SidePattern1}')`,
                mobile: `url('${SidePattern2}')`,
            },
            backgroundSize: {
                desktop: 'cover',
                mobile: '100% 1200px',
            },
        },
    },
    logoSidebar: {
        onion: {
            hidden: false,
        },
    },
    logo: {
        image: LogoImage,
        style: {
            size: {
                // width of logo image
                mobile: {
                    width: 78,
                },
                desktop: {
                    width: 189,
                },
            },
            // можно забить и юзать default, если устраивает прыжок при lazy load
            skeleton: {
                // height of logo image
                size: {
                    mobile: {
                        height: 26,
                    },
                    desktop: {
                        height: 62,
                    },
                },
            },
        },
    },
    twistCard: {
        style: {
            fillingBackground:
                'linear-gradient(to bottom right, rgb(89 137 73 / 80%) 0%, rgb(89 137 73 / 0%) 50%) ' +
                'bottom right / 50% 50% no-repeat, ' +
                'linear-gradient(to bottom left, rgb(89 137 73 / 80%) 0%, rgb(89 137 73 / 0%) 50%) bottom ' +
                'left / 50% 50% no-repeat, ' +
                'linear-gradient(to top left, rgb(89 137 73 / 80%) 0%, rgb(89 137 73 / 0%) 50%) top left / ' +
                '50% 50% no-repeat, ' +
                'linear-gradient(to top right, rgb(89 137 73 / 80%) 0%, rgb(89 137 73 / 0%) 50%) top right / ' +
                '50% 50% no-repeat, rgb(89 137 73 / 80%);',
            frontGradient:
                'linear-gradient(180deg,rgb(89 137 73 / 30%) 0%,' +
                'rgb(241 231 255 / 0%) 50%,transparent 100%)',
            cardBought: {
                titleColor: 'var(--colors-core-background-primary)',
            },
            cardSelected: {
                background: 'var(--colors-control-tertiary-bg)',
                titleColor: 'var(--colors-core-text-primary)',
                authorColor: 'var(--colors-core-text-tertiary)',
            },
        },
    },
    addUserPathButton: {
        style: {
            tip: {
                background: 'var(--colors-core-background-brand)',
            },
        },
    },
    onboardingModal: {
        hidden: false,
    },
    writersFAQCard: {
        hidden: false,
    },
    callToWriteStoriesCard: {
        hidden: false,
    },
    agentFlow: {
        hidden: false,
    },
    readyToWriteCard: {
        hidden: false,
    },
    skippableModal: {
        hidden: false,
    },
    signUpModal: {
        hidden: false,
    },
    replenishBalanceModalWindow: {
        disableBuy: false,
    },
    disclaimerModal: {
        Component: null,
    },
    socialLinksBlock: {
        hidden: false,
    },
    modalTemplateSecond: {
        defaultSidePattern: 'secondary',
    },
};
