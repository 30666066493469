import { gtmPushEvent } from '../gtm/gtm';
import { gtmEventNames } from '../gtm/gtmEventNames';
import { XsollaMetrika } from '../xsolla-metrika';
import { xmEventNames } from '../xsolla-metrika/xmEventNames';

export const twistCardView = (twistId: string, index: number, twistPrice: number | undefined) => {
    const position = index + 1;
    XsollaMetrika.customEvent(xmEventNames.story_reading_choice_view, {
        twist_id: twistId,
        position,
        twist_price: twistPrice,
    });
};

export const openTwistAttempt = (twistId: string, index: number, price: number) => {
    const position = index + 1;
    XsollaMetrika.elementClick(xmEventNames.story_reading_choice_unlock_attempt, twistId, {
        position,
        price,
    });
};

export const openTwistSuccess = (
    twistId: string,
    index: number,
    price: number,
    storyTitle: string,
    twistTitle: string
) => {
    const position = index + 1;
    XsollaMetrika.elementClick(xmEventNames.story_reading_choice_unlock_success, twistId, {
        position,
        price,
    });
    gtmPushEvent(gtmEventNames.twist_opened, {
        twist_id: twistId,
        twist_position: position,
        story_title: storyTitle,
        twist_title: twistTitle,
    });
};

export const addTwistClick = (parentId: string, isOwnStory: boolean) => {
    const xmEventName = isOwnStory
        ? xmEventNames.story_own_add_twist
        : xmEventNames.story_foreign_add_twist;
    XsollaMetrika.elementClick(xmEventName, 'start', { parent_id: parentId });

    const gtmEventName = isOwnStory
        ? gtmEventNames.add_twist_to_own_story
        : gtmEventNames.add_twist_to_someones_story;
    gtmPushEvent(gtmEventName, { event_value: 'start', twist_parent_id: parentId });
};

export const finishAddingTwistClick = (parentId: string, isOwnStory: boolean) => {
    const eventName = isOwnStory
        ? xmEventNames.story_own_add_twist
        : xmEventNames.story_foreign_add_twist;
    XsollaMetrika.elementClick(eventName, 'complete', { parent_id: parentId });
};
