import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useInAppNavigate } from 'hooks/useInAppNavigate';

export const AgentInvite = () => {
    const navigate = useInAppNavigate();
    const { nickname } = useParams();

    useEffect(() => {
        if (nickname) {
            localStorage.setItem('agent', nickname);
        }

        navigate('/');
    }, [navigate, nickname]);

    return null;
};
