import { httpApi } from 'core/httpApi';
import { MonetizationOption } from 'types/monetization';

export interface IPatchTwistParams {
    twistId: string;
    title?: string;
    body?: string;
    monetization_option?: MonetizationOption;
}

export interface IPatchTwistResponse {
    hashId: string;
    authorId: string;
    title: string;
    body: string;
    lang: string;
    isMature: boolean;
}

export const patchTwist = async ({
    title,
    body,
    twistId,
    monetization_option,
}: IPatchTwistParams) =>
    httpApi.v2.patch<IPatchTwistResponse, IPatchTwistResponse>(`twists/${twistId}`, {
        title,
        body,
        monetization_option,
    });
