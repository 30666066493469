import cn from 'classnames';
import type { FC } from 'react';

import styles from './PageLoader.module.css';
import { Spinner } from 'components/atoms/Spinner';

export interface IPageLoaderProps {
    className?: string;
    size?: number;
}

export const PageLoader: FC<IPageLoaderProps> = ({ className, size }) => (
    <div className={cn(styles['loader'], className)}>
        <Spinner size={size ?? 40} />
    </div>
);
