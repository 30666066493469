import type { FC } from 'react';

import { Anchor, IAnchorProps } from 'components/atoms/Anchor/Anchor';

type ExternalLinkProps = Pick<
    IAnchorProps,
    'href' | 'color' | 'className' | 'onClick' | 'children'
>;

export const ExternalLink: FC<ExternalLinkProps> = ({
    href,
    color = 'link',
    className,
    onClick,
    children,
}) => (
    <Anchor
        color={color}
        className={className}
        href={href}
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
    >
        {children}
    </Anchor>
);
