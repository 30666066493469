import { styled, TooltipProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TooltipContent } from './TooltipContent';
import { currentTip, toggleTip } from 'features/FlowTips';
import { Tooltip } from 'shared/ui/components';

interface IOnboardingTooltipProps extends Pick<TooltipProps, 'children' | 'open' | 'placement'> {
    titleId: string;
    statusTextId?: string;
    className?: string;
    offset?: [number, number];
    bgColor?: string;
}

const StyledTooltip = styled(Tooltip)`
    .MuiTooltip-tooltipPlacementTop,
    .MuiTooltip-tooltipPlacementBottom {
        transform: scale(-1, 1) !important;

        > div {
            transform: scale(-1, 1);
        }
    }
`;

export const OnboardingTooltip: FC<PropsWithChildren<IOnboardingTooltipProps>> = ({
    titleId,
    statusTextId,
    open,
    offset = [0, 0],
    bgColor = 'var(--colors-control-default-bg)',
    children,
    ...rest
}) => {
    const dispatch = useDispatch();
    const currentTipCount = useSelector(currentTip);

    const toggleNextTip = () => {
        dispatch(toggleTip(currentTipCount + 1));
    };

    return (
        <StyledTooltip
            {...rest}
            open={open}
            title={<TooltipContent titleId={titleId} statusTextId={statusTextId} />}
            arrow
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: { offset },
                        },
                    ],
                    style: {
                        zIndex: 999,
                    },
                },
                tooltip: {
                    style: {
                        backgroundColor: bgColor,
                        cursor: 'pointer',
                    },
                    onClick: toggleNextTip,
                },
                arrow: {
                    style: {
                        color: bgColor,
                    },
                },
            }}
        >
            {children}
        </StyledTooltip>
    );
};
