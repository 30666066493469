import { FC, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ERoutes } from './routes';
import { HttpStatusCode } from 'constants/httpStatusCode';
import { usePartnerRouterContext } from 'contexts/PartnerRouter/usePartnerRouterContext';
import { useUserQuery } from 'hooks/queries/user';

interface IAuthRequiredProps {
    children: ReactElement;
}

/** Restricts access to routes for unauthorized user */
export const AuthRequired: FC<IAuthRequiredProps> = ({ children }) => {
    const { data, error } = useUserQuery();
    const location = useLocation();
    const { getRoute } = usePartnerRouterContext();

    /**
     * Redirect to /login page, if:
     *  * user was not authenticated initially
     *  * user eventually becomes unauthenticated (getUser failed with 401)
     * */
    if (!data?.isAuthenticated || error?.response?.status === HttpStatusCode.Unauthorized) {
        return <Navigate to={getRoute(ERoutes.Main)} state={{ from: location }} replace />;
    }

    /** Here we are sure that user is authenticated */
    return children;
};
