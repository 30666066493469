import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { ERoutes } from 'app/providers/routing';

export const useIsEditorPath = (): boolean => {
    const { pathname } = useLocation();
    const editorRoutes = [
        ERoutes.CreateStory,
        ERoutes.CreateTwist,
        ERoutes.EditStory,
        ERoutes.EditTwist,
    ];

    const tabRoutes = [ERoutes.MyStoriesReading, ERoutes.MyStoriesWriting];

    return useMemo(() => {
        const isNotTab = !tabRoutes.find((route) => matchPath(route, pathname));
        return isNotTab && !!editorRoutes.find((route) => matchPath(route, pathname));
    }, [pathname]);
};
