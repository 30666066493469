import { gtmPushEvent } from '../gtm/gtm';
import { gtmEventNames } from '../gtm/gtmEventNames';
import { XsollaMetrika } from '../xsolla-metrika';
import { xmEventNames } from '../xsolla-metrika/xmEventNames';
import { WritersFaqBannerLocation } from './types';
import { EFAQTabs } from 'components/pages/FAQPage/types';

export const shopPackageSelect = (id: number) => {
    XsollaMetrika.elementClick(xmEventNames.shop_package_select, String(id));
};

export const shopPackagePurchase = (id?: number) => {
    XsollaMetrika.elementClick(xmEventNames.shop_package_purchase, String(id));
    gtmPushEvent(gtmEventNames.currency_package_purchased, { package_number: String(id) });
};

export const promoCodeRedeem = (promoCode: string, orbs: number | null) => {
    XsollaMetrika.customEvent(xmEventNames.shop_promo_code_redeem, { promo_code: promoCode, orbs });
};

export const stripeButtonClick = (value: string, balance: number, currency: string) => {
    XsollaMetrika.elementClick(xmEventNames.profile_stripe_button_click, value, {
        balance,
        currency,
    });
};

export const writersFaqItemExpand = (id: number, extraValues: { tab: EFAQTabs }) => {
    XsollaMetrika.elementClick(xmEventNames.faq_item_expand, String(id), extraValues);
};

export const writersFaqBannerClick = (location: WritersFaqBannerLocation) => {
    XsollaMetrika.elementClick(xmEventNames.faq_banner_click, location);
};

export const socialLinkClick = (socialNetworkName: string) => {
    XsollaMetrika.elementClick(xmEventNames.networks_icon_click, socialNetworkName);
};

export const logoutClick = () => {
    XsollaMetrika.elementClick(xmEventNames.profile_logout_click);
};

export const signUpModalClick = (value: 'close' | 'sign_up' | 'skip', modalName: string) => {
    XsollaMetrika.elementClick(xmEventNames.registration_popup_click, value, {
        modal_name: modalName,
    });
};

export const signUpModalShow = (modalName: string) => {
    XsollaMetrika.customEvent(xmEventNames.registration_popup_show, { modal_name: modalName });
};

export const sidebarSectionClick = (sectionName: string) => {
    XsollaMetrika.elementClick(xmEventNames.sidebar_section_click, sectionName);
};

export const contentLanguageClick = (language: string) => {
    XsollaMetrika.elementClick(xmEventNames.language_content_choice, language);
};

export const languageBannerClick = (value: 'create_story' | 'sign_up' | 'close') => {
    XsollaMetrika.elementClick(xmEventNames.language_banner_click, value);
};

export const noStoriesLanguageClick = () => {
    XsollaMetrika.elementClick(xmEventNames.language_no_stories_click);
};

export const openLegalDocumentClick = (documentName: string) => {
    XsollaMetrika.elementClick(xmEventNames.legal_agreement_open, documentName);
};

export const faqWriterBannerClick = (elementName: 'sign_up' | 'create_story' | 'ask_question') => {
    XsollaMetrika.elementClick(xmEventNames.faq_writer_banner_click, elementName);
};

export const faqAgentBannerClick = (elementName: 'sign_up' | 'become_agent' | 'ask_question') => {
    XsollaMetrika.elementClick(xmEventNames.faq_agent_banner_click, elementName);
};

export const faqTabSwitch = (tab: EFAQTabs) => {
    XsollaMetrika.elementClick(xmEventNames.faq_tab_switch, tab);
};

export const onboardingPopularStoriesShow = () => {
    XsollaMetrika.customEvent(xmEventNames.onboarding_popular_stories_show);
};

export const onboardingPopularStoriesClick = (storyId: string) => {
    XsollaMetrika.elementClick(xmEventNames.onboarding_popular_stories_click, storyId);
};
