import { AbTest } from 'analytics/ab-service';
import { httpApi } from 'core/httpApi';
import { TStories } from 'entities/story/model';

interface IGetStoriesParams {
    author_id?: string;
    languages?: string;
    limit?: number;
    offset?: number;
    with_top?: boolean;
}

interface IStoryDto {
    nickname: string;
    hashId: string;
    title: string;
    body: string;
    tags: string[];
    summary: string;
    channel: string | null;
}

export type TGetStoriesDto = IStoryDto[];

const mapToStories = (dto: TGetStoriesDto): TStories =>
    dto.map(({ hashId, nickname }) => ({ hashId, nickname }));

export const getStories = async (params: IGetStoriesParams): Promise<TGetStoriesDto> => {
    params.with_top = AbTest.isGroupB1152;

    return httpApi.v2.get<TGetStoriesDto, TGetStoriesDto>('stories', {
        params,
    });
};

export type TGetStoriesApiFunction = typeof getStories;
