import { useCallback } from 'react';
import { PrimitiveType, useIntl } from 'react-intl';

export type UseTranslate = ReturnType<typeof useTranslate>;

export const useTranslate = () => {
    const { formatMessage, messages } = useIntl();

    const translate = useCallback(
        (id: string, values?: Record<string, PrimitiveType>) =>
            !!messages[id] ? formatMessage({ id }, values) : id,
        [formatMessage, messages]
    );

    return translate;
};
