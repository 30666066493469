import { XsollaMetrika } from 'analytics/xsolla-metrika';
import { xmEventNames } from 'analytics/xsolla-metrika/xmEventNames';

export const filterByAuthorClick = (value: string) => {
    XsollaMetrika.elementClick(xmEventNames.search_author_nickname_click, value);
};

export const filterByAuthorClear = (authorId: string) => {
    XsollaMetrika.customEvent(xmEventNames.search_filter_remove, {
        author_id: authorId,
    });
};

export const searchByAuthorClick = (value: 'start' | 'complete') => {
    XsollaMetrika.elementClick(xmEventNames.search_bar_click, value);
};

export const searchByAuthorSelect = (authorId: string, storyCount: number) => {
    XsollaMetrika.customEvent(xmEventNames.search_filter_select, {
        author_id: authorId,
        number_of_stories: storyCount,
    });
};
