import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { BalanceNotificationContext } from './BalanceNotificationContext';

export const NOTIFICATION_TIMEOUT = 5000;

export const BalanceNotificationContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [amount, setAmount] = useState<number>();
    const [message, setMessage] = useState<string>();

    const hide = useCallback(() => {
        setAmount(undefined);
        setMessage(undefined);
    }, []);

    const display = useCallback(
        (amount: number, message: string) => {
            setAmount(amount);
            setMessage(message);
            setTimeout(() => hide(), NOTIFICATION_TIMEOUT);
        },
        [hide]
    );

    const value = useMemo(
        () => ({
            amount,
            message,
            display,
            hide,
        }),
        [amount, message, display, hide]
    );

    return (
        <BalanceNotificationContext.Provider value={value}>
            {children}
        </BalanceNotificationContext.Provider>
    );
};
