import { createContext } from 'react';

interface IReplenishBalanceModalContext {
    isDisplayed: boolean;
    display: () => void;
    hide: () => void;
}

export const ReplenishBalanceModalContext = createContext<IReplenishBalanceModalContext>({
    isDisplayed: false,
    display: () => {},
    hide: () => {},
});
