import type { FC } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsEvents } from 'analytics/events';
import { ERoutes } from 'app/providers/routing/routes';
import { NewStoryButton as NewStoryButtonLayout } from 'components/molecules/myStories/NewStoryButton';
import { currentTip, FlowTipsTypes, toggleTip } from 'features/FlowTips';
import { useThemeConfig } from 'hooks/theme/useThemeConfig';
import { useInAppNavigate } from 'hooks/useInAppNavigate';

export interface INewStoryButtonProps {
    className?: string;
    tooltipClassName?: string;
    isFloating?: boolean;
}

export const NewStoryButton: FC<INewStoryButtonProps> = ({
    className,
    tooltipClassName,
    isFloating,
}) => {
    const activeTip = useSelector(currentTip);
    const dispatch = useDispatch();

    const navigate = useInAppNavigate();
    const { disableCreateStory } = useThemeConfig((themeConfig) => themeConfig.routesOptions);

    const handleAddNewStoryTemplate = useCallback(() => {
        AnalyticsEvents.storyCreateClick();
        navigate(ERoutes.CreateStory);
    }, [navigate]);

    const onClick = useCallback(() => {
        if (activeTip === FlowTipsTypes.createStoryTip) {
            dispatch(toggleTip(activeTip + 1));
        }

        handleAddNewStoryTemplate();
    }, [handleAddNewStoryTemplate]);

    return disableCreateStory ? null : (
        <NewStoryButtonLayout
            className={className}
            tooltipClassName={tooltipClassName}
            onClick={onClick}
            isFloating={isFloating}
        />
    );
};
