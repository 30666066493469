import { forbesMockReadHistory } from 'api/forbesMock/forbesMockReadHistorySSService';
import { story1, story2, twist11, twist21 } from 'api/forbesMock/forbesMockStoriesData';

const FreeTwistForStory1 = [story1([twist11().hashId]), twist11(true)];

const FreeTwistForStory2 = [story2([twist21().hashId]), twist21(true)];

export const forbesMockReadFreeTwist = async (
    hash: string,
    readTwistHashes: string[],
    signal?: AbortSignal
) => {
    forbesMockReadHistory.push(hash);

    if (hash === twist21().hashId) {
        return Promise.resolve(FreeTwistForStory2);
    }

    return Promise.resolve(FreeTwistForStory1);
};
