import cn from 'classnames';
import { FC, lazy, Suspense, useState } from 'react';
import { Outlet as RouterOutlet } from 'react-router-dom';

import { SidePattern } from '../../atoms/SidePattern';
import AskEmailModalLazy from '../../molecules/askEmailModal/AskEmailModalLazy';
import SignUpFormLazy from '../../organisms/SingUpForm/SignUpFormLazy';
import styles from './WithSidebar.module.css';
import { PageLoader } from 'components/molecules/PageLoader';
import { useInitLiveChat } from 'components/organisms/LiveChatWidget/useInitLiveChat';
import { LogoSidebar } from 'components/organisms/LogoSidebar';
import { NewStoryButton } from 'components/organisms/myStories/NewStoryButton';
import { useOnboardingModal } from 'entities/Onboarding/Modal';
import { useUserQuery } from 'hooks/queries/user';
import { useThemeConfig } from 'hooks/theme/useThemeConfig';
import { getIsSearchBotAgent } from 'utils/getIsSearchBotAgent';
import { isDisplayAskEmailForm } from 'utils/isDisplayForm/isDisplayAskEmailForm';
import { isDisplaySignUpForm } from 'utils/isDisplayForm/isDisplaySignUpForm';

const BabkaOverlayLazy = lazy(() => import('components/organisms/BabkaOverlay/BabkaOverlay'));

export const WithSidebar: FC = () => {
    const { data: user, refetch: refetchUser } = useUserQuery();
    const onboardingModalConfig = useThemeConfig((themeConfig) => themeConfig.onboardingModal);
    const { Component: DisclaimerModal } = useThemeConfig(
        (themeConfig) => themeConfig.disclaimerModal
    );

    const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);
    const serviceInitEnabled = !getIsSearchBotAgent();
    const onboardingModal = useOnboardingModal({
        initEnabled: serviceInitEnabled && !onboardingModalConfig.hidden,
    });
    useInitLiveChat({ initEnabled: serviceInitEnabled && !onboardingModal });

    const onDisclaimerClose = () => {
        setShowDisclaimer(false);
    };

    return (
        <div className={styles['container']}>
            <SidePattern className={styles['side_pattern']} />
            <div className={styles['main']}>
                <LogoSidebar />
                <div className={cn(styles['content'])}>
                    <Suspense fallback={<PageLoader />}>
                        <RouterOutlet />
                    </Suspense>
                </div>
                {isDisplaySignUpForm(user) && <SignUpFormLazy />}
                {isDisplayAskEmailForm(user) && <AskEmailModalLazy onFinishRefetch={refetchUser} />}
                <Suspense>
                    <BabkaOverlayLazy />
                </Suspense>
                <NewStoryButton
                    className={styles['create_story']}
                    tooltipClassName={styles.onboardingTooltip}
                    isFloating={true}
                />
            </div>
            {onboardingModal}
            {showDisclaimer && DisclaimerModal && <DisclaimerModal onClose={onDisclaimerClose} />}
        </div>
    );
};

WithSidebar.displayName = 'WithSidebar';
