import type { ILogoProps } from './Logo';
import { FC, lazy, Suspense } from 'react';

import { Spinner } from '../Spinner';
import styles from './Logo.module.css';

export const LogoLazy = lazy(() => import('./Logo'));

export const Logo: FC<ILogoProps> = ({ className }) => (
    <Suspense
        fallback={
            <div className={styles['logo_skeleton']}>
                <Spinner size={10} />
            </div>
        }
    >
        <LogoLazy className={className} />
    </Suspense>
);
