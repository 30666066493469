import { useQuery } from '@tanstack/react-query';

import { useThemeConfig } from 'hooks/theme/useThemeConfig';

interface IUseLastOpenedStoryQueryArgs {
    enabled: boolean;
}

export const useLastOpenedStoryQuery = ({ enabled }: IUseLastOpenedStoryQueryArgs) => {
    const { getLastOpenedStory } = useThemeConfig((themeConfig) => themeConfig.api);

    return useQuery({
        enabled,
        queryKey: ['last_opened_story'],
        queryFn: getLastOpenedStory,
        staleTime: 0,
        cacheTime: 0,
    });
};
