import type { IConfig } from '../../types/config';

import SidePatternMobile from 'assets/80lv/SidePatternMobile.png';
import SidePatternPC from 'assets/80lv/SidePatternPC.png';
import LogoImage from 'assets/80lv/logo.svg';
import { config as defaultConfig } from 'themes/default/variants/light/config';

export const config: IConfig = {
    ...defaultConfig,
    withSideBar: {
        style: {
            sidePattern: {
                size: {
                    mobile: {
                        width: 16,
                    },
                    desktop: {
                        width: 52,
                    },
                },
            },
        },
    },
    sidePattern: {
        style: {
            backGroundImage: {
                desktop: `url('${SidePatternPC}')`,
                mobile: `url('${SidePatternMobile}')`,
            },
            backgroundSize: {
                desktop: '100% auto',
                mobile: '100% auto',
            },
        },
    },
    logoSidebar: {
        onion: {
            hidden: true,
        },
    },
    logo: {
        image: LogoImage,
        style: {
            size: {
                // width of logo image
                mobile: {
                    width: 128,
                },
                desktop: {
                    width: 189,
                },
            },
            skeleton: {
                // height of logo image
                size: {
                    mobile: {
                        height: 50,
                    },
                    desktop: {
                        height: 62,
                    },
                },
            },
        },
    },
    twistCard: {
        style: {
            fillingBackground: 'var(--colors-control-secondary-bg)',
            frontGradient:
                'linear-gradient(180deg,rgb(from var(--colors-control-secondary-bg) r g b / 30%) 0%,' +
                'transparent 50%,transparent 100%)',
            cardBought: {
                titleColor: 'var(--colors-core-background-secondary)',
            },
            cardSelected: {
                background: 'var(--colors-control-secondary-bg)',
                titleColor: 'var(--colors-core-background-secondary)',
                authorColor: 'var(--colors-core-background-primary)',
            },
        },
    },
    addUserPathButton: {
        style: {
            tip: {
                background: 'var(--colors-core-text-success)',
            },
        },
    },
};
