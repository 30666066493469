import { FlowTipsTypes } from 'features/FlowTips';
import { RootState } from 'store/store';

export const currentTip = (state: RootState) => state.flowTips.tipType;

export const isTwistTip = (state: RootState) => {
    return state.flowTips.tipType === FlowTipsTypes.twistTip;
};

export const isCreateTwistTip = (state: RootState) => {
    const { readStories, tipType } = state.flowTips;
    return tipType === FlowTipsTypes.createTwistTip && readStories >= 2;
};

export const isCreateStoryTip = (state: RootState) => {
    const { readStories, tipType } = state.flowTips;
    return tipType === FlowTipsTypes.createStoryTip && readStories >= 10;
};
