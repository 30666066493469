import { config } from 'themes/partners/forbes/config';
import { forbes } from 'themes/partners/forbes/forbes';
import { shadows } from 'themes/partners/forbes/shadows';
import { typo } from 'themes/partners/forbes/typo';
import { IMisc } from 'themes/types';
import { IThemeConfigurable, THEME_ID } from 'themes/types/theme';

export const forbesTheme: IThemeConfigurable = {
    ...forbes,
    shadows,
    typo,
    misc: {} as IMisc,
    themeId: THEME_ID.FORBES,
    config,
};
