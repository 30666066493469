import * as agentFlowEvents from './agentFlowEvents';
import * as editorEvents from './editorEvents';
import * as filterStoriesByAuthorEvents from './filterStoriesByAuthorEvents';
import * as metaframeEvents from './metaframeEvents';
import * as miscEvents from './miscEvents';
import * as myStoriesEvents from './myStoriesEvents';
import * as onboardingEvents from './onboardingEvents';
import * as storyEvents from './storyEvents';
import * as twistEvents from './twistEvents';

export * from './types';

export const AnalyticsEvents = {
    ...agentFlowEvents,
    ...editorEvents,
    ...metaframeEvents,
    ...miscEvents,
    ...onboardingEvents,
    ...filterStoriesByAuthorEvents,
    ...storyEvents,
    ...twistEvents,
    ...myStoriesEvents,
};
