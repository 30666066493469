import * as Sentry from '@sentry/react';
import { FC, useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { SomethingWrongMessage } from 'components/molecules/SomethingWrongMessage';

export const CaughtErrorPage: FC = () => {
    const error = useRouteError() as Error;

    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return <SomethingWrongMessage hideGoBack />;
};
