import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './ForbesDisclaimerModal.module.css';
import { Text } from 'components/atoms/Text';
import { ModalHeaderBasic } from 'components/molecules/ModalHeaderBasic';
import { ModalWindowBase } from 'components/organisms/ModalWindowBase';
import { ModalTemplateSecond } from 'components/templates/ModalTemplateSecond';
import { executeForTheme } from 'contexts/PartnerRouter/PartnerRouterContext';
import { useThemeContext } from 'contexts/Theme/useThemeContext';
import { useTranslate } from 'hooks/useTranslate';
import { THEME_ID } from 'themes/types/theme';

export interface IDisclaimerModalProps {
    onClose: () => void;
}

export const ForbesDisclaimerModal: FC<IDisclaimerModalProps> = ({ onClose }) => {
    const { themeId } = useThemeContext();
    const translate = useTranslate();

    const getPartnerName = () =>
        executeForTheme({
            DEFAULT: {
                callback: () => null,
            },
            FORBES: {
                match: themeId === THEME_ID.FORBES,
                callback: () => translate('non_translatable.partners.forbes'),
            },
            NEW_YORK_TIMES: {
                match: themeId === THEME_ID.NEW_YORK_TIMES,
                callback: () => translate('non_translatable.partners.nytimes'),
            },
            EIGHTY_LEVEL: {
                match: false,
                callback: () => null,
            },
            EIGHTY_LEVEL_DEV: {
                match: false,
                callback: () => null,
            },
        });

    const partnerName = getPartnerName();

    if (!partnerName) {
        return null;
    }

    return (
        <ModalWindowBase onShadowClick={onClose}>
            <ModalTemplateSecond
                header={<ModalHeaderBasic onClose={onClose} />}
                sidePatternVariant="primary"
            >
                <Text typo="body-lg-accent">
                    <FormattedMessage id="disclaimer_modal.title" />
                </Text>
                <Text color="tertiary" className={styles['description']}>
                    <FormattedMessage id="disclaimer_modal.content" values={{ partnerName }} />
                </Text>
            </ModalTemplateSecond>
        </ModalWindowBase>
    );
};
