import { createContext } from 'react';

import { defaultThemes, ICssTheme } from 'themes';
import { IConfig } from 'themes/types/config';
import { IThemeConfigurable } from 'themes/types/theme';

export interface IThemeContext {
    themeId: string | undefined;
    config: IConfig;
    cssTheme: ICssTheme;
    setTheme: (theme: IThemeConfigurable) => void;
}

export const ThemeContext = createContext<IThemeContext>({
    themeId: undefined,
    config: defaultThemes.light.config,
    cssTheme: defaultThemes.light,
    setTheme: () => {},
});
