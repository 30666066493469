import { enabledUiLanguages, uiLanguages } from 'constants/uiLanguages';
import { ELanguage } from 'types/languages';
import { getMatchedDefaultLanguages } from 'utils/languages/getDefaultLanguages';
import { localStorageService } from 'utils/localStorageService';

const uiLanguageLSService = localStorageService<ELanguage>('ui_language');

const get = (): ELanguage => {
    const [defaultLanguage] = getMatchedDefaultLanguages(enabledUiLanguages) as ELanguage[];

    const savedValue = uiLanguageLSService.get();
    const savedLanguage =
        typeof savedValue === 'string' && uiLanguages.includes(savedValue) ? savedValue : null;

    return savedLanguage || defaultLanguage;
};

const set = (language: ELanguage) => uiLanguageLSService.set(language);

export const uiLanguageClientStorage = {
    get,
    set,
};
