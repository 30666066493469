import { XsollaMetrika } from '../xsolla-metrika';
import { xmEventNames } from '../xsolla-metrika/xmEventNames';
import { MonetizationOption } from 'types/monetization';

export const monetizationOptionsOpen = (
    option: MonetizationOption,
    twistId: string,
    storyId: string
) => {
    XsollaMetrika.elementClick(xmEventNames.editor_pricing_open, option, {
        twist_id: twistId,
        story_id: storyId,
    });
};

export const monetizationOptionsSave = (
    option: MonetizationOption,
    twistId: string,
    storyId: string
) => {
    XsollaMetrika.elementClick(xmEventNames.editor_pricing_save, option, {
        twist_id: twistId,
        story_id: storyId,
    });
};
