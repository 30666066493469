import { story1, story2 } from 'api/forbesMock/forbesMockStoriesData';
import { TGetStoriesDto } from 'entities/story/api/getStories';

export interface IForbesMockGetPublishedStoriesParams {
    limit?: number;
    offset?: number;
}

export const forbesMockGetPublishedStories = async (
    params: IForbesMockGetPublishedStoriesParams
): Promise<TGetStoriesDto> => {
    if (params?.limit === 25) {
        return Promise.resolve([story1(), story2()]);
    }
    return Promise.resolve([story1(), story2()]);
};
