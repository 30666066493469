import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button } from '../../../atoms/Button/Button';
import styles from './StaticLayout.module.css';
import { INewStoryButtonLayoutProps } from './types';
import { ReactComponent as FeatherIcon } from 'assets/feather.svg';
import { OnboardingTooltip } from 'entities/Onboarding/Tooltip';
import { isCreateStoryTip } from 'features/FlowTips';

export const StaticLayout: FC<INewStoryButtonLayoutProps> = ({
    className,
    isDisabled,
    onClick,
}) => {
    const isTipShowing = useSelector(isCreateStoryTip);

    return (
        <OnboardingTooltip
            titleId="onboarding_tip_create_story"
            statusTextId="onboarding_tip_create_story_status"
            className={styles.tooltip}
            bgColor="var(--colors-core-background-brand)"
            placement="left"
            open={isTipShowing}
        >
            <Button
                className={className}
                onClick={onClick}
                size="lg"
                variant="primary"
                isDisabled={isDisabled}
                icon={<FeatherIcon height={24} />}
                iconPosition="before"
                stretched
            >
                <FormattedMessage id="my_stories.create_new_story" />
            </Button>
        </OnboardingTooltip>
    );
};
