import cn from 'classnames';
import { FC, ReactElement } from 'react';

import styles from './ModalTemplateImage.module.css';

export type IModalTemplateImageProps = {
    header: ReactElement;
    image: ReactElement;
    title: ReactElement;
    main: ReactElement;
    buttonBlock: ReactElement;
    closeButton: ReactElement;
    className?: string;
    withSmallScreens?: boolean;
    group1ClassName?: string;
    group2BodyClassName?: string;
    groupAll?: boolean;
    allGroupClass?: string;
};

export const ModalTemplateImage: FC<IModalTemplateImageProps> = ({
    header,
    image,
    title,
    main,
    buttonBlock,
    closeButton,
    className,
    withSmallScreens = true,
    group1ClassName,
    group2BodyClassName,
    groupAll,
    allGroupClass,
}) => (
    <div className={cn(styles['modal'], className)}>
        <div className={cn('story3_scrollable', styles['container'])}>
            <div className={cn(allGroupClass, styles['groups'])}>
                {!groupAll ? (
                    <>
                        <div
                            className={cn(
                                styles['group_1'],
                                withSmallScreens && styles['group_1_small'],
                                group1ClassName
                            )}
                        >
                            {header}
                            {image}
                        </div>
                        <div
                            className={cn(
                                styles['group_2'],
                                withSmallScreens && styles['group_2_small']
                            )}
                        >
                            {title}
                            <div className={cn(styles['group_2_body'], group2BodyClassName)}>
                                {main}
                                {buttonBlock}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className={cn(
                                styles['group_1'],
                                withSmallScreens && styles['group_1_small'],
                                group1ClassName
                            )}
                        >
                            {header}
                            {title}
                            {main}
                            {buttonBlock}
                        </div>
                        <div
                            className={cn(
                                styles['group_2'],
                                withSmallScreens && styles['group_2_small']
                            )}
                        >
                            <div className={cn(styles['group_2_body'], group2BodyClassName)} />
                        </div>
                    </>
                )}
            </div>
        </div>
        {closeButton}
    </div>
);
