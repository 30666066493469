import { XsollaMetrika } from '../xsolla-metrika';
import { xmEventNames } from '../xsolla-metrika/xmEventNames';

export const signInClick = () => {
    XsollaMetrika.elementClick(xmEventNames.metaframe_sign_in_click);
};

export const balanceButtonClick = () => {
    XsollaMetrika.elementClick(xmEventNames.metaframe_shop_click);
};

export const profileButtonClick = () => {
    XsollaMetrika.elementClick(xmEventNames.metaframe_profile_click);
};

export const myStoriesButtonClick = () => {
    XsollaMetrika.elementClick(xmEventNames.metaframe_stories_click);
};
