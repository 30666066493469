import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';

import {
    executeForTheme,
    PARTNERS_URL,
    PROJECTS,
} from 'contexts/PartnerRouter/PartnerRouterContext';
import { usePartnerRouterContext } from 'contexts/PartnerRouter/usePartnerRouterContext';
import { useThemeContext } from 'contexts/Theme/useThemeContext';
import { defaultThemes } from 'themes';
import { eightyLevelTheme } from 'themes/partners/80lv';
import { eightyLevelDevTheme } from 'themes/partners/80lv-dev';
import { forbesTheme } from 'themes/partners/forbes';
import { newYorkTimesTheme } from 'themes/partners/nytimes';

export const useSetThemeByRoute = () => {
    const matchForbes = useMatch(`${PARTNERS_URL.FORBES}/*`);
    const matchNewYorkTimes = useMatch(`${PARTNERS_URL.NEW_YORK_TIMES}/*`);
    const match80lv = useMatch(`${PARTNERS_URL.EIGHTY_LEVEL}/*`);
    const match80lvDev = useMatch(`${PARTNERS_URL.EIGHTY_LEVEL_DEV}/*`);

    const { setPrefix, setProject } = usePartnerRouterContext();
    const { setTheme, themeId } = useThemeContext();

    useEffect(() => {
        executeForTheme<void>({
            DEFAULT: {
                callback: () => {
                    setPrefix(PARTNERS_URL.DEFAULT);
                    setTheme(defaultThemes.light);
                    setProject(PROJECTS.DEFAULT);
                },
            },
            FORBES: {
                match: !!matchForbes,
                callback: () => {
                    setPrefix(PARTNERS_URL.FORBES);
                    setTheme(forbesTheme);
                    setProject(PROJECTS.FORBES);
                },
            },
            NEW_YORK_TIMES: {
                match: !!matchNewYorkTimes,
                callback: () => {
                    setPrefix(PARTNERS_URL.NEW_YORK_TIMES);
                    setTheme(newYorkTimesTheme);
                    setProject(PROJECTS.NEW_YORK_TIMES);
                },
            },
            EIGHTY_LEVEL: {
                match: !!match80lv,
                callback: () => {
                    setPrefix(PARTNERS_URL.EIGHTY_LEVEL);
                    setTheme(eightyLevelTheme);
                    setProject(PROJECTS.EIGHTY_LEVEL);
                },
            },
            EIGHTY_LEVEL_DEV: {
                match: !!match80lvDev,
                callback: () => {
                    setPrefix(PARTNERS_URL.EIGHTY_LEVEL_DEV);
                    setTheme(eightyLevelDevTheme);
                    setProject(PROJECTS.EIGHTY_LEVEL_DEV);
                },
            },
        });
    }, [match80lv, match80lvDev, matchForbes, matchNewYorkTimes, setPrefix, setProject, setTheme]);

    return {
        themeId,
    };
};
