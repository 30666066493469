const window = global.window;

const intRegExp = /^\d+$/;

const isUnsafeIntString = (value: unknown) =>
    typeof value === 'string' &&
    intRegExp.test(value) &&
    !Number.isSafeInteger(parseInt(value, 10));

export const localStorageService = <T>(key: string) => {
    if (!window) {
        return {
            get() {},
            set() {},
            clear() {},
            value: null,
        };
    }

    const get = (): T | null => {
        const val = window.localStorage.getItem(key);

        return val ? ((isUnsafeIntString(val) ? val : JSON.parse(val)) as T) : null;
    };

    const set = (val: T) => {
        window.localStorage.setItem(key, JSON.stringify(val));
    };

    const clear = () => {
        window.localStorage.removeItem(key);
    };

    return {
        get,
        set,
        clear,
        value: get(),
    };
};
