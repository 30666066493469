import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as Logo404 } from 'assets/404.svg';
import { ErrorMessage } from 'components/molecules/ErrorMessage';
import { IErrorMessage } from 'components/molecules/ErrorMessage/ErrorMessage';

export type INotFoundMessage = Pick<IErrorMessage, 'hideGoBack'>;

export const NotFoundMessage: FC<INotFoundMessage> = ({ hideGoBack }) => (
    <ErrorMessage
        logo={<Logo404 />}
        text={<FormattedMessage id="not_found_message.title" />}
        hideGoBack={hideGoBack}
    />
);
