export enum ELanguage {
    German = 'de',
    English = 'en',
    Spanish = 'es',
    French = 'fr',
    Japanese = 'ja',
    Korean = 'ko',
    Portuguese = 'pt',
    Russian = 'ru',
    Chinese = 'zh',
}
