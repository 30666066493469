import { forbesMockBalance } from 'api/forbesMock/forbesMockBalanceSSService';
import {
    twist11,
    twist12,
    twist13,
    twist21,
    twist22,
    twist23,
} from 'api/forbesMock/forbesMockStoriesData';

export const forbesMockBuyTwistByHash = async (hash: string, signal?: AbortSignal) => {
    const balance = forbesMockBalance.get();
    if (hash === twist11().hash) {
        forbesMockBalance.set(balance);
    } else if (hash === twist12().hash) {
        if (balance < twist12().price) {
            return Promise.reject();
        }
        forbesMockBalance.set(balance - twist12().price);
    } else if (hash === twist13().hash) {
        if (balance < twist13().price) {
            return Promise.reject();
        }
        forbesMockBalance.set(balance - twist13().price);
    } else if (hash === twist21().hash) {
        forbesMockBalance.set(balance);
    } else if (hash === twist22().hash) {
        if (balance < twist22().price) {
            return Promise.reject();
        }
        forbesMockBalance.set(balance - twist22().price);
    } else if (hash === twist23().hash) {
        if (balance < twist23().price) {
            return Promise.reject();
        }
        forbesMockBalance.set(balance - twist23().price);
    }
    return Promise.resolve({} as any);
};
