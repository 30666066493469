import { FC } from 'react';

import { ReactComponent as CrossSVG } from '../../../../assets/cross.svg';
import { Button } from '../../../atoms/Button/Button';
import styles from './CloseButtonBlock.module.css';

interface ICloseButtonBlockProps {
    onClick: () => void;
}

export const CloseButtonBlock: FC<ICloseButtonBlockProps> = ({ onClick }) => (
    <div className={styles['container']} onClick={onClick}>
        <Button size="xs" className={styles['button']} icon={<CrossSVG width={24} height={24} />} />
    </div>
);
