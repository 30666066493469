import TagManager from 'react-gtm-module';

import { ENV_MODE } from '../../config';
import { EnvMode } from 'types/envMode';

const GTM_TAG = 'GTM-N629WHZ';
const GTM_TAG_DEV = 'GTM-54DKFHW'; //r.rozhkov@xsolla.com

export const gtmInit = (initialUrl: string) => {
    if (!window.dataLayer) {
        TagManager.initialize({
            gtmId: ENV_MODE === EnvMode.Production ? GTM_TAG : GTM_TAG_DEV,
            events: {
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
                initial_page_url: initialUrl, //to use in GA4 tag as page location in page_view event
            },
        });
    }
};

export const gtmPushEvent = (eventName: string, data?: Record<string, unknown>) => {
    if (window.dataLayer) {
        TagManager.dataLayer({
            dataLayer: { event: eventName, ...data },
        });
    }
};
