export const xmEventNames = {
    metaframe_sign_in_click: 'story3_metaframe_sign-in_click',
    metaframe_profile_click: 'story3_metaframe_profile_click',
    metaframe_shop_click: 'story3_metaframe_shop_click',
    metaframe_stories_click: 'story3_metaframe_stories_click',
    story_switch_right: 'story3_story_switch_right',
    story_switch_left: 'story3_story_switch_left',
    story_reading_root_view: 'story3_story_reading_root-view',
    story_reading_choice_view: 'story3_story_reading_choice-view',
    story_reading_choice_unlock_attempt: 'story3_story_reading_choice-unlock-attempt',
    story_reading_choice_unlock_success: 'story3_story_reading_choice-unlock-success',
    story_menu_create: 'story3_story_menu_create',
    story_own_add_plot: 'story3_story_own_add-plot',
    story_own_add_twist: 'story3_story_own_add-twist',
    story_foreign_add_twist: 'story3_story_foreign_add-twist',
    story_own_revise_twists: 'story3_story_own_revise-twists',
    story_foreign_revise_twists: 'story3_story_foreign_revise-twists',
    shop_package_select: 'story3_shop_package_select',
    shop_package_purchase: 'story3_shop_package_purchase',
    stories_list_delete: 'story3_stories_list_delete',
    stories_archive_restore: 'story3_stories_archive_restore',
    onboarding_email_specification: 'story3_onboarding_email_specification',
    onboarding_first_screen_submit: 'story3_onboarding_first-screen_submit',
    onboarding_first_screen_age_error: 'story3_onboarding_first-screen_age-error',
    onboarding_second_screen_submit: 'story3_onboarding_second-screen_submit',
    onboarding_modal_show: 'story3_onboarding_pop-up_show',
    onboarding_modal_close: 'story3_onboarding_pop-up_close',
    onboarding_modal_next: 'story3_onboarding_pop-up_next',
    faq_item_expand: 'story3_faq_item_expand',
    faq_banner_click: 'story3_faq_banner_click',
    content_tab_switch: 'story3_content_tab_switch',
    reading_explore_click: 'story3_reading_explore_click',
    writing_story_open: 'story3_writing_story_open',
    reading_story_open: 'story3_reading_story_open',
    editor_pricing_open: 'story3_editor_pricing_open',
    editor_pricing_save: 'story3_editor_pricing_save',
    networks_icon_click: 'story3_networks_icon_click',
    agent_banner_start_agreement: 'story3_agent_banner_start-agreement',
    agent_banner_continue_agreement: 'story3_agent_banner_continue-agreement',
    agent_banner_check_agreement: 'story3_agent_banner_check-agreement',
    agent_banner_copy_link: 'story3_agent_banner_copy-link',
    agent_agreement_accept: 'story3_agent_agreement_accept',
    agent_agreement_click_banner: 'story3_agent_agreement_click-banner',
    profile_stripe_button_click: 'story3_profile_stripe_button-click',
    shop_promo_code_redeem: 'story3_shop_promocode_redeem',
    profile_logout_click: 'story3_profile_log-out_click',
    registration_popup_show: 'story3_registration_pop-up_show',
    registration_popup_click: 'story3_registration_pop-up_click',
    sidebar_section_click: 'story3_sidebar_section_click',
    language_content_choice: 'story3_language_content_choice',
    language_banner_click: 'story3_language_banner_click',
    language_no_stories_click: 'story3_language_no-stories_click',
    story_share_button_click: 'story3_story_share_button-click',
    legal_agreement_open: 'story3_legal_agreement_open',
    faq_tab_switch: 'story3_faq_tab_switch',
    faq_writer_banner_click: 'story3_faq_writer-banner_click',
    faq_agent_banner_click: 'story3_faq_agent-banner_click',
    search_bar_click: 'story3_search_bar_click',
    search_filter_select: 'story3_search_filter_select',
    search_filter_remove: 'story3_search_filter_remove',
    search_author_nickname_click: 'story3_search_author-nickname_click',
    onboarding_popular_stories_show: 'story3_onboarding_popular-stories_show',
    onboarding_popular_stories_click: 'story3_onboarding_popular-stories_click',
};
