export const sessionStorageService = <T>(key: string) => {
    const get = (): T | null => {
        const val = window.sessionStorage.getItem(key);

        return val ? (JSON.parse(val) as T) : null;
    };

    const set = (val: T) => {
        window.sessionStorage.setItem(key, JSON.stringify(val));
    };

    const clear = () => {
        window.sessionStorage.removeItem(key);
    };

    return {
        get,
        set,
        clear,
        value: get(),
    };
};
