import { httpApi } from 'core/httpApi';

export interface IGetTwistParams {
    twistId: string;
}

export interface IGetTwistResponse {
    hashId: string;
    authorId: string;
    title: string;
    body: string;
    lang: string;
    isMature: boolean;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    hashRootId: string;
    closestHashRootId?: string;
    hashParentId: string;
    genre: string | null;
    tags: string[];
}

export const getTwist = async ({ twistId }: IGetTwistParams) =>
    httpApi.v2.get<IGetTwistResponse, IGetTwistResponse>(`twists/${twistId}`);
