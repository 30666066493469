import { IColors } from './colors';
import { IMisc } from './misc';
import { IShadows } from './shadows';
import { ITypography } from './typography';
import { PARTNERS_DATA } from 'contexts/PartnerRouter/PartnerRouterContext';
import { IConfig } from 'themes/types/config';
import { VerifyExtends } from 'utils/verifyExtends';

export interface ICssTheme {
    colors: IColors<string>;
    typo: ITypography;
    misc: IMisc;
    shadows: IShadows;
}

export enum THEME_ID {
    DEFAULT = 'defaultLight',
    FORBES = 'forbes',
    NEW_YORK_TIMES = 'nytimes',
    EIGHTY_LEVEL = '80lv',
    EIGHTY_LEVEL_DEV = '80lv-dev',
}

/**
 * TODO refactor and remove
 * NOTE: this is a type for validating PROJECTS enum. It should extend PARTNERS_DATA. Do not remove!
 */
type __VerifyThemeId = VerifyExtends<PARTNERS_DATA<string>, typeof THEME_ID>;

export interface IThemeConfigurable extends ICssTheme {
    themeId: THEME_ID;
    config: IConfig;
}

export type ThemeVariant = Pick<ICssTheme, 'colors'>;
