import cn from 'classnames';
import { ComponentProps, FC, ReactElement } from 'react';

import styles from './FormControl.module.css';
import { Text } from 'components/atoms/Text';

export interface IFormControlProps extends ComponentProps<'div'> {
    extra?: ReactElement;
    label?: ReactElement | string;
    error?: ReactElement | string;
}

export const FormControl: FC<IFormControlProps> = ({
    label,
    error,
    className,
    children,
    ...restProps
}) => (
    <div className={cn(styles['container'], className)} {...restProps}>
        {!!label && (
            <Text typo="body-md" color="primary" className={styles['label']}>
                {label}
            </Text>
        )}

        {children}

        {!!error && (
            <Text typo="body-md" color="alert" className={styles['error']}>
                {error}
            </Text>
        )}
    </div>
);
